import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Checkbox, Input, Button, Select } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import {
  faSquareCheck,
  faSquare,
  faSquarePlus
} from '@fortawesome/free-regular-svg-icons';
import {
  selectVolumeMetadata,
  selectVisualOptions
} from '../../../../redux/viewer/viewer.selectors';
import { setScalarValuesVisible } from '../../../../redux/viewer/viewer.slice';
import SegmentationSettingsStyled, {
  ColorPicker,
} from './SegmentationSettings.styled';

// TODO: move to utils
/*
const CollapsiblePanel = (props) => {
  return (<CollapsiblePanelStyled
    ghost
    defaultActiveKey={[0]}
    expandIconPosition='end'
  >
    <Collapse.Panel key={0} header={props.header}>
      {props.children}
    </Collapse.Panel>
  </CollapsiblePanelStyled>);
}
*/

const SegmentationSettings = ({ id, metadata, visibleScalars, ...props }) => {
  const dispatch = useDispatch();
  const lut = metadata?.lookupTableAnnotations;

  const [ filterTerm, setFilterTerm ] = useState("");
  const [ filteredList, setFilteredList ] = useState([]);

  const [ mode, setMode ] = useState("cortex");

  const segList = useMemo(() => {
    return lut && [...lut].sort((a, b) => a.name > b.name).filter(i => i.name.toLowerCase() !== 'unknown');
  }, [lut]);

  useEffect(() => {
    if(!segList) return;
    setFilteredList(filterTerm.length < 2
      ? segList
      : segList.filter(s => s.name.toLowerCase().includes(filterTerm))
    );
  }, [segList, filterTerm]);

  useEffect(() => {
    if(!segList) return;
    if(mode === 'cortex') {
      dispatch(setScalarValuesVisible(id, segList.map(s => s.index), false));
      dispatch(setScalarValuesVisible(id, segList.filter(s => s.name.includes('Cerebral-Cortex')).map(s => s.index), true));
    } else {
      dispatch(setScalarValuesVisible(id, segList.map(s => s.index), true));
    }
  }, [dispatch, segList, id, mode]);

  const selectItemsIcon = useMemo(() => {
    return filteredList.every(i => visibleScalars[i.index])
      ? faSquareCheck
      : filteredList.some(i => visibleScalars[i.index])
        ? faSquarePlus
        : faSquare;
  }, [filteredList, visibleScalars]);

  const selectItemsCallback = useCallback(() => {
    const on = !filteredList.every(i => visibleScalars[i.index]);
    dispatch(setScalarValuesVisible(id, filteredList.map(i => i.index), on));
  }, [id, dispatch, filteredList, visibleScalars]);

  if(!segList) return null;

  return (<>
    Mode
    <Select
      variant='borderless'
      options={[
        { label: 'Cortex',            value: 'cortex' },
        { label: 'Full segmentation', value: 'full'   }
      ]}
      value={mode}
      onChange={setMode}
    />
    { mode === "full" && <SegmentationSettingsStyled className="wide-box">
      <div className="filter-box">
        <Button type='text'
          icon={<FontAwesomeIcon icon={selectItemsIcon}/>}
          onClick={selectItemsCallback}
        />
        <Input allowClear
          variant="borderless"
          placeholder="Type to filter ..."
          onChange={e => setFilterTerm(e.target.value?.toLowerCase() || "")}
        />
      </div>
      <div className="scroll-box">
        { filteredList.map(s =>
          <Checkbox key={s.index}
            checked={visibleScalars[s.index]}
            onChange={e => {
              dispatch(setScalarValuesVisible(id, [s.index], e.target.checked));
            }}
          >
            {s.name}
            <ColorPicker style={{marginLeft: 'auto'}} color={s.color}/>
          </Checkbox>
        )}
        { filteredList.length === 0 &&
          <div className="empty-box">
            <FontAwesomeIcon icon={faInbox}/>
            No items
          </div>
        }
      </div>
    </SegmentationSettingsStyled>}
  </>);
}

const mapState = (state, { id }) => ({
  metadata:       selectVolumeMetadata(state, id),
  visibleScalars: selectVisualOptions(state, id)?.visibleScalars
});

export default connect(mapState, null)(SegmentationSettings);
