import vtkScalarToRGBA from '@kitware/vtk.js/Filters/General/ScalarToRGBA';
import vtkImageMapper from '@kitware/vtk.js/Rendering/Core/ImageMapper';
import vtkImageSlice from '@kitware/vtk.js/Rendering/Core/ImageSlice';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import { AXIS } from '../../../../constants';
import { BaseActor } from './BaseActors'

class SliceActor extends BaseActor {
  constructor(renderingEngine, volumeId, axis) {
    super(renderingEngine, volumeId,
      vtkImageSlice.newInstance(), /* actor */
      vtkImageMapper.newInstance() /* mapper */
    );

    switch(axis) {
      case AXIS.X: this._setSlice = this.mapper.setISlice; break;
      case AXIS.Y: this._setSlice = this.mapper.setJSlice; break;
      case AXIS.Z: this._setSlice = this.mapper.setKSlice; break;
      default: break;
    }

    this.axis = axis;
    this.uid = `${volumeId}#${axis}`;
    this.sliceIndex = undefined;
  }

  _initialize(volume) {
    const { imageData, metadata } = volume;
    const pixMin = metadata?.minPixelValue || 0;
    const pixMax = metadata?.maxPixelValue || 255;

    if(imageData.getPointData().getScalars().getNumberOfComponents() === 1) {
      // add ScalarsToRGBA filter
      let cfun = this.ctf;
      if(!cfun) {
        cfun = vtkColorTransferFunction.newInstance();
        cfun.addRGBPoint(pixMin, 0, 0, 0);
        cfun.addRGBPoint(pixMax, 1, 1, 1);
        cfun.setMappingRange(pixMin, pixMax);
      }

      let ofun = this.sof;
      if(!ofun) {
        ofun = vtkPiecewiseFunction.newInstance();
        ofun.addPoint(0.0, 0.0);
        ofun.addPoint(1.0, 1.0);
      }

      const rgbaFilter = vtkScalarToRGBA.newInstance();
      rgbaFilter.setLookupTable(cfun);
      rgbaFilter.setPiecewiseFunction(ofun);
      rgbaFilter.setInputData(imageData);

      this.rgbaFilter = rgbaFilter;
      this.mapper.setInputConnection(rgbaFilter.getOutputPort());
    } else {
      if(this.ctf) this.actor.getProperty().setRGBTransferFunction(0, this.ctf);
      if(this.sof) this.actor.getProperty().setScalarOpacity(0, this.sof);

      this.rgbaFilter = null;
      this.mapper.setInputData(imageData);
    }
  }

  setSlices(worldPos, force) {
    if(!this.ready) return;

    const idx = this.worldPosToIndex(worldPos)[this.axis];
    if(!force && (idx === this.sliceIndex || idx === undefined)) return;

    this.sliceIndex = idx;
    this._setSlice(idx);
  }

  modified() {
    //this.mapper.setInputData(cache.getVolume(this.volumeId).imageData);
    super.modified();
  }
};

export default SliceActor;
