import styled from 'styled-components';
import { Modal } from 'antd';

const CheckListStyled = styled.div`
  border: 1px solid lightgray;
  border-radius: 2px;

  .scroll-box, .ant-table-wrapper {
    max-height: 154px;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .scroll-box {
    padding: 4px 8px;
  }

  .filter-box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding-left: 8px;

    .ant-input-affix-wrapper {
      padding-left: 8px;
    }
  }

  .empty-box {
    display: inline;
    color: lightgray;
    padding-left: 1px;

    > :first-child {
      margin-right: 8px;
    }
  }

  .ant-checkbox-group-item {
    display: flex;
  }
`;

const GroupDialogStyled = styled(Modal)`
  .ant-modal-body, .ant-modal-header {
    padding: 16px;
  }

  .ant-modal-body > * {
    margin-bottom: 8px;
  }

  .ant-transfer-list {
    width: calc(50% - 26px)
  }

  .gd-select {
    display: flex;
    align-items: center;

    .ant-typography {
      width: 100px;
    }

    .ant-select {
      margin-left: 8px;
      width: 100%
    }
  }
`;

export { CheckListStyled, GroupDialogStyled };
