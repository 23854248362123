import { RectangleROITool, utilities, cursors, drawing, annotation } from '@cornerstonejs/tools';
import { getEnabledElement } from '@cornerstonejs/core';

const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { hideElementCursor } = cursors.elementCursor;
const { drawHandles: drawHandlesSvg, drawTextBox: drawTextBoxSvg,
  drawRect: drawRectSvg, drawLinkedTextBox: drawLinkedTextBoxSvg,
 } = drawing;

const { isAnnotationVisible } = annotation.visibility;
const { isAnnotationLocked } = annotation.locking;
const { getAnnotations, removeAnnotation, addAnnotation } = annotation.state;

class TractsFilterTool extends RectangleROITool {
  static toolName;
/*
  constructor(toolProps, defaultToolProps) {
    super(toolProps, defaultToolProps);

    eventTarget.addEventListener(Events.ANNOTATION_COMPLETED, (e) => {
      console.log("NEW ANNOTATION", e)

      const { annotation } = e.detail;
      const { points } = annotation.data.handles;

      annotation.metadata.viewPlaneNormal.forEach((n, i) => {
        if(n !== 0) return;
        const normal = [0, 0, 0];
        normal[i] = 1;



        addAnnotation({
          ...annotation,
          isLocked: true,
          metadata: {
            ...annotation.metadata,
            viewPlaneNormal: normal
          },
          data: {
            ...annotation.data,
            handles: {
              points: points.map(pts =>
                  pts.map((p, i) => annotation.metadata.viewPlaneNormal[i] !== 0 ? p + i : p)
              )
            }
          }
        }, "default")

      });
    });
  }*/

  addNewAnnotation = (evt) => {
    // NOTE: we can't call super here, since addNewAnnotation is a class field
    // and not a method as it should be in Cornerstone

    const { element, currentPoints } = evt.detail;
    const { viewport, renderingEngine } = getEnabledElement(element);
    const worldPos = currentPoints.world;

    // we just add removing of other annotations for this tool before adding a new
    const annotations = getAnnotations(this.getToolName(), element);
    annotations?.forEach(a => removeAnnotation(a.annotationUID));

    this.isDrawing = true;

    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;

    const referencedImageId = this.getReferencedImageId(
      viewport,
      worldPos,
      viewPlaneNormal,
      viewUp
    );

    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

    const annotation = {
      invalidated: true,
      highlighted: true,
      isLocked: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal],
        viewUp: [...viewUp],
        FrameOfReferenceUID,
        referencedImageId,
      },
      data: {
        label: '',
        handles: {
          points: [
            [...worldPos],
            [...worldPos],
            [...worldPos],
            [...worldPos],
          ],
          textBox: {
            hasMoved: false,
            worldPosition: [0, 0, 0],
            worldBoundingBox: {
              topLeft: [0, 0, 0],
              topRight: [0, 0, 0],
              bottomLeft: [0, 0, 0],
              bottomRight: [0, 0, 0],
            },
          },
          activeHandleIndex: null,
        },
        cachedStats: {},
      },
    };

    addAnnotation(annotation, element);

    const viewportIdsToRender = getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex: 3,
      movingTextBox: false,
      newAnnotation: true,
      hasMoved: false,
    };
    this._activateDraw(element);

    hideElementCursor(element);

    evt.preventDefault();

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  }

  renderAnnotation = (enabledElement, svgDrawingHelper) => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = this.filterInteractableAnnotationsForElement(
      element,
      annotations
    );

    if (!annotations?.length) {
      return renderStatus;
    }

    const styleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i];

      const { annotationUID, data } = annotation;
      const { points, activeHandleIndex } = data.handles;
      const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p));

      styleSpecifier.annotationUID = annotationUID;

      const lineWidth = this.getStyle('lineWidth', styleSpecifier, annotation);
      const lineDash = this.getStyle('lineDash', styleSpecifier, annotation);
      const color = this.getStyle('color', styleSpecifier, annotation);

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      if (!isAnnotationVisible(annotationUID)) continue;

      let activeHandleCanvasCoords;
      if (
        !isAnnotationLocked(annotation) &&
        !this.editData &&
        activeHandleIndex !== null
      ) {
        // Not locked or creating and hovering over handle, so render handle.
        activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
      }

      if (activeHandleCanvasCoords) {
        const handleGroupUID = '0';

        drawHandlesSvg(
          svgDrawingHelper,
          annotationUID,
          handleGroupUID,
          activeHandleCanvasCoords,
          {
            color,
          }
        );
      }

      const dataId = `${annotationUID}-rect`;
      const rectangleUID = '0';
      drawRectSvg(
        svgDrawingHelper,
        annotationUID,
        rectangleUID,
        canvasCoordinates[0],
        canvasCoordinates[3],
        {
          color,
          lineDash,
          lineWidth,
        },
        dataId
      );

      renderStatus = true;
    }

    return renderStatus;
  };
}

TractsFilterTool.toolName = 'TractsFilterTool';
export default TractsFilterTool;
