import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTrashCan
} from '@fortawesome/free-regular-svg-icons';
import {
  removePatientSeries,
  setPatientSeriesTag
} from '../../../../redux/upload/upload.slice';
import FileTreeStyled, { FileTreeItemStyled } from './FileTree.styled';

const menuItems = [
  { key: 'structural', label: 'structural' },
  { key: 'functional', label: 'functional' },
  { key: 'tracts',     label: 'tracts'     },
  { key: 'unknown',    label: 'unknown'    }
];

const FileTreeItem = ({ node, onExpand, onDelete, onTagChange, ...props }) => {
  const [ hover, setHover ] = useState(false);

  const tagMenu = useMemo(() => {
    return node.children && ({
      items: menuItems,
      onClick: ({ key }) => onTagChange(node.key, key !== 'unknown' ? key : undefined)
    });
  }, [node.children, node.key, onTagChange]);

  return (<FileTreeItemStyled
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
  >
    <FontAwesomeIcon icon={node.children ? faFolder : faPaperclip}/>
    <span onClick={() => onExpand(node.key)}> { node.title } </span>
    { node.children && <>
      <Typography.Text>
        {`[ ${node.children?.length} file${node.children?.length > 1 ? 's' : ''} ]`}
      </Typography.Text>
      <span style={{ width: '84px' }}>
        <Dropdown arrow trigger={['click']} menu={tagMenu}>
          <Tag color={node.tag && "blue"}> { node.tag || 'unknown' } </Tag>
        </Dropdown>
      </span>
      <FontAwesomeIcon
        icon={faTrashCan}
        style={{opacity: hover ? '100' : '0'}}
        onClick={() => onDelete(node.key)}
      />
    </>}
  </FileTreeItemStyled>);
}

const FileTree = ({ id, tree, ...props }) => {
  const dispatch = useDispatch();
  const [ expandedKeys, setExpandedKeys ] = useState([]);

  const onTreeExpand = useCallback(key => {
    setExpandedKeys(keys => keys.includes(key)
      ? keys.filter(k => k !== key)
      : [ ...keys, key ]
    );
  }, []);

  const onTreeNodeDelete = useCallback(key => {
    dispatch(removePatientSeries(id, key));
    setExpandedKeys(keys => keys.filter(k => k !== key));
  }, [id, dispatch]);

  const onTagChange = useCallback((series, tag) => {
    dispatch(setPatientSeriesTag(id, series, tag));
  }, [id, dispatch]);

  return (<FileTreeStyled
    selectable={false}
    titleRender={node => <FileTreeItem
      node={node}
      onExpand={onTreeExpand}
      onDelete={onTreeNodeDelete}
      onTagChange={onTagChange}
    />}
    expandedKeys={expandedKeys}
    treeData={tree}
  />);
}

export default FileTree;
