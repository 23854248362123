import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert, Button, Checkbox, Input, Select, Tabs, Tag, Typography, message
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import {
  useDatasetsQuery,
  useGroupsQuery
} from '../../../redux/datasets/datasets.api';
import {
  useDoAnalyze,
  useNextAnalysisId,
} from '../../../redux/analyzes/analyzes.api';
import {
  selectRunMode,
  selectName,
  selectSubjects,
  selectRunConfig,
  selectIsValidConfig,
  selectIsParallel,
  selectIsQA,
  selectCanParrallelize,
  selectAnalyzersWithMissingData
} from '../../../redux/run/run.selectors';
import {
  setMode,
  setName,
  setSubjects,
  setParallel,
  setQA,
  setSubjectsTags,
  clear,
} from '../../../redux/run/run.slice';
import { ANALYSIS_MODE } from '../../../constants';
import RunStyled from './Run.styled';
import AnalyzerSelector from './AnalyzerSelector';
import { CheckList } from '../Datasets/Groups/GroupDialog';

const Run = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ startAnalysis, startAnalysisResult ] = useDoAnalyze();

  const {
    mode, name, subjects, analyzers, parallel, parallelDisabled, runDisabled, qa
  } = props;

  const { data: patients, isLoading: isPatientsLoading } = useDatasetsQuery(
    { status: 'ready' }, { skip: mode === ANALYSIS_MODE.GROUP }
  );

  const { data: groups, isLoading: isGroupsLoading } = useGroupsQuery(
    { status: 'ready' }, { skip: mode === ANALYSIS_MODE.SINGLE }
  );

  const { data: nextId, isSuccess: isNextIdLoaded } = useNextAnalysisId();

  useEffect(() => {
    return () => dispatch(clear());
  }, [dispatch]);

  useEffect(() => {
    if(startAnalysisResult?.isSuccess) {
      message.success("Analysis successfully started");
      navigate('/analysis');
    }
  }, [navigate, startAnalysisResult])

  useEffect(() => {
    if(mode === ANALYSIS_MODE.SINGLE && subjects.length === 1) {
      const { tags } = patients.data.find(p => p.id === subjects[0]);
      dispatch(setSubjectsTags(tags));
    } else if(mode === ANALYSIS_MODE.GROUP) {
      const groupsTags = subjects?.reduce((a, s) => {
        const { tags } = groups.data.find(g => g.id === s);
        return a.length > 0 ? a.filter(t => tags.includes(t)) : tags;
      }, []);
      dispatch(setSubjectsTags(groupsTags.length > 0 ? groupsTags : undefined));
    }
  }, [dispatch, mode, subjects, patients, groups]);

  useEffect(() => {
    if(isNextIdLoaded) dispatch(setName(`#${nextId}`));
  }, [dispatch, nextId, isNextIdLoaded]);

  return (<>
    <Typography.Title level={4}>
      Run new analysis
    </Typography.Title>

    <RunStyled>
      <Tabs onChange={key => dispatch(setMode(key))}
        items={[
          {
            key: ANALYSIS_MODE.SINGLE,
            label: 'Individual mode',
            children: <div className='config-group'>
              <span>Select patient:</span>
              { patients && patients.data.length === 0
                ? <Alert type='warning' showIcon
                    message="No patients found. Go to uploads to add new patients."
                  />
                : <Select
                    value={subjects[0]}
                    onChange={value => dispatch(setSubjects([value]))}
                    placeholder="Select patient ..."
                    loading={isPatientsLoading}
                    options={patients?.data?.map(p => ({
                      label: `${p.name} [${p.sex}${p.age}]`,
                      value: p.id
                    }))}
                  />
              }
            </div>
          },
          {
            key: ANALYSIS_MODE.GROUP,
            label: 'Group mode',
            children: <div className='config-group'>
              <span>Select groups:</span>
              { groups && groups.data.length === 0
                ? <Alert type='warning' showIcon
                    message="No groups found. Go to groups page to create patient groups."
                  />
                : <CheckList
                    selectedItems={subjects}
                    setSelectedItems={value => dispatch(setSubjects(value))}
                    columns={[
                      {
                        dataIndex: 'name',
                        title: 'Name',
                      },
                      {
                        title: 'Entries',
                        render:(_, record) => `[${record.patients.length} patients]`
                      },
                      {
                        title: 'Tags',
                        dataIndex: 'tags',
                        render: (_, record) => record.tags.map(t => <Tag key={t}>{t}</Tag>)
                      }
                    ]}
                    options={groups?.data/*groups?.data?.map(g => ({
                      label: <div  key={g.id} className='group-item'>
                        <span>{g.name}</span>
                        <span>[{g.patients.length} patients]</span>
                        {g.tags.map(t => <Tag key={t}>{t}</Tag>)}
                        </div>,
                      value: g.id
                    }))*/}
                  />
              }
            </div>
          }
        ]}
      />

      <div className='config-group'>
        <span>Select analyzers:</span>
        <AnalyzerSelector/>
      </div>

      <div className='config-group'>
        <span>Enter analysis name:</span>
        <Input placeholder='Analysis name'
          value={name}
          onChange={e => dispatch(setName(e.target.value))}
        />
      </div>

      <div className='config-group'>
        <Checkbox
          checked={qa}
          onChange={e => dispatch(setQA(e.target.checked))}
        >
          Perform quality checks
        </Checkbox>
      </div>

      <div className='config-group'>
        <Checkbox disabled={parallelDisabled}
          checked={!parallelDisabled && parallel}
          onChange={e => dispatch(setParallel(e.target.checked))}
        >
          Parallelize
        </Checkbox>
      </div>

      { props.badAnalyzers &&
        <Alert className='config-error' type="error" showIcon
          message={<>
            The following analysis type(s) cannot be performed since
            { mode === ANALYSIS_MODE.SINGLE
              ? " the selected subject doesn't"
              : " some of the selected groups don't " }
            provide required input data:
            <ul>
              { props.badAnalyzers.map(({ title, tags }) => <li key={title}>
                <b>{title}</b>: { tags.map(t => <Tag key={t}>{t}</Tag>) }
              </li>) }
            </ul>
            { mode === ANALYSIS_MODE.GROUP &&
              "Please, check subjects in the selected groups."
            }
          </>}
        />
      }

      <Button type='primary'
        disabled={runDisabled}
        onClick={() => {
          if(subjects.length === 0) {
            message.error("Analysis subject(s) must be specified");
            return;
          }

          const config = Object.entries(analyzers).reduce((a, [k, v]) => {
            if(v.enabled) a[k] = v.config;
            return a;
          }, {});

          startAnalysis({
            mode,
            name,
            qa,
            ids: subjects,
            analyzers: config,
            parallel: !parallelDisabled && parallel
          });
        }}
      >
        <FontAwesomeIcon icon={faPlay}/>
        Run
      </Button>
    </RunStyled>
  </>);
}

const mapState = (state, props) => ({
  mode:      selectRunMode(state),
  name:      selectName(state),
  subjects:  selectSubjects(state),
  analyzers: selectRunConfig(state),
  parallel:  selectIsParallel(state),
  qa:        selectIsQA(state),
  parallelDisabled: !selectCanParrallelize(state),
  runDisabled: !selectIsValidConfig(state),
  badAnalyzers: selectAnalyzersWithMissingData(state)
});

export default connect(mapState, null)(Run);
