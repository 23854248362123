import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { useGroupsQuery } from '../../../redux/datasets/datasets.api';

const AddOption = styled.div`
  background-color: white;
  padding: 5px 10px;
  color: black;

  :hover {
    cursor: pointer;
    background-color: #E6F7FF;
  }
`;

const GroupSelect = (props) => {
  const ref = useRef();
  const [ options, setOptions ] = useState([]);
  const [ searchValue, setSearchValue ] = useState("");

  const { data: groups, isLoading } = useGroupsQuery();

  useEffect(() => {
    setOptions(groups?.data?.map(g => ({ label: g.name, value: `e_${g.id}` })) || []);
  }, [groups]);

  const addOption = useCallback((name) => {
    setOptions(d => [...d, { label: name, value: `n_${name}` }]);
  }, []);

  const onChange = (value) => {
    if(!props.onChange) return;
    if(!value) props.onChange(undefined);
    props.onChange({
      isNew: value[0] === 'n',
      id: value.slice(2)
    });
  }

  const customDropdown = (menu) => {
    const showMenu = !searchValue || options.find(o => {
      return o.label.toLowerCase().includes(searchValue.toLowerCase())
    });

    const showAddOption = searchValue && !options.find(o => {
      return o.label.toLowerCase() === searchValue
    });

    return <>
      { showAddOption && <AddOption onClick={() => {
        addOption(searchValue);
        ref.current.blur();
        onChange(`n_${searchValue}`);
      }}>
        Create group <b>{searchValue}</b>
      </AddOption>}
      { showMenu && menu }
    </>
  }

  return (<Select
    ref={ref}
    style={{width: '100%'}}
    placeholder={"Select group or type new group name"}
    showSearch
    searchValue={searchValue}
    onSearch={setSearchValue}
    dropdownRender={customDropdown}
    notFoundContent={<></>}
    options={options}
    filterOption={(input, option) => {
      return option.label.toLowerCase().includes(input.toLowerCase())
    }}
    onChange={onChange}
    value={props.value && `${props.value.isNew ? 'n' : 'e'}_${props.value.id}`}
  />);
}

export default GroupSelect;
