import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Checkbox, Typography, Spin, Input, InputNumber } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useAnalyzersConfig } from '../../../redux/analyzes/analyzes.api';
import { useGroupsQuery } from '../../../redux/datasets/datasets.api';
import {
  selectRunMode,
  selectRunConfig,
  selectSubjects,
} from '../../../redux/run/run.selectors';
import {
  setAnalyzerEnabled,
  setAnalyzerConfig
} from '../../../redux/run/run.slice';
import {
  GroupConfigStyled,
  AnalyzerConfigStyled,
  AnalyzerSelectorStyled
} from './AnalyzerSelector.styled';

const { Text } = Typography;

const getConfigControl = (item, value, onChange, index) => {
  switch(item.type) {
  case 'integer':
  case 'double':
    return <InputNumber
      onChange={value => onChange(item.key, value)}
      value={value}
      precision={item.type === 'integer' ? 0 : 3}
      min={item.min}
      max={item.max}
    />;
  case 'boolean':
    return <Checkbox
      onChange={e => onChange(item.key, e.target.checked)}
      value={value}
    />
  case 'string':
    return <Input defaultValue={item.default} value={value}
      onChange={e => onChange(item.key, e.target.value)}
    />;
  default:
    return undefined;
  }
}

const GroupConfig = ({ item, groups, onChange, ...props }) => {
  const [ value, setValue ] = useState(groups
    ? groups.map(({ id }, i) => {
        return { id, value: i < item.default?.length ? item.default[i] : 0 };
      })
    : []
  );

  useEffect(() => {
    if(!groups) return;
    setValue(value => {
      const newVal = [];
      groups.forEach((g, i) => {
        const old = value.find(({ id }) => g.id === id);
        if(old) newVal.push(old);
        else newVal.push({ id: g.id, value: i < item.default?.length ? item.default[i] : 0 });
      });
      return newVal;
    });
  }, [groups, item.default]);

  useEffect(() => {
    onChange(item.key, value.map(v => v.value));
  }, [onChange, item.key, value])

  const _onChange = useCallback((id, val) => setValue(cur => {
    const newVal = [...cur];
    newVal.find(i => i.id === id).value = val;
    return newVal;
  }), []);

  return (<GroupConfigStyled>
    { groups?.length > 0
      ? groups.map((s, j) => <React.Fragment key={s.id}>
          <Text>{s.name}</Text>
          { getConfigControl(item, value && value[j]?.value, (k, v) => _onChange(s.id, v), j) }
        </React.Fragment>)
      : <Text type="warning"> Select at least one group </Text>
    }
  </GroupConfigStyled>);
}

const AnalyzerConfig = ({ mode, subjects, analyzer, values, ...props }) => {
  const dispatch = useDispatch();
  const { type, config } = analyzer;

  const onChange = useCallback((key, value) => {
    dispatch(setAnalyzerConfig(type, key, value));
  }, [dispatch, type]);

  const { data: groupsData } = useGroupsQuery(undefined, {
    skip: mode === 'i' || !config || !subjects?.length
  });

  const groups = useMemo(() => groupsData?.data && subjects.map(
    s => ({ id: s, name: groupsData.data.find(({ id }) => id === s)?.name })
  ), [subjects, groupsData]);

  useEffect(() => {
    config.forEach(item => {
      if(item.arrayMode) return;
      dispatch(setAnalyzerConfig(type, item.key, item.default, true));
    });
  }, [dispatch, type, config]);

  if(!config) return undefined;

  return <AnalyzerConfigStyled>
    <span>
      <FontAwesomeIcon icon={faGear}/>
      <Text strong> Configuration </Text>
    </span>
    <div>
      { config.map(item => {
        if(item.mode instanceof Array && !item.mode.find(m => m === mode)) {
          return undefined;
        }
        return <React.Fragment key={item.key}>
          <Text>
            {item.description}
          </Text>
          { item.arrayMode === 'per-group'
            ? <GroupConfig
                item={item}
                value={values[item.key]}
                onChange={onChange}
                groups={groups}
              />
            : getConfigControl(item, values[item.key], onChange)
          }
        </React.Fragment>
      })}
    </div>
  </AnalyzerConfigStyled>
}

const AnalyzerSelector = ({ mode, subjects, analyzers, ...props }) => {
  const dispatch = useDispatch();
  const { data: config, isSuccess } = useAnalyzersConfig();

  if(!isSuccess) return <Spin/>;

  return (<AnalyzerSelectorStyled>
    { config.map(a => {
      return <React.Fragment key={a.type}>
        <Checkbox checked={analyzers[a.type]?.enabled}
          onChange={e => dispatch(setAnalyzerEnabled(a, e.target.checked))}
        >
          {a.title}
        </Checkbox>
        <Text type="secondary"> {a.description} </Text>
        { a.config && Object.keys(a.config.filter(i => !i.mode || i.mode.includes(mode))).length > 0 && analyzers[a.type]?.enabled &&
          <AnalyzerConfig
            mode={mode}
            subjects={subjects}
            analyzer={a}
            values={analyzers[a.type].config}
          />
        }
      </React.Fragment>}
    )}
  </AnalyzerSelectorStyled>);
}

const mapState = (state, props) => ({
  mode: selectRunMode(state),
  analyzers: selectRunConfig(state),
  subjects: selectSubjects(state)
});

export default connect(mapState, null)(AnalyzerSelector);
