import React from 'react';
import { Alert, Button, Progress, Typography } from 'antd';
import { connect, useDispatch } from 'react-redux';
import {
  selectName,
  selectProgress,
  selectCompressionProgress,
  selectError
} from '../../../redux/upload/upload.selectors';
import {
  setProgress,
  setCompressionProgress,
  setError,
  clear
} from '../../../redux/upload/upload.slice';
import UploadProgressStyled from './UploadProgress.styled';

const UploadProgress = (props) => {
  const dispatch = useDispatch();
  const { name, progress, compressProgress, error } = props;

  return (<UploadProgressStyled>
    { compressProgress !== undefined && <>
      <Typography.Text>
        { compressProgress === 100
          ? "Compression complete"
          : `Compressing ${name?.length > 0 ? name : "dataset"} ...`
        }
      </Typography.Text>
      <Progress
        percent={compressProgress}
        status={compressProgress === 100 ? "success" : "active"}
      />
    </>}
    { progress !== undefined && <>
      <Typography.Text>
        { progress === 100
          ? "Upload complete"
          : `Uploading ${name?.length > 0 ? name : "dataset"} ...`
        }
      </Typography.Text>
      <Progress
        percent={progress}
        status={error ? "exception" : (progress === 100 ? "success" : "active") }
      />
    </>}
    { error &&
      <Alert type="error" message={error}/>
    }
    <Button
      type={"primary"}
      disabled={compressProgress < 100}
      danger={progress < 100}
      onClick={() => {
        if(error) {
          dispatch(setProgress(undefined));
          dispatch(setCompressionProgress(undefined));
          dispatch(setError(undefined));
        } else if(progress === 100) {
          dispatch(clear());
        } else {
          // TODO: abort
        }
      }}
    >
      { error
        ? "Try again"
        : compressProgress < 100 || progress < 100
          ? "Abort"
          : "Upload another dataset"
      }
    </Button>
  </UploadProgressStyled>);
}

const mapState = (state, props) => ({
  name:     selectName(state),
  progress: selectProgress(state),
  error:    selectError(state),
  compressProgress: selectCompressionProgress(state)
});

export default connect(mapState, null)(UploadProgress);
