import styled from 'styled-components';

const MetadataPanelStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 2fr;
  row-gap: 4px;
  align-items: center;
  line-height: 32px;
  padding: 0 16px;
  margin-left: 8px;

  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;

  color: white;

  :last-child {
    margin-bottom: 8px;
  }

  .ant-typography {
    color: lightgray;
  }
`;

export default MetadataPanelStyled;
