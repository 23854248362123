import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Radio, Button, Dropdown, Typography } from 'antd';
import RadioButtonStyled from './RadioButton.styled';

const RadioDropdown = ({ menu, text, children, ...props }) => {
  return (<RadioButtonStyled>
    <Radio.Button {...props}>
      {children}
    </Radio.Button>
    { menu &&
      <Dropdown trigger='click' placement='bottomRight' menu={menu}
        disabled={props.disabled}
      >
        <Button>
          { text && <Typography.Text ellipsis> {text} </Typography.Text> }
          <FontAwesomeIcon icon={faChevronDown}/>
        </Button>
      </Dropdown>
    }
  </RadioButtonStyled>);
}

export default RadioDropdown;
