import * as cornerstone from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
// import niftiImageLoader from '../nifti/imageLoader';
import niftiMetaDataProvider from '../nifti/metaDataProvider';
import niftiVolumeLoader from '../nifti/niftiLoader';
import trkVolumeLoader from '../trk/trkLoader';
import thicknessVolumeLoader from '../curv/curvLoader';
import mgzVolumeLoader from '../mgz/mgzLoader';
import plyVolumeLoader from '../ply/plyLoader';
import matLoader from '../mat/matLoader';
import MouseWheelZoomTool from './MouseWheelZoomTool';
import DataProbeTool from './DataProbeTool';
import TractsFilterTool from './TractsFilterTool';
/*
import vtkSpline3D from '@kitware/vtk.js/Common/DataModel/Spline3D';
import { splineKind } from '@kitware/vtk.js/Common/DataModel/Spline3D/Constants'
import { BoundaryCondition } from '@kitware/vtk.js/Common/DataModel/Spline1D/Constants';

const x = vtkSpline3D.newInstance({ kind: splineKind.CARDINAL_SPLINE, close: false, boundaryCondition: BoundaryCondition.SECOND_DERIVATIVE  });
console.log("SPLINE", x)
const inPoints = [[0, 0, 0], [0.2, 0.6, 0], [1, 1, 0]];
x.computeCoefficients(inPoints);
console.log(x.toJSON());
let str = '';
for(let i = 0; i < 2; ++i) {
  for(let j = 0; j < 10; ++j) {
    str += x.getPoint(i, j / 10.0).join('\t') + '\n';
  }
}
console.log(str)
*/

const { volumeLoader } = cornerstone;
const {
  StackScrollMouseWheelTool,
  SegmentationDisplayTool,
  TrackballRotateTool,
  PanTool,
  ZoomTool,
  DragProbeTool,
} = cornerstoneTools;

let initialized = false;
let initializing = false;

const initCornerstone = async () => {
  if(initializing || initialized) return;
  initializing = true;

  await cornerstone.init();
  await cornerstoneTools.init();

  cornerstoneTools.addTool(StackScrollMouseWheelTool);
  cornerstoneTools.addTool(TrackballRotateTool);
  cornerstoneTools.addTool(PanTool);
  cornerstoneTools.addTool(ZoomTool);
  cornerstoneTools.addTool(DragProbeTool);
  cornerstoneTools.addTool(MouseWheelZoomTool);
  cornerstoneTools.addTool(DataProbeTool);
  cornerstoneTools.addTool(SegmentationDisplayTool);
  // cornerstoneTools.addTool(RectangleROITool);
  // cornerstoneTools.addTool(ReferenceCursors);
  cornerstoneTools.addTool(TractsFilterTool);

  const probeCursor = cornerstoneTools.cursors.CursorSVG.Probe;
  cornerstoneTools.cursors.registerCursor(
    DataProbeTool.toolName,
    probeCursor.iconContent,
    probeCursor.viewBox
  );

  const rectCursor = cornerstoneTools.cursors.CursorSVG.RectangleROI;
  cornerstoneTools.cursors.registerCursor(
    TractsFilterTool.toolName,
    rectCursor.iconContent,
    rectCursor.viewBox
  );

  // imageLoader.registerImageLoader('nii', niftiImageLoader);
  volumeLoader.registerVolumeLoader('nii', niftiVolumeLoader);
  volumeLoader.registerVolumeLoader('trk', trkVolumeLoader);
  volumeLoader.registerVolumeLoader('curv', thicknessVolumeLoader);
  volumeLoader.registerVolumeLoader('mgz', mgzVolumeLoader);
  volumeLoader.registerVolumeLoader('mat', matLoader);
  volumeLoader.registerVolumeLoader('ply', plyVolumeLoader);

  initialized = true;
}

const isCornerstoneInitialized = () => initialized;

export { initCornerstone, isCornerstoneInitialized };
