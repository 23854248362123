import { ImageVolume } from '@cornerstonejs/core';

class PlyImageVolume extends ImageVolume {
  constructor({ polyData, ...props }) {
    super(props);
    this.polyData = polyData;
  }

  setColorMode(mode) {
    // do nothing
  }

  setQuality(quality) {
    // do nothing
  }
}

export default PlyImageVolume;
