import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { RenderingEngine, cache } from '@cornerstonejs/core';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import sceneReducers, {
  initColorFunctions, createActor, rebuildScene, clearScene,
  clearLayer, updateLayerVisibity, reorderActors,
  DEFAULT_TRACTS_SLICE_MODE,
  DEFAULT_THICKNESS_MODE, DEFAULT_TRACTS_QUALITY
} from './sceneManager';
import toolsReducers, {
  updateToolsState,
  setProbeToolTargetId
} from './toolManager';
import { selectVolumeViewportIds, selectViewportIds } from './viewer.selectors';
import { initCornerstone } from '../../modules/cornerstone/init';
import { DATA_TYPE, AXIS } from '../../constants';

export const RENDERING_ENGINE_ID = 'RENDERING_ENGINE_0';

const initialState = {
  renderingEngine: null,
  analysisId: undefined,
  layers: [],
  viewportGridRows: 2,
  viewportGridCols: 2,
  viewports: [
    {
      id: 'viewport-0',
      axis: AXIS.Z,
      created: false,
      initialized: false,
      flipVertical: true,
      flipHorizontal: false
    },
    {
      id: 'viewport-1',
      axis: AXIS.Y,
      created: false,
      initialized: false,
      flipVertical: false,
      flipHorizontal: false
    },
    {
      id: 'viewport-2',
      axis: AXIS.X,
      created: false,
      initialized: false,
      flipVertical: false,
      flipHorizontal: true
    },
    {
      id: 'viewport-3',
      axis: AXIS.VOLUME,
      created: false,
      initialized: false,
      flipVertical: false,
      flipHorizontal: false
    }
  ],
  activeViewport: 'viewport-0',
  filesToLoad: 0,
  progress: {},
  slicePlanes: null,
  cameraPos: [ undefined, undefined, undefined ],
  activeTools: {}
};

const _findLayer = (layers, volumeId) => {
  for(let i = 0; i < layers.length; ++i) {
    if(layers[i].volumeId === volumeId) return [layers[i], [i]];
    if(layers[i].children) {
      const l = _findLayer(layers[i].children, volumeId);
      if(l) return [l[0], [i, ...l[1]]];
    }
  }
  return undefined;
}

const findLayer = (state, volumeId) => {
  const l = _findLayer(state.layers, volumeId);
  return l || [];
}

const findLayerIndex = (state, layer) => {
  const l = _findLayer(state.layers, layer.volumeId);
  return l ? l[1] : undefined;
}

const getLayer = (state, index) => {
  if(index === undefined || index === null) return undefined;
  if(index instanceof Array) {
    let layer = state.layers[index[0]];
    for(let i = 1; i < index.length; ++i) {
      layer = layer.children[index[i]];
    }
    return layer;
  }

  return state.layers[index];
}

const _forEachLayer = (layers, callback) => {
  for(let i = 0; i < layers.length; ++i) {
    callback(layers[i]);
    if(layers[i].children) _forEachLayer(layers[i].children, callback);
  }
}

const forEachLayer = (state, callback) => {
  _forEachLayer(state.layers, callback);
}

export const setLayerVolumeId = (state, index, volumeId, keepRange = false) => {
  const layer = getLayer(state, index);
  if(layer.volumeId === volumeId) return;
  if(state.probeTargetId === layer.volumeId) {
    setProbeToolTargetId(state, volumeId);
  }

  const vps = selectVolumeViewportIds({ viewer: state });

  layer.volumeId = volumeId;
  if(layer.actor) {
    vps.forEach(id => layer.actor.removeFromViewport(id));
    layer.actor.delete();
  }
  layer.actor = createActor(state, layer);
  if(!layer.actor && !keepRange) layer.resetMappingRange = true;
}

export const _deleteLayer = (state, layer) => {
  const viewportIds = selectViewportIds({ viewer: state });
  viewportIds.forEach(id => layer.actor?.removeFromViewport(id));
  clearLayer(layer);
  layer.actor = null;
}

const initLayer = (l) => ({
  ...l,
  visible: l.visible === undefined ? true : l.visible,
  ctf: vtkColorTransferFunction.newInstance(),
  sof: vtkPiecewiseFunction.newInstance(),
  options: { opacity: 1.0, ...l.options },
  showMetadata: false,
  actor: undefined,
  children: l.children ? l.children.map(initLayer) : undefined
});

const prepareLayer = (state, volumeId, csRenderable) => {
  const [ layer, idx ] = findLayer(state, volumeId);
  if(!layer) return;

  layer.hasColorSettings = csRenderable;
  layer.actor?.delete();
  layer.actor = createActor(state, layer);

  initColorFunctions(state, layer);

  switch(layer.type) {
  case DATA_TYPE.CONN_GROUP: {
    const data = cache.getVolume(volumeId)?.data;
    if(!data.length) break;
    const { options, actor } = layer;
    const hasPFDR = data[0].p.length > 1;
    options.hasPFDR = hasPFDR;
    if(hasPFDR) {
      options.filterArray = 1;
      actor?.setFilterArray(1);
    }

    options.roiVolumeIds = data.reduce((a, roi, i) => {
      let name = roi.names[i];
      name = name.replace(/^[^.]+\./, '').replace(/\s.*$/, '');
      a.push(
        volumeId.replace(/^mat:/, 'nii:').replace(/\/rest\/.*/, `/rest/${name}/spmT_0001.nii`)
      );
      return a;
    }, []);
    break;
  }
  case DATA_TYPE.TRACTOGRAPHY: {
    if(layer.options.clusters) {
      layer.options.visibleClusters = {}
      layer.options.clusters.names.forEach((_, i) => {
          layer.options.visibleClusters[i] = true;
      });
      cache.getVolume(volumeId)?.setClusterData(current(layer.options.clusters));
    }
    cache.getVolume(volumeId)?.setQuality(layer.options.tractsQuality);
    break;
  }
  case DATA_TYPE.MESH: {
    if(idx.length === 2 && state.layers[idx[0]].type === DATA_TYPE.TRACTOGRAPHY) {
      layer.actor.setColor(
        state.layers[idx[0]].options.clusters.colors[idx[1]].map(i => i / 255.0)
      );
    }
    break;
  }
  case DATA_TYPE.SEGMENTATION:
    // createSegmentation(state, volumeId);
    break;
  default:
    break;
  }
}

const initializeViewer = createAsyncThunk('viewer/init', initCornerstone);

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setAnalysisId: (state, action) => {
      state.analysisId = action.payload;
    },
    setFunctionalROI: {
      reducer: (state, action) => {
        const { index, value } = action.payload;
        setLayerVolumeId(state, index, value);
      },
      prepare: (index, value) => ({ payload: { index, value }})
    },
    addLayers: (state, action) => {
      state.layers.push(...action.payload.map(initLayer));
      /*
      forEachLayer(state, layer => {
        const vol = layer.volumeId && cache.getVolume(layer.volumeId)
        if(vol) prepareLayer(state, vol.volumeId, vol.csRenderable)
      })*/
    },
    deleteLayer: (state, action) => {
      const idx = action.payload;
      const layer = getLayer(state, idx);
      _deleteLayer(state, layer);
      state.layers.splice(idx, 1);
      rebuildScene(state);
      updateToolsState(state);
    },
    moveLayer: {
      reducer: (state, action) => {
        const { from, to } = action.payload;
        if(state.layers[from].hidden) return;
        /*
        let target = to;
        if(to > from) {
          while(target < state.layers.length) {
            if(!state.layers[target].hidden && !state.layers[target].folder) {
              if(target !== to) --target;
              break;
            }
            ++target;
          }
        } else {
          while(target > 0) {
            if(!state.layers[target].hidden) break;
            --target;
          }
        }*/
        const tmp = state.layers[from];
        // const tmp = state.layers.splice(from, 1)[0];
        // state.layers.splice(target, 0, tmp);
        state.layers[from] = state.layers[to];
        state.layers[to] = tmp;
        reorderActors(state);
        // NOTE: rebuildScene should be invoked here,
        // but it will be called anyway by viewer effects
      },
      prepare: (from, to) => ({ payload: { from, to }})
    },
    addLoadingRequest: {
      reducer: (state, action) => {
        const { volumeId, numFiles } = action.payload;
        state.progress[volumeId] = 0;
        state.filesToLoad += numFiles;
      },
      prepare: (volumeId, numFiles) => ({ payload: { volumeId, numFiles }})
    },
    loadProgress: (state, action) => {
      const { imageId, percentComplete } = action.payload;
      if(!(imageId in state.progress)) return;
      state.progress[imageId] = Math.min(percentComplete, 95);
    },
    loadError: {
      reducer: (state, action) => {
        const { volumeIds, error } = action.payload;
        volumeIds.forEach(volumeId => {
          state.progress[volumeId] = 100;

          const [ layer ] = findLayer(state, volumeId);
          if(layer) layer.error = error.message || error;
        });
      },
      prepare: (volumeId, error) => volumeId instanceof Array
        ? { payload: { volumeIds: volumeId, error }}
        : { payload: { volumeIds: [volumeId], error }}
    },
    loadFinished: {
      reducer: (state, action) => {
        const { volume, force } = action.payload;
        const { volumeId, csRenderable } = volume;
        if(!force && !Object.keys(state.progress).find(k => k === volumeId)) return;

        console.log("LOAD FINISHED", volume)


        state.progress[volumeId] = 100;

        prepareLayer(state, volumeId, csRenderable)
      },
      prepare: (volume, force = false) => ({ payload: { volume, force }})
    },
    resetLoadProgress: (state, action) => {
      state.progress = {};
      state.filesToLoad = 0;
    },
    setViewportGrid: {
      reducer: (state, action) => {
        const { rows, cols } = action.payload;
        state.viewportGridRows = rows;
        state.viewportGridCols = cols;
        let axis = AXIS.Z;
        if(state.viewports.length < rows * cols) {
          for(let i = state.viewports.length; i < rows * cols; ++i) {
            state.viewports.push({
              id: `viewport-${i}`,
              axis: (axis++) % 3,
              created: false,
              initialized: false,
              recreated: true,
            });
          }
        } else {
          state.viewports.splice(rows * cols, state.viewports.length - rows * cols);
        }
      },
      prepare: (rows, cols) => ({ payload: { rows, cols }})
    },
    setViewportInitialized: (state, action) => {
      const viewport = state.viewports.find(v => v.id === action.payload);
      if(!viewport) return;

      const { id, flipHorizontal, flipVertical } = viewport;
      viewport.initialized = true;
      state.renderingEngine.getViewport(id).flip({ flipHorizontal });
      state.renderingEngine.getViewport(id).flip({ flipVertical });
    },
    setViewportCreated: (state, action) => {
      const viewport = state.viewports.find(v => v.id === action.payload);
      if(viewport) viewport.created = true;
    },
    setViewportAxis: {
      reducer: (state, action) => {
        const { viewportId, axis } = action.payload;
        const viewport = state.viewports.find(v => v.id === viewportId);
        if(!viewport) return;
        viewport.axis = axis;
        viewport.created = false;
        viewport.initialized = false;
        viewport.recreated = true;
        viewport.flipHorizontal = axis === AXIS.X;
        viewport.flipVertical = axis === AXIS.Z;
      },
      prepare: (viewportId, axis) => ({ payload: { viewportId, axis }})
    },
    setActiveViewport: (state, action) => {
      if(state.activeViewport === action.payload) return;
      state.activeViewport = action.payload;
    },
    setLayerVisible: {
      reducer: (state, action) => {
        const { index, on } = action.payload;
        const layer = getLayer(state, index);
        layer.visible = on;
        updateLayerVisibity(state, index);
      },
      prepare: (index, on) => ({ payload: { index, on }})
    },
    showLayerMetadata: {
      reducer: (state, action) => {
        const { index, on } = action.payload;
        getLayer(state, index).showMetadata = on;
      },
      prepare: (index, on) => ({ payload: { index, on }})
    },
    layerModified: (state, action) => {
      const layer = getLayer(state, action.payload);
      layer.actor?.modified();
      state.renderingEngine.renderViewports(
        selectViewportIds({ viewer: state })
      );
    },
    clear: (state) => {
      clearScene(state);
      return {
        ...initialState,
        renderingEngine: state.renderingEngine
      };
    },
    ...sceneReducers,
    ...toolsReducers
  },
  extraReducers: (builder) => {
    builder.addCase(initializeViewer.fulfilled, (state, action) => {
      state.renderingEngine = new RenderingEngine(RENDERING_ENGINE_ID);
    });
  }
});

export const {
  setAnalysisId, setViewportGrid,
  setViewportCreated, setViewportInitialized, setActiveViewport, setViewportAxis,
  addLayers, deleteLayer, moveLayer, layerModified,
  addLoadingRequest, loadProgress, loadError, loadFinished, resetLoadProgress,
  setRGBTransferFunctionPreset, setRGBTransferFunctionRange,
  setFunctionalROI, setScalarValuesVisible,
  setRGBTransferFunctionInterpolation, setOpacityRange,
  createRenderingEngine, initializeScene, updateScene, updateCameraPos,
  showLayerMetadata,
  setLayerVisible,
  setLayerOpacity,
  setTractographySliceMode,
  setTractographyColorMode,
  setTractographyQuality,
  setTractographyColored,
  setTractsVisible,
  setThicknessMode,
  setThicknessRepresentationMode,
  setFunctionalGroupModeROI,
  setFunctionalGroupDisplayMode,
  setFunctionalPValue,
  setFunctionalFilterArray,
  initTools, initProbeTool, clearTools, addToolsToViewport,
  setMouseLeftTool, setMouseRightTool, toolActivated, suspendActiveTool,
  setProbeToolTarget, setFilterTractsTarget, initSegmentations,
  clear,
} = viewerSlice.actions;

export { initializeViewer, findLayer, findLayerIndex, getLayer, forEachLayer };
export {
  DEFAULT_TRACTS_QUALITY, DEFAULT_TRACTS_SLICE_MODE,
  DEFAULT_THICKNESS_MODE
};

export default viewerSlice.reducer;
