import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Checkbox, Input, Select, Typography, Tag, Transfer, Table, Alert } from 'antd';
import { CheckListStyled, GroupDialogStyled } from './GroupDialog.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import {
  useDatasetsQuery,
  useGroupsQuery,
  useGroupQuery,
  useNextGroupId
} from '../../../../redux/datasets/datasets.api';

const GROUP_MODE = Object.freeze({
  NEW: 0,
  ADD: 1,
  EDIT: 2,
});

const leftTableColumns = [
  {
    dataIndex: 'label',
    title: 'Name',
  },
  {
    dataIndex: 'age',
    title: 'age',
  },
  {
    dataIndex: 'sex',
    title: 'sex',
  },
];

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          /*
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
          */
          console.log(treeSelectedKeys);
          onItemSelectAll(treeSelectedKeys)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export const CheckList = (props) => {
  const { options, selectedItems, setSelectedItems } = props;
  const [ filterTerm, setFilterTerm ] = useState(undefined);
  const [ filteredList, setFilteredList ] = useState(options);

  const checkedAll = useMemo(() => {
    if(!selectedItems) return false;
    return filteredList?.length > 0 && filteredList.every(i => selectedItems.includes(i.id));
  }, [selectedItems, filteredList])

  useEffect(() => {
    setFilteredList(filterTerm?.length > 1
      ? options.filter(i => (i.label || i.name).toLowerCase().includes(filterTerm))
      : options
    );
  }, [options, filterTerm]);

  return (<CheckListStyled>
    { !props.disabled &&
      <div className="filter-box">
        <Checkbox indeterminate={selectedItems?.length > 0 && !checkedAll}
          disabled={filteredList?.length === 0}
          checked={checkedAll}
          onChange={() => {
            setSelectedItems(checkedAll ? [] : filteredList?.map(i => i.id));
          }}
        />
        <Input allowClear
          variant="borderless"
          placeholder="Search ..."
          onChange={e => setFilterTerm(e.target.value?.toLowerCase() || "")}
        />
      </div>
    }
    { props.columns
      ? <Table
          pagination={false}
          showHeader={false}
          size="small"
          columns={props.columns}
          dataSource={filteredList}
          onRow={(record) => ({
            onClick: () => {
              if(selectedItems.includes(record.id)) {
                setSelectedItems(selectedItems.filter(i => i !== record.id));
              } else {
                setSelectedItems([...selectedItems, record.id]);
              }
            }
          })}
          rowSelection={{
            selectedRowKeys: selectedItems,
            onChange: (selectedKeys) => setSelectedItems(selectedKeys)
          }}
        />
      : <div className="scroll-box">
      <Checkbox.Group
        disabled={props.disabled}
        defaultValue={[]}
        options={filteredList}
        value={selectedItems}
        onChange={setSelectedItems}
      />
      { (!filteredList || filteredList.length === 0) &&
        <div className="empty-box">
          <FontAwesomeIcon icon={faInbox}/>
          No items
        </div>
      }
    </div>
    }
  </CheckListStyled>);
}

const GroupDialog = ({ onOk, onCancel, group, ...props }) => {
  const [ groupName, setGroupName ] = useState("");
  const [ selectedPatients, setSelectedPatients ] = useState([]);
  const [ addGroupId, setAddGroupId ] = useState(undefined);
  const [ errorMessage, setErrorMessage ] = useState(undefined);

  const [ title, mode ] = useMemo(() => {
    if(!group || group.isNew) return [ 'Create new group', GROUP_MODE.NEW ];
    if(!group.id && !group.isNew) return [ 'Add to existing group', GROUP_MODE.ADD ];
    return [ 'Edit group', GROUP_MODE.EDIT ];
  }, [group]);

  const { data } = useDatasetsQuery(!group?.editable
    ? { ids: group?.patients }
    : undefined,
    { skip: !props.open }
  );

  const { data: nextId } = useNextGroupId();

  const { data: groupsData, isLoading: isGroupsLoading } = useGroupsQuery(undefined,
    { skip: mode !== GROUP_MODE.ADD }
  );

  const { data: addGroupData } = useGroupQuery(addGroupId,
    { skip: addGroupId === undefined }
  );

  const patients = useMemo(() => {
    return data?.data.map(i => ({ key: i.id, label: i.name, age: i.age, sex: i.sex, description: i.name }));
  }, [data]);

  const groups = useMemo(() => {
    return groupsData?.data?.map(i => ({ label: i.name, value: i.id }));
  }, [groupsData]);

  const onHide = useCallback(() => {
    setGroupName(nextId ? `Group #${nextId}` : '');
    setSelectedPatients([]);
    setAddGroupId(undefined);
    setErrorMessage(undefined);
  }, [nextId]);

  useEffect(() => {
    if(!group) return;
    setGroupName(group.name);
    setSelectedPatients(group.patients);
    if(group.status === 'invalid' || group.status === 'internal-error') {
      setErrorMessage("Group contains invalid patients");
    }
  }, [group]);

  useEffect(() => {
    if(!addGroupData || !group) return;
    const uniquePatients = Array.from(new Set(
      [ ...addGroupData.patients, ...group.patients ]
    ));
    if(uniquePatients.length !== addGroupData.patients.length + group.patients.length) {
      setErrorMessage("Some patients are already in the selected group");
    } else {
      setErrorMessage(undefined);
    }
    setGroupName(addGroupData.name);
    setSelectedPatients(uniquePatients);
  }, [addGroupData, group]);

  useEffect(() => {
    if(nextId) setGroupName(`Group #${nextId}`);
  }, [nextId]);

  return (<GroupDialogStyled {...props}
    title={title}
    onCancel={() => {
      onHide();
      if(onCancel) onCancel();
    }}
    onOk={() => {
      if(onOk) onOk({
        id: mode === GROUP_MODE.ADD ? addGroupId : group?.id,
        name: groupName,
        patients: selectedPatients
      });
      onHide();
    }}
    okButtonProps={{
      disabled: selectedPatients.length === 0
    }}
  >
    { mode === GROUP_MODE.ADD
      ? <div className='gd-select'>
          <Typography.Text>
            Select group:
          </Typography.Text>
          <Select
            options={groups}
            loading={isGroupsLoading}
            onChange={setAddGroupId}
          />
        </div>
      : <Input addonBefore='Group name'
          placeholder={'Enter patients group name'}
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
        />
    }
    { errorMessage &&
      <Alert showIcon type={'warning'} message={errorMessage}/>
    }
    <Typography.Text>
      { group?.editable ? "Select subjects:" : "Selected subjects:" }
    </Typography.Text>
    {/*
    <TableTransfer
      leftColumns={leftTableColumns}
      dataSource={patients}
      showSearch
      targetKeys={selectedPatients}
      onChange={setSelectedPatients}
      titles={['All subjects', 'Group']}
      filterOption={(inputValue, item) =>
        item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      }
      render={i => i.label}
      listStyle={{
        height: 300
      }}
    />*/}

    <CheckList disabled={group && !group.editable}
      columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text) => <div style={{maxWidth: "150px"}}> {text} </div>
          },
          {
            title: 'Age',
            dataIndex: 'age'
          },
          {
            title: 'Sex',
            dataIndex: 'sex'
          },
          {
            title: 'Tags',
            dataIndex: 'tags',
            render: (_, record) => record.tags?.map(t => <Tag key={t}>{t}</Tag>)
          }
      ]}
      options={data?.data}
      selectedItems={selectedPatients}
      setSelectedItems={setSelectedPatients}
    />
  </GroupDialogStyled>);
}

export default GroupDialog;
