import { ProbeTool, utilities, cursors, drawing } from '@cornerstonejs/tools';
import { getEnabledElement, cache } from '@cornerstonejs/core';

const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { hideElementCursor } = cursors.elementCursor;
const { drawHandles: drawHandlesSvg, drawTextBox: drawTextBoxSvg } = drawing;

const transformWorldToIndex = (viewport, volumeId, point) => {
  return viewport.getImageData(volumeId).imageData.worldToIndex(point).map(Math.round);
}

class DataProbeTool extends ProbeTool {
  static toolName;

  addNewAnnotation = (evt) => {
    const { currentPoints, element } = evt.detail;
    const { viewport, renderingEngine } = getEnabledElement(element);
    const { viewPlaneNormal, viewUp } = viewport.getCamera();
    const worldPos = currentPoints.world;

    this.isDrawing = true;

    const annotation = {
      invalidated: true,
      highlighted: true,
      isVisible: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal],
        viewUp: [...viewUp],
        FrameOfReferenceUID: viewport.getFrameOfReferenceUID(),
        referencedImageId: this.configuration.targetId,
      },
      data: {
        label: '',
        handles: { points: [[...worldPos]] },
        cachedStats: {},
      },
    };

    const viewportIdsToRender = getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      newAnnotation: true,
      viewportIdsToRender,
    };
    this._activateModify(element);

    hideElementCursor(element);

    evt.preventDefault();

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  }

  renderAnnotation = (enabledElement, svgDrawingHelper) => {
    if(!this.editData) return false;

    const { viewport } = enabledElement;

    const annotations = this.filterInteractableAnnotationsForElement(
      viewport.element,
      [this.editData.annotation]
    );

    if(!annotations?.length) return false;
    // console.log(annotations);

    // const targetId = this.getTargetId();
    // const renderingEngine = viewport.getRenderingEngine();

    const annotation = this.editData.annotation;
    const { annotationUID, data } = annotation;
    const point = data.handles.points[0];
    const canvasCoordinates = viewport.worldToCanvas(point);

    const styleSpecifier = {
      toolGroupId:  this.toolGroupId,
      toolName:     this.getToolName(),
      viewportId:   viewport.id,
      annotationUID
    };

    const color = this.getStyle('color', styleSpecifier, annotation);
/*
    if (!data.cachedStats[targetId]) {
      data.cachedStats[targetId] = {
        Modality: null,
        index: null,
        value: null,
      };

      this._calculateCachedStats(annotation, renderingEngine, enabledElement);
    } else if (annotation.invalidated) {
      this._calculateCachedStats(annotation, renderingEngine, enabledElement);
    }
*/
    // If rendering engine has been destroyed while rendering
    if (!viewport.getRenderingEngine()) {
      console.warn('Rendering Engine has been destroyed');
      return false;
    }

    const { targetId } = this.configuration;
    const { dimensions, scalarData, metadata, data: polyData } = cache.getVolume(targetId);

    let textLines = undefined;

    if(polyData) {
      const actor = viewport.getActors().find(a => a.referenceId === targetId);
      if(!actor) {
        console.warn("Failed to find actor for volumeId", targetId);
        return false;
      }

      const data = actor.actor.getMapper().getInputData();
      const points = data.getPoints().getData();
      const names =  data.getPointData().getArrayByName('Names');
      let minDist = 1e6;
      let minIdx = undefined;
      for(let i = 0; i < points.length; i += 3) {
        const d = (points[i] - point[0]) * (points[i] - point[0])
                + (points[i + 1] - point[1]) * (points[i + 1] - point[1])
                + (points[i + 2] - point[2]) * (points[i + 2] - point[2]);
        if(d < minDist) {
          minDist = d;
          minIdx = i / 3;
        }
      }

      const value = minDist < 4 * 3.5 * 3.5 // TODO: replace by constant or actual scaled point radius
        ? data.getPointData().getArrayByName('Scalars').getData()[minIdx]
        : undefined;

      const name = value && names && names.getData()[minIdx];

      textLines = [
        `[${point[0].toFixed(2)}, ${point[1].toFixed(2)}, ${point[2].toFixed(2)}]`,
        `F: ${value ? value.toFixed(3) : '---'}`,
        `ROI: ${name ? name : '---'}`
      ];

    } else {
      const index = transformWorldToIndex(viewport, targetId, point);
      const { dataRangeUnscale, lookupTableAnnotations, Units, Modality } = metadata;

      let value = scalarData[index[0] + dimensions[0] * index[1] + dimensions[0] * dimensions[1] * index[2]];

      if(lookupTableAnnotations) {
        value = value !== 0
          ? lookupTableAnnotations.find(i => i.index === value)?.name
          : undefined;
      } else {
        value = dataRangeUnscale ? dataRangeUnscale(value) : value;
        value = value ? value.toFixed(3) : undefined;
      }

      textLines = [
        `[${index[0]}, ${index[1]}, ${index[2]}]`,
        `${Modality}: ${value || '---'} ${Units || ''}`
      ];
    }

    const handleGroupUID = '0';
    const textUID = '0';

    drawHandlesSvg(
      svgDrawingHelper,
      annotationUID,
      handleGroupUID,
      [canvasCoordinates],
      { color }
    );

    drawTextBoxSvg(
      svgDrawingHelper,
      annotationUID,
      textUID,
      textLines,
      canvasCoordinates,
      {
        ...this.getLinkedTextBoxStyle(styleSpecifier, annotation),
        background: 'rgba(0, 0, 0, 0.3)',
        padding: 5
      }
    );

    return true;
  };
}

DataProbeTool.toolName = 'ThicknessProbe';
export default DataProbeTool;
