import styled from 'styled-components';

const RunStyled = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  padding-bottom: 12px;
  max-width: 55%;

  .ant-tabs-nav {
    padding-left: 16px;
  }

  .ant-btn {
    margin-left: 16px;
    svg {
      margin-right: 0.3em;
    }
  }

  .config-group {
    padding: 12px 16px;
    padding-top: 0;
    display: flex;
    flex-direction: column;

    > span:first-child {
      margin-bottom: 4px;
    }
  }

  .config-error {
    margin: 16px;
    margin-top: 0;

    ul {
      margin: 0;
      padding-left: 16px;
    }

    .anticon {
      align-self: baseline;
      margin-top: 5px;
    }
  }
`;

export default RunStyled;
