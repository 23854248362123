import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Menu, Empty } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBrain, faAreaChart, faChartBar, faChartPie, faImage, faSitemap, faTableList
} from '@fortawesome/free-solid-svg-icons';
import { selectAnalysisId } from '../../../../redux/viewer/viewer.selectors';
import { useAnalysis } from '../../../../redux/analyzes/analyzes.api';
import {
  QADenoisingPlot, QATimeseriesPlot, QASlicesPlot
} from './FunctionalPlots';
import QATracts from './TractographyPlots';
import QAFsqc from './MorphometryPlots';
import QADialogStyled from './QADialog.styled';

const CONN_QA = [
  {
    name: 'QA_DENOISE_QC-FC.measureQC_InvalidScans',
    suffix: '.mat',
    icon: <FontAwesomeIcon icon={faAreaChart}/>,
    plotFn: (url) => <QADenoisingPlot url={url}/>
  },
  {
    name: 'QA_DENOISE_QC-FC.measureQC_MeanMotion',
    suffix: '.mat',
    icon: <FontAwesomeIcon icon={faAreaChart}/>,
    plotFn: (url) => <QADenoisingPlot url={url}/>
  },
  {
    name: 'QA_DENOISE_QC-FC.measureQC_ProportionValidScans',
    suffix: '.mat',
    icon: <FontAwesomeIcon icon={faAreaChart}/>,
    plotFn: (url) => <QADenoisingPlot url={url}/>
  },
  {
    name: 'QA_DENOISE_timeseries',
    suffix: '.subject001.session001.mat',
    icon: <FontAwesomeIcon icon={faChartBar}/>,
    plotFn: (url) => <QATimeseriesPlot url={url} scaleX={4} scaleY={0.15}/>
  },
  {
    name: 'QA_NORM_functionalDataset0',
    suffix: '.subject001.session001.mat',
    icon: <FontAwesomeIcon icon={faImage}/>,
    plotFn: (url, id) => <QASlicesPlot url={url}
      title={'QA_NORM_functionalDataset0'}
      maskThreshold={0.33}
      segmentationId={'nii:/static/referenceGM.nii'}
      volumeId={`nii:/results/${id}/conn/wart_mean_aufunc_0.nii`}
    />
  },
  {
    name: 'QA_NORM_structural',
    suffix: '.subject001.session001.mat',
    icon: <FontAwesomeIcon icon={faImage}/>,
    plotFn: (url, id) => <QASlicesPlot url={url}
      title={'QA_NORM_structural'}
      maskThreshold={0.33}
      segmentationId={'nii:/static/referenceGM.nii'}
      volumeId={`nii:/results/${id}/conn/wc0cstruct_0.nii`}
    />
  },
  {
    name: 'QA_REG_functionalDataset0',
    suffix: '.subject001.session001.mat',
    icon: <FontAwesomeIcon icon={faImage}/>,
    plotFn: (url, id) => <QASlicesPlot url={url}
      title={'QA_REG_functionalDataset0'}
      maskThreshold={0.6}
      segmentationId={`nii:/results/${id}/conn/wc0cstruct_0.nii`}
      volumeId={`nii:/results/${id}/conn/wart_mean_aufunc_0.nii`}
    />
  },
  {
    name: 'QA_REG_WhiteMatter_structural',
    suffix: '.subject001.session001.mat',
    icon: <FontAwesomeIcon icon={faImage}/>,
    plotFn: (url, id) => <QASlicesPlot url={url}
      title={'QA_REG_WhiteMatter_structural'}
      maskThreshold={0.6}
      segmentationId={`nii:/results/${id}/conn/wc2cstruct_0.nii`}
      volumeId={`nii:/results/${id}/conn/wc0cstruct_0.nii`}
    />
  },
  {
    name: 'QA_TIMEART_functional',
    suffix: '.subject001.mat',
    icon: <FontAwesomeIcon icon={faChartBar}/>,
    plotFn: (url) => <QATimeseriesPlot url={url} scaleX={2} scaleY={2}/>
  }
];

const DSI_QA = [
  {
    name: 'Sources',
    icon: <FontAwesomeIcon icon={faTableList}/>,
    plotFn: (id) => <QATracts id={id} dataKey='qc_src'/>
  },
  {
    name: 'Reconstruction',
    icon: <FontAwesomeIcon icon={faTableList}/>,
    plotFn: (id) => <QATracts id={id} dataKey='qc_fib'/>
  }
];

const FS_QA = [
  {
    name: 'FSQC',
    icon: <FontAwesomeIcon icon={faTableList}/>,
    plotFn: (id) => <QAFsqc id={id}/>
  }
]

// ----------------------------------------------------------------------------

const QADialog = ({ id, ...props }) => {
  const [ activePlot, setActivePlot ] = useState(undefined);

  const { data: analysis } = useAnalysis(id, { skip: !id || !props.open });

  const menuItems = useMemo(() => {
    if(!analysis) return undefined;
    const analyzers = analysis.config?.analyzers || [];

    const items = [];
    if(analyzers.includes('conn')) {
      items.push({
        key: 'conn',
        label: 'Functional',
        icon: <FontAwesomeIcon icon={faBrain}/>,
        children: CONN_QA.map(({ name,  icon }, i) => (
          { key: name, label: name, icon }
        ))
      });
    }
    if(analyzers.includes('dsi')) {
      items.push({
        key: 'dsi',
        label: 'Tractography',
        icon: <FontAwesomeIcon icon={faSitemap}/>,
        children: DSI_QA.map(({ name, icon }, i) => (
          { key: name, label: name, icon }
        ))
      });
    }
    if(analyzers.includes('freesurfer')) {
      items.push({
        key: 'freesurfer',
        label: 'Morphometry',
        icon: <FontAwesomeIcon icon={faChartPie}/>,
        children: FS_QA.map(({ name, icon }, i) => (
          { key: name, label: name, icon }
        ))
      })
    }
    return items;
  }, [analysis]);

  useEffect(() => {
    return () => setActivePlot(undefined);
  }, []);

  return <QADialogStyled
    width={window.innerWidth - 32}
    footer={null}
    title="QA"
    centered={true}
    destroyOnClose={true}
    {...props}
  >
    <div className="qa-content">
      <Menu mode="inline"
        onClick={e => {
          const analyzer = e.keyPath[1];
          if(analyzer === 'conn') {
            const { name, suffix, plotFn } = CONN_QA.find(i => i.name === e.key);
            const url = `/results/${id}/conn/results/qa/output/${name}${suffix}`;
            setActivePlot(plotFn && plotFn(url, id));
          } else if(analyzer === 'dsi') {
            const { plotFn } = DSI_QA.find(i => i.name === e.key);
            setActivePlot(plotFn && plotFn(id));
          } else if(analyzer === 'freesurfer') {
            const { plotFn } = FS_QA.find(i => i.name === e.key);
            setActivePlot(plotFn && plotFn(id));
          }
        }}
        items={menuItems}
        defaultOpenKeys={['conn', 'dsi']}
        style={{ width: '256px' }}
      />
      <div className="qa-data">
        { !activePlot
          ? <Empty description={"Select plot"}/>
          : activePlot
        }
      </div>
    </div>
  </QADialogStyled>
}

const mapState = (state, props) => ({
  id: selectAnalysisId(state)
});

export default connect(mapState)(QADialog);
