import { message } from 'antd';
import * as dicomParser from 'dicom-parser';
import * as pako from 'pako';
import * as untar from 'js-untar';
import JSZip from 'jszip';

const defaultFileReader = async (file) => {
  return file.arrayBuffer().then(buf => new Uint8Array(buf));
}

const zipFileReader = async (file) => {
  return file.async('uint8array');
}

const tarFileReader = async (file) => {
  return new Uint8Array(file.buffer);
}

export const readDicomData = async (file) => {
  if(file._data) return zipFileReader(file);
  if(file.uname) return tarFileReader(file);
  return defaultFileReader(file);
}

const getSeriesTag = (name) => {
  if(name.match(/_*mpr*/i))  return 'structural';
  if(name.match(/_*bold*/i)) return 'functional';
  if(name.match(/_*mddw*/i)) {
    return /tracew|adc/i.test(name) ? undefined : 'tracts';
  }
  return undefined;
}

const extractNamesForAnonymous = (patients, anons) => {
  let trimmed;
  do {
    trimmed = 0;
    const prefixes = {};

    anons.forEach(k => {
      const { name } = patients[k].patient;
      const prefix = name.substring(0, name.indexOf('/'));
      if(prefix in prefixes) prefixes[prefix].push(k);
      else prefixes[prefix] = [k];
    });

    for(let [k, v] of Object.entries(prefixes)) {
      if(v.length === 1) continue;
      v.forEach(i => {
        patients[i].patient.name = patients[i].patient.name.substring(k.length + 1);
      });
      ++trimmed;
    };
  } while(trimmed > 0);

  anons.forEach(k => {
    const { name } = patients[k].patient;
    if(name.indexOf('/') > 0) {
      patients[k].patient.name = name.substring(0, name.indexOf('/'));
    }
  });
}

const findPatient = (list, name) => {
  let maxLength = 0;
  let maxKey = undefined;
  const names = Object.keys(list);

  const prefix = name.substring(0, name.lastIndexOf('/'));

  for(let i = 0; i < names.length; ++i) {
    const p = names[i];
    if(!list[p].isAnonymous) continue;
    if(p.substring(0, p.lastIndexOf('/')) === prefix) return p;

/* Uncomment to enable min prefix matching
    let j = 0;
    const maxj = Math.min(p.lastIndexOf('/'), name.lastIndexOf('/'));
    for(; j < maxj; ++j) {
      if(p[j] !== name[j]) break;
    }

    if(j === maxj) return p;
    --j;
    if(p[j] !== '/') continue;
    if(maxLength < j) {
      maxLength = j;
      maxKey = p;
    }
*/
  }

  return maxLength === 0 ? undefined : maxKey;
}

const parseDicomFiles = async (files, onProgress, readFile) => {
  if(!files) return undefined;

  const patients = {};
  let invalidFiles = 0;

  for(let i = 0; i < files.length; ++i) {
    let isAnonymous = false;
    const file = files[i];
    const data = await readFile(file);

    // TODO: add error handling
    let dcm = undefined;
    try {
      dcm  = dicomParser.parseDicom(data, { untilTag: 'x00200052' });
    } catch {
      invalidFiles++;
    }

    if(!dcm) continue;

    let patientName    = dcm.string('x00100010');
    const seriesName   = dcm.string('x0008103e').replace(/\s/g, '');
    const seriesNumber = dcm.string('x00200011');

    if(!patientName) {
      let path = file.webkitRelativePath || file.name;
      path = path.substring(0, path.lastIndexOf('/'));

      const matchingName = findPatient(patients, path);
      if(matchingName && matchingName !== path) {
        patientName = matchingName;
      } else {
        patientName = path;
      }

      isAnonymous = true;
    }

    if(!(patientName in patients)) {
      patients[patientName] = {
        isAnonymous,
        patient: {
          name: patientName,
          age:  parseInt(dcm.string('x00101010')),
          sex:  dcm.string('x00100040') || 'U',
        },
        series: {}
      };
    }

    const seriesId = `${parseInt(seriesNumber) < 10 ? '0' : ''}${seriesNumber}_${seriesName}`;
    if(!(seriesId in patients[patientName].series)) {
      patients[patientName].series[seriesId] = [];
    }
    patients[patientName].series[seriesId].push(file);

    onProgress(Math.floor(i / files.length * 100));
  }

  if(invalidFiles > 0) {
    console.warn(`Skipped ${invalidFiles} invalid files`);
  }

  const anons = Object.entries(patients).filter(([k, v]) => v.isAnonymous).map(i => i[0]);
  if(anons.length > 0) {
    extractNamesForAnonymous(patients, anons);
  }

  return patients;
}

export const processDicoms = async (files, onProgress) => {
  if(!files) return undefined;

  onProgress(0);

  if(files.length === 1 && files[0].name.toLowerCase().endsWith('.zip')) {
    return files[0].arrayBuffer().then(buf => {
      const data = new Uint8Array(buf);
      const zip  = new JSZip();
      return zip.loadAsync(data);
    }).then(zip => {
      const dcms = zip.filter((_, file) => {
        return !file.dir && file.name.toLowerCase().endsWith('.dcm');
      });
      return parseDicomFiles(dcms, onProgress, zipFileReader);
    }).catch(error => {
      message.error("Unable to read zip file")
      console.error(error)
    });
  } else if(files.length === 1 && files[0].name.toLowerCase().endsWith('.tar.gz')) {
    return files[0].arrayBuffer().then(async buf => {
      const inflator = new pako.Inflate();
      const data = new Uint8Array(buf);

      const chunkSize = 64 * 1024;
      for(let i = 0; i < data.length; i += chunkSize) {
        await new Promise((resolve) => {
          inflator.push(data.slice(i, i + chunkSize));
          setTimeout(() => resolve(true), 0); // <- to enable progress
          // resolve(true);
        }).then(() => {
          onProgress(Math.round(i / data.length * 100));
        });
      }

      return inflator.result;
    }).then(data => {
      return untar(data.buffer);
    }).then(tar => {
      const dcms = tar.filter(file => file.name.toLowerCase().endsWith('.dcm'));
      return parseDicomFiles(dcms, onProgress, tarFileReader);
    });
  } else {
    return parseDicomFiles(files, onProgress, defaultFileReader);
  }
}

export const createFileTree = async (seriesData) => {
  if(!seriesData) return [];

  const tree = Object.values(seriesData).reduce((list, { patient, series }) => {
    list.push({
      patient,
      files: Object.entries(series).sort((a, b) => a[0] > b[0]).reduce((a, s) => {
        a.push({
          title: s[0],
          key: s[0],
          tag: getSeriesTag(s[0]),
          children: s[1].map(f => ({
            title:  f.name,
            key:    f.name,
            size:   f.size || f._data?.uncompressedSize,
            file:   f
          }))
        });
        return a;
      }, [])
    });
    return list;
  }, []);

  return filterTags(tree);
}

const filterTags = (tree) => {
  tree.forEach(p => {
    const tags = {};
    p.files.forEach((s, i) => {
      if(!s.tag) return;
      const d = { index: i, count: s.children.length };
      if(!tags[s.tag]) tags[s.tag] = [d];
      else tags[s.tag].push(d);
    });

    Object.entries(tags).forEach(([k, v]) => {
      if(v.length < 2) return;
      const [ maxIndex ] = v.reduce((a, t) => {
        return a[1] <= t.count ? [ t.index, t.count ] : a
      }, [undefined, 0]);
      v.forEach(({ index }) => {
        if(index !== maxIndex) p.files[index].tag = undefined;
      });
    });
  });

  return tree;
}
