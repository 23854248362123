import React from 'react';
import { Typography } from 'antd';
import { cache } from '@cornerstonejs/core';
import MetadataPanelStyled from './MetadataPanel.styled';

const { Text } = Typography;

const MetadataPanel = ({ volumeId, ...props }) => {
  const metadata = cache.getVolume(volumeId)?.metadata;

  return (<MetadataPanelStyled>
    { metadata?.parsingTime && <>
      <Text ellipsis={true}> Parsing time </Text>
      <span> {metadata.parsingTime} ms </span>
    </>}
    {
      metadata?.voxelizationTime && <>
        <Text ellipsis={true}> Voxelization time </Text>
        <span> {metadata.voxelizationTime} ms </span>
      </>
    }
  </MetadataPanelStyled>);
}

export default MetadataPanel;
