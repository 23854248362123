import React, { useMemo } from 'react';
import { Table, Typography, Spin } from 'antd';
import { useFreeSurferQAFile } from '../../../../redux/analyzes/analyzes.api';

const QAFSQC = ({ id, dataKey, ...props }) => {
  const { data, error, isLoading } = useFreeSurferQAFile(id);

  const columns = useMemo(() => {
    if(!data) return undefined;
    return data.split('\n')[0]?.split(',').map((c, i) => ({
      title: c, dataIndex: `${i}`
    }));
  }, [data]);

  const dataSource = useMemo(() => {
    if(!data) return undefined;
    const lines = data.split('\n');
    const res = [];
    for(let i = 1; i < lines.length; ++i) {
      const d = lines[i].split(',');
      if(d.length < columns.length) continue;
      res.push(d.reduce((a, v, i) => {
        a[`${i}`] = i > 0 ? Math.round(parseFloat(v) * 10e5) / 10e5 : v;
        return a;
      }, {}));
    }
    return res;
  }, [data]);

  if(!data || isLoading) return <Spin/>;
  if(error) return "Error";

  return <div style={{ overflow: 'auto', width: 'calc(100% - 170px)' }}>
    <Table
      rowKey={(record) => record['0']}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      size='small'
    />
  </div>

}

export default QAFSQC;
