import React, { useRef, useEffect, useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as cornerstone from '@cornerstonejs/core';
import { toCornerstoneAxis } from '../../../../constants/axes';
import {
  selectRenderingEngine,
  selectViewportAxis,
  selectVolumeSize,
  selectVolumeSpacing
} from '../../../../redux/qa_viewer/qa_viewer.selectors';
import {
  setViewportReady
} from '../../../../redux/qa_viewer/qa_viewer.slice';
import QAViewportStyled from './QAViewport.styled';

const { ViewportType } = cornerstone.Enums;
const { CAMERA_MODIFIED } = cornerstone.Enums.Events;

const QAViewport = ({ renderingEngine, viewportId, axis, ...props}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [ sliceIdx, setSliceIdx ] = useState(undefined);
  const { dim, spacing } = props;

  const onCameraModified = useCallback(() => {
    if(!ref.current) return;
    try {
      setSliceIdx(renderingEngine.getViewport(viewportId)?.getCurrentImageIdIndex());
    } catch {
      // do nothing
    }
  }, [renderingEngine, viewportId]);

  useEffect(() => {
    if(!ref.current || !renderingEngine) return;

    renderingEngine.enableElement({
      viewportId,
      element: ref.current,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        background: [0, 0, 0],
        orientation: toCornerstoneAxis(axis)
      }
    });

    dispatch(setViewportReady(viewportId));
    ref.current.addEventListener(CAMERA_MODIFIED, onCameraModified);

    return () => {
      renderingEngine.disableElement(viewportId);
    }
  }, [renderingEngine, viewportId, axis, onCameraModified, dispatch]);

  return <QAViewportStyled ref={ref}>
    <div className="viewport-element"
      style={{position: 'relative', width: '100%', height: '100%'}}
    />
    <div className="overlay">
      { (!isNaN(sliceIdx) && dim && spacing)
        ? `z = ${Math.round((sliceIdx - dim / 2) * spacing)}`
        : undefined
      }
    </div>
  </QAViewportStyled>
}

const mapState = (state, props) => ({
  renderingEngine: selectRenderingEngine(state),
  axis: selectViewportAxis(state, props.viewportId),
  dim: selectVolumeSize(state, props.viewportId),
  spacing: selectVolumeSpacing(state, props.viewportId)
});

export default connect(mapState, null)(QAViewport);
