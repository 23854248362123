import { fetchData, elapsed } from '../cornerstone/fetcher';
import BinaryReader from '../binReader';
import { ImageVolume } from '@cornerstonejs/core';
import { ungzip } from 'pako';

import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';

// TRK file format: http://trackvis.org/docs/?subsect=fileformat
const MGH_HEADER_SIZE = 283;
const MGH_TAG = {
  COLORTABLE: 1
};

const MGH_DATA_TYPE = {
  UCHAR: 0,
  INT:   1,
  FLOAT: 3,
  SHORT: 4
};

const decompress = async (volumeId, data) => {
  if(volumeId.endsWith('.mgz')) return ungzip(data).buffer;
  return data;
}

const int8Reader = (data, offset, isLittleEndian) => {
  const a = data.getInt32(offset, isLittleEndian);
  const b = data.getInt32(offset + 4, isLittleEndian);
  return { data: (a << 32) + b, size: 8 };
}

const readColorTable = (r) => {
  const lut = {
    magic:      r.scan('int'),
    colorRange: r.scan('int'),
    file:       r.scan('string', r.scan('int')),
    colors:     new Map()
  }

  const numValues = r.scan('int');
  for(let i = 0; i < numValues; ++i) {
    const index = r.scan('int');
    const name  = r.scan('string', r.scan('int'));
    const color = r.scan('int', 4);
    lut.colors.set(index, { name, color: color.slice(0, 3) });
  }

  return lut;
}

const readTags = (r) => {
  const tags = [];
  while(!r.atEnd()) {
    const type = r.scan('int');
    if(type === MGH_TAG.COLORTABLE) {
      tags.push({ type, value: readColorTable(r) });
    } else {
      const length = r.scan('int8');
      tags.push({ type, value: r.scan('string', length) });
    }
  }
  return tags;
}

const findColorTable = (tags) => {
  return tags?.find(({ type }) => type === MGH_TAG.COLORTABLE)?.value?.colors;
}

const buildLookupTable = ({ tags }, categories) => {
  const colors = findColorTable(tags);
  if(!colors) return undefined;

  const ctf = vtkColorTransferFunction.newInstance();
  const sof = vtkPiecewiseFunction.newInstance();
  sof.addPointLong(0, 0.0, 0.5, 1.0);

  const annotations = [];
  for(let c of categories) {
    const item = colors.get(c);
    if(!item?.color) continue;

    ctf.addRGBPoint(c, ...item.color.map(i => i / 255.0));
    if(c !== 0) {
      // sof.addPoint(c - 0.49, 1.0, 1.0);
      // sof.addPointLong(c, 1.0, 1.0, 1.0);
      // sof.addPoint(c + 0.49, 1.0, 1.0);
      sof.addPointLong(c, 1.0, 0.5, 1.0);
    }
    annotations.push({ index: c, ...item });
  }

  return { ctf, sof, annotations };
}

// ----------------------------------------------------------------------------

const loadMGZ = (volumeId, options) => {
  return fetchData(volumeId, options)
  .then(data => elapsed(() => decompress(volumeId, data)
  .then(data => {
    const r = new BinaryReader(data);
    r.setLittleEndian(false);
    r.addCustomReader('int8', int8Reader);
    const header = {
      version:     r.scan('int'),
      width:       r.scan('int'),
      height:      r.scan('int'),
      depth:       r.scan('int'),
      nframes:     r.scan('int'),
      type:        r.scan('int'),
      dof:         r.scan('int'),
      goodRASFlag: r.scan('short'),
      spacingX:    r.scan('float'),
      spacingY:    r.scan('float'),
      spacingZ:    r.scan('float'),
      xr:          r.scan('float'), // default is -1
      xa:          r.scan('float'), // default is 0
      xs:          r.scan('float'), // default is 0
      yr:          r.scan('float'), // default is 0
      ya:          r.scan('float'), // default is 0
      ys:          r.scan('float'), // default is -1
      zr:          r.scan('float'), // default is 0
      za:          r.scan('float'), // default is 1
      zs:          r.scan('float'), // default is 0
      cr:          r.scan('float'), // default is 0
      ca:          r.scan('float'), // default is 0
      cs:          r.scan('float'), // default is 0
      unused:      r.scan('byte', MGH_HEADER_SIZE - 22 * 4 - 1)
    };

    if(header.type !== MGH_DATA_TYPE.INT && header.type !== MGH_DATA_TYPE.FLOAT) {
      // TODO: add support for other data types
      throw Error(`Unsupported data type ${header.type} for ${volumeId}`);
    }

    console.log("MGH HEADER", header);

    const isFloatData = header.type === MGH_DATA_TYPE.FLOAT;

    const { width, height, depth } = header;

    const size = width * height * depth * header.nframes;
    const scalarData = new Uint32Array(size);
    const categories = new Set();
    let minPixelValue = 10e6;
    let maxPixelValue = 10e-6;

    // mgz orientation is coronal, so we should swap z and y axes
    header.dimensions = [ width, depth, height ];
    header.spacing    = [ header.spacingX, header.spacingZ, header.spacingY ];
    for(let z = 0; z < depth; ++z) {
      for(let y = 0; y < height; ++y) {
        for(let x = 0; x < width; ++x) {
          const val = r.scan(isFloatData ? 'float' : 'int');
          if(maxPixelValue < val) maxPixelValue = val;
          else if(minPixelValue > val) minPixelValue = val;
          scalarData[x + z * width + (height - y - 1) * width * depth] = val;
          if(!isFloatData) categories.add(val);
        }
      }
    }

    let optionalData = {};
    if(r.offset !== data.byteLength) {
      optionalData = {
        tr:         r.scan('float'),
        flipAngle:  r.scan('float'),
        te:         r.scan('float'),
        ti:         r.scan('float'),
        fov:        r.scan('float'),
        tags:       readTags(r)
      };
    }

    const { ctf, sof, annotations } = buildLookupTable(optionalData, categories) || {};

    return {
      header: { ...header, ...optionalData },
      data: scalarData,
      metadata: {
        minPixelValue,
        maxPixelValue,
        lookupTable: ctf,
        scalarOpacity: sof,
        lookupTableAnnotations: annotations,
        dataRange: [ minPixelValue, maxPixelValue ]
      },
      size
    };
  })));
}

const mgzVolumeLoader = (volumeId, options) => {
  return {
    promise: loadMGZ(volumeId, options).then(({ header, data, metadata, size }) => {
      const vol = new ImageVolume({
        volumeId,
        imageIds: [],
        dimensions: header.dimensions,
        spacing:    header.spacing,
        origin:     header.dimensions.map((d, i) => -d * header.spacing[i] / 2),
        metadata:   {
          FrameOfReferenceUID: 'default',
          Modality: metadata.lookupTableAnnotations ? 'Segment' : 'CT',
          PhotometricInterpretation: 'MONOCHROME2',
          PixelRepresentation: 0,
          ...metadata
        },
        direction:   [1, 0, 0, 0, 1, 0, 0, 0, 1],
        scalarData:  data,
        sizeInBytes: size,
      });
      vol.csRenderable = false;
      vol.isReady = true;
      return vol;
    }),
    cancel: undefined /* TODO */
  }
}

export { loadMGZ };
export default mgzVolumeLoader;
