import React, { useMemo, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Alert, Table, Tag, Pagination } from 'antd';
import PatientsStyled, { NameLinkStyled } from './Patients.styled';
import {
  useDatasetsQuery
} from '../../../../redux/datasets/datasets.api';
import {
  setSelectedDatasets
} from '../../../../redux/datasets/datasets.slice';
import {
  selectCheckedDatasets
} from '../../../../redux/datasets/datasets.selectors';
import { STATUS_ICON } from '../../../../constants';

const DEFAULT_DATASETS_PAGE_SIZE = 25;
const DEFAULT_SORT_COLUMN = 'created';
const DEFAULT_SORT_ORDER= 'descend';

// https://stackoverflow.com/a/18650828
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 bytes';
  else if (!bytes) return 'unknown';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const paramsObject = (searchParams) => {
  return Object.fromEntries(searchParams.entries());
}

// ----------------------------------------------------------------------------

const NameLink = ({ children, ...props }) => {
  const ref = useRef(null);
  const [ isOverflown, setIsOverflown ] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if(element) setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <NameLinkStyled to={props.to} ref={ref} title={isOverflown ? props.title : undefined}>
      {children}
    </NameLinkStyled>
  );
}

// ----------------------------------------------------------------------------

const Patients = ({ selectedDatasets, ...props }) => {
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    return () => dispatch(setSelectedDatasets([]))
  }, [dispatch]);

  const currentPage = useMemo(() => {
    return parseInt(searchParams.get('page')) || 1;
  }, [searchParams]);

  const columns = useMemo(() => {
    const sortColumn = searchParams.get('orderBy') || DEFAULT_SORT_COLUMN;
    const sortOrder = searchParams.get('sortOrder') || DEFAULT_SORT_ORDER;
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        defaultSortOrder: sortColumn === 'name' && sortOrder,
        width: '300px',
        render: (text, record) =>
          <NameLink to={`../datasets/${record.id}`} title={text?.length ? text : undefined}>
            { STATUS_ICON[record.status] }
            { text?.length ? text : record.name?.length ? record.name : <i>{'<unnamed>'}</i> }
          </NameLink>
      },
      {
        title: 'Age',
        dataIndex: 'age',
        sorter: true,
        defaultSortOrder: sortColumn === 'age' && sortOrder,
      },
      {
        title: 'Sex',
        dataIndex: 'sex',
        render: (text) => text === 'U' ? '-' : text
      },
      {
        title: 'Size',
        dataIndex: 'size',
        sorter: true,
        defaultSortOrder: sortColumn === 'size' && sortOrder,
        render: (text) => formatBytes(text, 1)
      },
      {
        title: 'Data',
        dataIndex: 'tags',
        render: (_, record) => record.tags.map(t => <Tag key={t}>{t}</Tag>)
      },
      {
        title: 'Added',
        dataIndex: 'created',
        sorter: true,
        defaultSortOrder: sortColumn === 'created' && sortOrder,
        render: (text) => new Date(Number(text) * 1000).toLocaleString()
      }
    ]
  }, [searchParams]);

  const pageSize = localStorage.getItem("datasetsPageSize") || DEFAULT_DATASETS_PAGE_SIZE;

  const { data: patients, isLoading } = useDatasetsQuery({
    from: (currentPage - 1) * pageSize,
    count: pageSize,
    orderBy: searchParams.get('orderBy'),
    sortOrder: searchParams.get('sortOrder') === 'ascend' ? 'asc' : 'desc'
  });

  const { data, total } = useMemo(() => {
    return patients
      ? { ...patients, data: patients.data.map(i => ({ ...i, key: i.id })) }
      : {}
  }, [patients]);

  return (<PatientsStyled>
    { data?.length === 0 &&
      <Alert type="info" showIcon closable
        message={<span>
          Go to <Link to="../upload">Uploads</Link> to add new datasets
        </span>}
      />
    }
    <span className="pagination">
      <Pagination
        showSizeChanger
        hideOnSinglePage={true}
        pageSize={pageSize}
        pageSizeOptions={[10, 25, 50]}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("datasetsPageSize", size)
        }}
        current={currentPage}
        total={total}
        onChange={(page) => {
          setSearchParams({ ...paramsObject(searchParams), page })
        }}
      />
      { total > 0 &&
        <span>
          Showing patients {(currentPage - 1) * pageSize + 1}-{Math.min(currentPage * pageSize, total)} of <b>{total}</b>
        </span>
      }
    </span>
    <Table
      loading={isLoading}
      pagination={false}
      onRow={(record) => ({
        onClick: () => {
          if(selectedDatasets.includes(record.id)) {
            dispatch(setSelectedDatasets(selectedDatasets.filter(i => i !== record.id)));
          } else {
            dispatch(setSelectedDatasets([...selectedDatasets, record.id]));
          }
        }
      })}
      rowSelection={{
        selectedRowKeys: selectedDatasets,
        onChange: (selectedKeys) => dispatch(setSelectedDatasets(selectedKeys))
      }}
      onChange={(pagination, filters, sorter, { action }) => {
        if(action !== 'sort') return;
        const params = paramsObject(searchParams);
        if(!sorter.order) {
          delete params.orderBy;
          delete params.sortOrder
        } else {
          params.orderBy = sorter.field;
          params.sortOrder = sorter.order;
        }
        setSearchParams(params);
      }}
      columns={columns}
      dataSource={data}
    />
  </PatientsStyled>);
}

const mapState = (state, props) => ({
  selectedDatasets: selectCheckedDatasets(state)
});

export default connect(mapState, null)(Patients);
