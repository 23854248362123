import React, { useState, useEffect, useCallback } from 'react';
import { Popover, FloatButton, Select, Input, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import * as html2canvas from 'html2canvas';
import { useSendFeedbackRequest } from '../../redux/upload/upload.api';
import FeedbackDialogStyled from './FeedbackDialog.styled';

const FeedbackButton = (props) => {
  const [ open, setOpen ] = useState(false);

  const onDone = useCallback(() => setOpen(false), []);

  return (
    <Popover trigger='click'
      title="Leave feedback"
      open = {open}
      onOpenChange={setOpen}
      content={<FeedbackDialog rootRef={props.rootRef} onDone={onDone}/>}
    >
      <FloatButton icon={<FontAwesomeIcon icon={faCommentDots}/>}/>
    </Popover>
  )
}

const FeedbackDialog = ({ rootRef, onDone, ...props }) => {
  const [ category, setCategory ] = useState(undefined);
  const [ text, setText ] = useState(undefined);
  const [ isSending, setIsSending ] = useState(false);

  const [ sendFeedback, { isLoading, isSuccess, isError } ] = useSendFeedbackRequest();

  useEffect(() => {
    return () => {
      setCategory(undefined)
      setText(undefined)
    }
  }, []);

  useEffect(() => {
    if(!isLoading) setIsSending(false);
  }, [isLoading]);

  useEffect(() => {
    if(isSuccess) {
      message.success("Thanks for your feedback")
      setText(undefined)
      setCategory(undefined)
      onDone()
    } else if(isError) {
      message.error("Unable to send feedback. Please, try again later")
    }
  }, [isSuccess, isError, onDone])

  return <FeedbackDialogStyled>
    Category
    <Select
      options={[
        { label: 'Bug', value: 'bug' },
        { label: 'Feature request', value: 'feature' },
        { label: 'Enhancement suggestion', value: 'suggestion' }
      ]}
      placeholder="Select feedback category"
      value={category}
      onChange={setCategory}
    />
    Your comment
    <Input.TextArea
      rows={4}
      placeholder="Please, enter your feedback"
      showCount
      maxLength={1000}
      value={text}
      onChange={e => setText(e.target.value)}
    />
    <Button
      type='primary'
      disabled={!(category && text?.length)}
      loading={isSending}
      onClick={() => {
        if(isSending) return

        if(!rootRef) {
          // show alert
          return
        }

        setIsSending(true)
        html2canvas(rootRef).then(canvas => {
          canvas.toBlob(blob => sendFeedback({
            path: window.location.pathname,
            category: category,
            message: text,
            image: blob
          }));
        })
      }}
    >
      Send
    </Button>
  </FeedbackDialogStyled>
}

export { FeedbackButton };
export default FeedbackDialog;
