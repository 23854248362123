export const CONN_GROUP_MODE = Object.freeze({
  POINT_CLOUD: 0,
  HEAT_MAP: 1
});

export const CONN_GROUP_MODE_OPTIONS = [
  { label: 'Point cloud', value: CONN_GROUP_MODE.POINT_CLOUD },
  { label: 'Heat map',    value: CONN_GROUP_MODE.HEAT_MAP }
];

export const TRACTS_SLICE_MODE = Object.freeze({
  DISABLED:    0,
  PRECISE:     1,
  APPROXIMATE: 2,
  VOXELIZED:   3,
  CLUSTERED:   4
});

export const ANALYSIS_MODE = Object.freeze({
  SINGLE: 'i',
  GROUP: 'g'
});

export const TRACTS_COLOR_MODE = Object.freeze({
  NORMALS: 0,
  COLORMAP: 1
});
