import styled from 'styled-components';

const ViewportsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, 1fr);

  width: 100%;
  height: 100%;
/*
  > div {
    border-left: 1px solid gray;
  }

  > div:nth-child(-n+2) {
    border-bottom: 1px solid gray;
  }
*/
`;

const ViewerOverlay = styled.div`
  position: fixed;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;

  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #9ccef9;

  width: 75%;
  height: 100%;
  top: 32px;
  left: 25%;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  border-left: 1px solid grey;

  .ant-progress {
    width: 33%;
    margin-right: 18px;
  }

`;

const SettingsHeader = styled.span`
  padding: 0;
  padding-left: 9px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  width: calc(25% + 1px);
  border-right: 1px solid gray;

  .ant-typography {
    color: white;
    padding-left: 10px;
  }

  .ant-typography + .ant-typography {
    flex: 1;
  }

  .ant-upload .ant-btn {
    vertical-align: 0px;
  }
`;

const ViewerStyled = styled.div`
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-areas:
    "header header"
    "sider content";
  grid-template-rows: 32px 1fr;
  grid-template-columns: 25% 75%;

  > .header {
    grid-area: header;
    display: flex;
    padding: 0;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid gray;
    background-color: rgba(0, 0, 0, 0.85);

    .ant-btn[disabled] {
      color: gray;
      vertical-align: -1px;
    }
  }

  > .sider {
    grid-area: sider;
    padding: 0;
    padding-left: 8px;
    color: #9ccef9;
    background-color: rgba(0, 0, 0, 0.85);
  }

  > .content {
    grid-area: content;
  }
`;

export { ViewerOverlay, ViewportsStyled, SettingsHeader };
export default ViewerStyled;
