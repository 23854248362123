import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { ImageVolume } from '@cornerstonejs/core';
import AXIS from '../../constants/axes';

class NiftiImageVolume extends ImageVolume {
  constructor(props) {
    super(props);
    this.csRenderable = true;
    this.isReady = true;
  }

  createSlice(axis, index /*, targetBuffer */) {
    const xs = this.dimensions[0];
    const ys = this.dimensions[1];

    const getVoxel = (x, y, z) => {
      return this.scalarData[x + xs * y + xs * ys * z];
    }

    let rowIdx, colIdx, getPixel;
    switch(axis) {
      case AXIS.Z:
        rowIdx = AXIS.X;
        colIdx = AXIS.Y;
        getPixel = (r, c) => getVoxel(r, c, index);
        break;
      case AXIS.Y:
        rowIdx = AXIS.X;
        colIdx = AXIS.Z;
        getPixel = (r, c) => getVoxel(r, index, c);
        break;
      case AXIS.X:
        rowIdx = AXIS.Y;
        colIdx = AXIS.Z;
        getPixel = (r, c) => getVoxel(index, r, c);
        break;
      default:
        throw new Error(`Invalid axis: ${axis}`)
    }

    const rows = this.dimensions[rowIdx];
    const cols = this.dimensions[colIdx];
    const slice = new this.metadata.typeConstructor(rows * cols);
    // slice.set(this.scalarData.slice())
    // const slice = this.scalarData.slice(xs * ys * index, xs * ys * index + rows * cols);

    let i = 0;
    for(let c = 0; c < cols; ++c) {
      for(let r = 0; r < rows; ++r) {
        slice[i++] = getPixel(r, c);
      }
    }

    return { slice, rows, cols };
  }

  createVTKSlice(axis, index/*, targetBuffer */) {
    const { slice, rows, cols } = this.createSlice(axis, index);

    const id = vtkImageData.newInstance({
      spacing: this.spacing,
      extent: [0, rows - 1, 0, cols - 1, 0, 0],
      origin: [0, 0, 0],
      direction: [1, 0, 0, 0, 1, 0, 0, 0, 1]
    });

    const da = vtkDataArray.newInstance({
      numberOfComponents: 1,
      values: slice,
    });
    da.setName('scalars');

    id.getPointData().setScalars(da);

    return id;
  }

  sliceDimensions(axis) {
    const { dimensions: dims, spacing } = this;
    switch(axis) {
      case AXIS.Z: return [ dims[0] * spacing[0], dims[1] * spacing[1] ];
      case AXIS.Y: return [ dims[0] * spacing[0], dims[2] * spacing[2] ];
      case AXIS.X: return [ dims[2] * spacing[2], dims[1] * spacing[1] ];
      default: throw new Error(`Invalid axis: ${axis}`)
    }
  }
}

// creates nifti volume from parsed nifti file
const createNiftiVolume = (volumeId, { header, metadata, data }) => {
  const [ xs, ys, zs ] = [header.dims[1], header.dims[2], header.dims[3]];
  const [ spx, spy, spz ] = [header.pixDims[1], header.pixDims[2], header.pixDims[3]];

  console.log("NIFTI", volumeId, header)

  return new NiftiImageVolume({
    volumeId,
    imageIds: [],
    dimensions: [xs, ys, zs],
    spacing: [spx, spy, spz],
    origin: [-xs * spx / 2, -ys * spy / 2, -zs * spz / 2],
    direction: [1, 0, 0, 0, 1, 0, 0, 0, 1],
    metadata: {
      ...metadata,
      FrameOfReferenceUID: 'default',
      Modality: metadata.isFloatData ? 'Value' : 'CT',
      PhotometricInterpretation: "MONOCHROME2",
      PixelRepresentation: 0,
      /*voiLut: [{ windowCenter: 127, windowWidth: 255 }]*/
    },
    scalarData: data,
    sizeInBytes: xs * ys * zs * metadata.typeConstructor.BYTES_PER_ELEMENT
  });
}

export { createNiftiVolume };
export default NiftiImageVolume;
