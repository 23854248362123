import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as cornerstone from '@cornerstonejs/core';
import { Button, Checkbox, Divider, InputNumber, Collapse, Slider, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faGear, faCheck } from '@fortawesome/free-solid-svg-icons';
import QAViewport from '../Viewport/QAViewport';
import QASlicesPlotStyled, { QASlicesDisplay, UnitsSelect } from './QASlicesPlot.styled';
import {
  selectViewportGridRows,
  selectViewportGridCols,
  selectViewportGridAxis,
  selectReferenceSlice,
  selectSliceStep,
  selectSliceStepAuto,
  selectBackgroundThreshold,
  selectBackgroundMin,
  selectBackgroundMax,
  selectViewports,
  selectIsQAReady
} from '../../../../redux/qa_viewer/qa_viewer.selectors';
import {
  setVolume, setSegmentation, setError, setConfig,
  createScene, clearScene, saveScene
} from '../../../../redux/qa_viewer/qa_viewer.slice';
import { AXIS } from '../../../../constants';

const { cache, volumeLoader } = cornerstone;

const UNITS = Object.freeze({
  MM: 1,
  VOXELS: 2
});

const getDisplayedSlice = (i, d, s, a, u) => {
  return u === UNITS.MM ? (i  - d[a] / 2) * s[a] : i;
}

const generateMask = (volume, segmentationId, th) => {
  const { scalarData } = volume;
  if(scalarData) {
    const { scalarData: sourceData } = cache.getVolume(segmentationId);
    const t = volume.metadata.maxPixelValue * th;
    for(let i = 0; i < scalarData.length; ++i) {
      scalarData[i] = sourceData[i] >= t ? 1 : 0
    }
  }
}

const QASlicesPlot = ({ volumeId, segmentationId, maskThreshold, ...props }) => {
  const dispatch = useDispatch();
  const { viewports, isReady, rows, cols, step, autoStep } = props;

  const [ _cols, setCols ] = useState();
  const [ _rows, setRows ] = useState();
  const [ _step, setStep ] = useState();
  const [ _stepAuto, setStepAuto ] = useState(true);
  const [ _axis, setAxis ] = useState(props.axis);
  const [ _dispRefSlice, setDispRefSlice ] = useState(undefined);
  const [ _refSlice, setRefSlice ] = useState(undefined);
  const [ _bkgTh, setBkgTh ] = useState();
  const [ _maskTh, setMaskTh] = useState();
  const [ dimensions, setDimensions ] = useState();
  const [ spacing, setSpacing ] = useState();
  const [ units, setUnits ] = useState(UNITS.MM);
  const [ dirty, setDirty ] = useState(false);
  const [ maskDirty, setMaskDirty ] = useState(false);
  const [ showMore, setShowMore ] = useState(false);

  useEffect(() => {
    if(!volumeId || !segmentationId) return;

    volumeLoader.createAndCacheVolume(volumeId, {}).then(() => {
      dispatch(setVolume(volumeId));
      const vol = cache.getVolume(volumeId);
      if(vol) {
        setDimensions(vol.dimensions);
        setSpacing(vol.spacing);
        setRefSlice(undefined);
      }
    }).catch(error => {
      dispatch(setError(error));
    });

    volumeLoader.createAndCacheVolume(segmentationId, {}).then(volume => {
      const segId = `${segmentationId}#seg#${maskThreshold}`;
      if(cache.getVolume(segId)) return { volumeId : segId };
      return volumeLoader.createAndCacheDerivedVolume(segmentationId, {
        volumeId: segId,
        targetBuffer: { type: 'Uint8Array' }
      });
    }).then(volume => {
      generateMask(volume, segmentationId, maskThreshold);
      dispatch(setSegmentation(volume.volumeId));
    }).catch(error => {
      dispatch(setError(error));
    });

    return () => {
      dispatch(clearScene());
    }
  }, [volumeId, segmentationId, maskThreshold, dispatch]);

  useEffect(() => {
    if(_stepAuto) setStep(step);
  }, [_stepAuto, step]);

  useEffect(() => {
    if(isReady) dispatch(createScene());
  }, [dispatch, isReady]);

  const sliceLimits = useMemo(() => {
    if(!spacing || !dimensions) return {};
    switch(units) {
    case UNITS.MM:
      return {
        min: -dimensions[_axis] / 2 * spacing[_axis],
        max: dimensions[_axis] / 2 * spacing[_axis],
        step: spacing[_axis]
      };
    case UNITS.VOXELS:
      return {
        min: 0,
        max: dimensions[_axis] - 1,
        step: 1
      };
    default:
      return {};
    }
  }, [units, spacing, dimensions, _axis]);

  useEffect(() => {
    if(_refSlice === undefined && props.refSlice !== undefined && spacing && dimensions) {
      setDispRefSlice(getDisplayedSlice(props.refSlice, dimensions, spacing, _axis, units));
      setRefSlice(props.refSlice);
    }
  }, [_refSlice, props.refSlice, units, spacing, dimensions, _axis]);

  return <QASlicesPlotStyled>
    <span>
      <FontAwesomeIcon icon={faGear}/>
      Settings
      <Divider type='vertical'/>
      Rows
      <InputNumber defaultValue={rows} min={1} max={5}
        value={_rows}
        onChange={v => { setRows(v); setDirty(true); }}
      />
      Columns
      <InputNumber defaultValue={cols} min={1} max={10}
        value={_cols}
        onChange={v => { setCols(v); setDirty(true); }}
      />
      Step
      <InputNumber defaultValue={step} min={1} max={20}
        disabled={_stepAuto}
        value={_step}
        onChange={v => { setStep(v); setDirty(true); }}
      />
      <Checkbox defaultChecked={autoStep} checked={_stepAuto}
        onChange={e => { setStepAuto(e.target.checked); setDirty(true); }}
      >
        Auto
      </Checkbox>

      <Collapse ghost expandIconPosition={'end'}
        onChange={v => setShowMore(v.length > 0)}
      >
        <Collapse.Panel header={"More"}/>
      </Collapse>

      <Button
        icon={<FontAwesomeIcon icon={faCheck}/>}
        disabled={!dirty && !maskDirty}
        onClick={() => {
          const updateConfig = () => {
            setDirty(false);
            dispatch(setConfig(
              _rows || rows,
              _cols || cols,
              _step,
              _stepAuto,
              _axis,
              _refSlice,
              _bkgTh || props.bkgThreshold
            ));
          }

          if(dirty && !maskDirty) {
            updateConfig();
          } else if(maskDirty) {
            setMaskDirty(false);
            const segId = `${segmentationId}#seg#${_maskTh}`;
            if(cache.getVolume(segId)) {
              dispatch(setSegmentation(segId));
            } else {
              volumeLoader.createAndCacheDerivedVolume(segmentationId, {
                volumeId: segId,
                targetBuffer: { type: 'Uint8Array' }
              }).then(volume => {
                generateMask(volume, segmentationId, _maskTh);
                dispatch(setSegmentation(volume.volumeId));
                updateConfig();
              }).catch(error => {
                dispatch(setError(error));
              });
            }
          }
        }}
      >
        Apply changes
      </Button>
      <Button
        icon={<FontAwesomeIcon icon={faDownload}/>}
        onClick={() => dispatch(saveScene(props.title))}
      >
        Save
      </Button>
    </span>

    { showMore &&
      <span style={{ paddingBottom: '8px' }}>
        Reference slice
        <InputNumber
          min={sliceLimits.min}
          max={sliceLimits.max}
          step={sliceLimits.step}
          value={_dispRefSlice}
          onChange={v => {
            setDispRefSlice(v);
            setRefSlice(units === UNITS.MM ? v / spacing[_axis] + dimensions[_axis] / 2 : v);
            setDirty(true);
          }}
        />
        <UnitsSelect
          value={units}
          onChange={e => {
            setUnits(e.target.value);
            setDispRefSlice(
              getDisplayedSlice(_refSlice, dimensions, spacing, _axis, e.target.value)
            );
          }}
          options={[
            { label: 'mm', value: UNITS.MM },
            { label: 'voxels', value: UNITS.VOXELS }
          ]}
        />
        Axis:
        <Select
          value={_axis}
          onChange={v => {
            setAxis(v);
            setDispRefSlice(
              getDisplayedSlice(_refSlice, dimensions, spacing, v, units)
            );
            setDirty(true);
          }}
          options={[
            { value: AXIS.Z, label: "Axial" },
            { value: AXIS.Y, label: "Coronal" },
            { value: AXIS.X, label: "Sagittal" }
          ]}
        />
        Background threshold
        <Slider min={props.bkgMin} max={props.bkgMax}
          defaultValue={props.bkgThreshold}
          value={_bkgTh}
          onChange={v => { setBkgTh(v); setDirty(true); }}
        />
        Overlay threshold
        <Slider min={0.0} max={1.0} step={0.01} defaultValue={maskThreshold}
          value={_maskTh}
          onChange={v => { setMaskTh(v); setMaskDirty(true); }}
        />
      </span>
    }

    <QASlicesDisplay rows={rows} cols={cols}>
      { viewports.map(({ id }) => <QAViewport key={id} viewportId={id}/>) }
    </QASlicesDisplay>
  </QASlicesPlotStyled>
}

const mapState = (state, props) => ({
  viewports: selectViewports(state),
  isReady: selectIsQAReady(state),
  rows: selectViewportGridRows(state),
  cols: selectViewportGridCols(state),
  step: selectSliceStep(state),
  autoStep: selectSliceStepAuto(state),
  axis: selectViewportGridAxis(state),
  refSlice: selectReferenceSlice(state),
  bkgThreshold: selectBackgroundThreshold(state),
  bkgMin: selectBackgroundMin(state),
  bkgMax: selectBackgroundMax(state)
});

export default (connect)(mapState, null)(QASlicesPlot);
