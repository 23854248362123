import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files:               undefined,
  uploadData:          [],
  ignoreUntagged:      true,
  group:               undefined,
  progress:            undefined,
  error:               undefined,
  compressionProgress: undefined,
  parsingProgress:     undefined
};

const filterPatientFiles = (state, id, predicate) => {
  const applyFilter = (item) => item.files = item.files.filter(predicate);

  if(id) applyFilter(state.uploadData[id]);
  else state.uploadData.forEach(applyFilter);

  state.uploadData = state.uploadData.filter(item => item.files.length > 0);
  if(state.uploadData.length === 0) state.files = undefined;
}

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    clearFiles: (state) => {
      state.files = undefined;
      state.uploadData = undefined;
    },
    setCompressionProgress: (state, action) => {
      state.compressionProgress = action.payload;
    },
    setUploadData: (state, action) => {
      state.uploadData = action.payload;
    },
    setPatientName: {
      reducer: (state, action) => {
        const { id, name } = action.payload;
        state.uploadData[id].patient.name = name;
      },
      prepare: (id, name) => ({ payload: { id, name }})
    },
    setPatientAge: {
      reducer: (state, action) => {
        const { id, age } = action.payload;
        state.uploadData[id].patient.age = age;
      },
      prepare: (id, age) => ({ payload: { id, age }})
    },
    setPatientSex: {
      reducer: (state, action) => {
        const { id, sex } = action.payload;
        state.uploadData[id].patient.sex = sex;
      },
      prepare: (id, sex) => ({ payload: { id, sex }})
    },
    setPatientSeriesTag: {
      reducer: (state, action) => {
        const { id, series, tag } = action.payload;
        state.uploadData[id].files = state.uploadData[id].files.map(i => {
          return i.key === series
            ? { ...i, tag }
            : (i.tag === tag ? { ...i, tag: undefined } : i)
        });
      },
      prepare: (id, series, tag) => ({ payload: { id, series, tag }})
    },
    removePatient: (state, action) => {
      state.uploadData.splice(action.payload, 1);
      if(state.uploadData.length === 0) state.files = undefined;
    },
    removePatientSeries: {
      reducer: (state, action) => {
        const { id, series } = action.payload;
        filterPatientFiles(state, id, s => s.key !== series);
      },
      prepare: (id, series) => ({ payload: { id, series }})
    },
    removeUntaggedSerieses: (state) => {
      filterPatientFiles(state, undefined, s => s.tag !== undefined);
    },
    setIgnoreUntaggedSerieses: (state, action) => {
      state.ignoreUntagged = action.payload;
    },
    setParsingProgress: (state, action) => {
      state.parsingProgress = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    clear: () => initialState
  }
});

export const {
  setFiles, clearFiles,
  setUploadData, removeFileTreeNode, removeUntaggedSerieses, setParsingProgress,
  setPatientName, setPatientAge, setPatientSex, setPatientSeriesTag,
  removePatient, removePatientSeries, setIgnoreUntaggedSerieses,
  setCompressionProgress, setProgress, setError, setGroup, clear
} = uploadSlice.actions;

export default uploadSlice.reducer;
