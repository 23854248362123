import styled from 'styled-components';

const DatasetsStyled = styled.div`
  h4, .ant-alert {
    margin-bottom: 8px;
  }

  .ant-btn > svg {
    margin-right: 8px;
  }

  .ant-tabs-extra-content {
    display: flex;

    > * {
      margin-left: 8px;
    }
  }
`;

export default DatasetsStyled;
