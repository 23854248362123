import styled from 'styled-components';

const UploadFormStyled = styled.div`
  > .ant-input-group-wrapper {
    max-width: 55%;
    margin-bottom: 8px;
  }

  .upload-header {
    display: grid;
    grid-template-columns: 45% auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 4px;
    grid-row-gap: 4px;

    align-items: center;
    max-width: 55%;

    .ant-typography {
      grid-row: 1;
    }

    .ant-checkbox-wrapper {
      grid-column: 1;
      margin: 5px 0;
    }

    .ant-select {
      grid-column: 2 / 4;
    }

    .ant-btn {
      grid-row: 1;
    }
  }

  .upload-form {
    display: flex;
    align-items: center;

    .ant-upload-list-item:first-child {
      margin-top: 0;
    }

    .ant-typography {
      margin: 0 8px;
    }
  }

  .upload-filelist {
    margin-top: 6px;
    max-width: 55%;

    .ant-tree-treenode, .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }

  .upload-dropzone {
    border: 1px dashed black;
    border-radius: 8px;
    background: white;
    max-width: 55%;
    height: 250px;

    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 16px 32px;
      padding-top: 56px;

      > svg {
        font-size: 24px;
        margin-bottom: 8px;
      }

      > p {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    > div:last-child {
      position: relative;
      text-align: center;
      top: -135px;

      b {
        text-decoration: underline;
        color: #1890ff;
        margin: 0 0.3em;
        cursor: pointer;
      }
      p {
        font-size: 11px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }
`;

export default UploadFormStyled;
