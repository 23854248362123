import * as cornerstoneTools from '@cornerstonejs/tools';
import { current } from '@reduxjs/toolkit'
import MouseWheelZoomTool from '../../modules/cornerstone/MouseWheelZoomTool';
import DataProbeTool from '../../modules/cornerstone/DataProbeTool';
import TractsFilterTool from '../../modules/cornerstone/TractsFilterTool';
import { AXIS, DATA_TYPE, TRACTS_SLICE_MODE } from '../../constants';
import { RENDERING_ENGINE_ID } from './viewer.slice';
import {
  selectValidThicknessVolumeId,
  selectDataLayers
} from './viewer.selectors';
import { renderAllViewports, rebuildScene, getLayerActors, getLayerVolumes } from './sceneManager';
import { setFilterTractsTarget } from './viewer.slice';
import { cache, eventTarget } from '@cornerstonejs/core';
import { store } from '../store.js';

const { MouseBindings, Events, SegmentationRepresentations } = cornerstoneTools.Enums;
const {
  StackScrollMouseWheelTool,
  PanTool,
  ZoomTool,
  TrackballRotateTool,
  ToolGroupManager,
  SegmentationDisplayTool,
  segmentation
} = cornerstoneTools;

const TOOL_GROUP_ID = Object.freeze({
  SLICE: 'SLICE_TOOL_GROUP',
  VOLUME: 'VOLUME_TOOL_GROUP'
});

// const segmentationId = `mgz:/results/e3f160c4-aab2-4e5d-ac38-58c12589d232/freesurfer/mri/aseg.mgz`;
// const tractsId = 'trk:/results/e3f160c4-aab2-4e5d-ac38-58c12589d232/dsi/tracts.trk'

const setActiveTool = (state, toolGroup, mouseButton, toolName) => {
  if(!toolGroup) return;

  const prevTool = state.activeTools[mouseButton];
  if(prevTool === toolName) return;

  state.activeTools[mouseButton] = toolName;

  if(prevTool) {
    if(prevTool === TractsFilterTool.toolName) {
      toolGroup.setToolEnabled(prevTool);
    } else {
      toolGroup.setToolDisabled(prevTool);
    }
  }

  toolGroup.setToolDisabled(toolName);
  toolGroup.setToolActive(toolName, { bindings: [{ mouseButton }]});

  const filterTractsTrigger = (e) => {
    store.dispatch(setFilterTractsTarget(e.detail.annotation))
  }

  if(toolName === TractsFilterTool.toolName) {
    eventTarget.addEventListener(Events.ANNOTATION_COMPLETED, filterTractsTrigger);
    // eventTarget.addEventListener(Events.ANNOTATION_MODIFIED, filterTractsTrigger);
    eventTarget.addEventListener(Events.ANNOTATION_MODIFIED, (e) => {
      console.log("MODIFIED", e)
    });
  } else if(prevTool === TractsFilterTool.toolName) {
    eventTarget.removeEventListener(Events.ANNOTATION_COMPLETED, filterTractsTrigger);
    eventTarget.removeEventListener(Events.ANNOTATION_MODIFIED, filterTractsTrigger);
  }
}

const setProbeToolTargetId = (state, targetId) => {
  state.probeTargetId = targetId;
  state.sliceTools.setToolConfiguration(DataProbeTool.toolName, { targetId });
}

const updateToolsState = (state) => {
  const layers = selectDataLayers({ viewer: state });
  const activeProbe = Object.entries(state.activeTools).find(
    ([_, value]) => value === DataProbeTool.toolName
  );
  if(layers.length > 0 && !layers.find(l => l.volumeId === state.probeTargetId)) {
    state.probeTargetId = layers[0].volumeId;
  } else if(layers.length === 0 && activeProbe) {
    state.probeTargetId = undefined;
  }
}

const createSegmentation = async (state, volumeId) => {
  // Currenlty segmentations cannot be displayed when polydata (e.g. tractography)
  // is presented in a viewport: app crashes when renderOutline is enabled (vtk bug)
  // or there some geometry clipping problems when renderOutline is disabled.
  // When (or if) it will be fixed segmentation display must be implemented
  // via Cornerstone

  segmentation.addSegmentations([{
    segmentationId: volumeId,
    representation: {
      type: SegmentationRepresentations.Labelmap,
      data: { volumeId }
    }
  }]);

  const { metadata } = cache.getVolume(volumeId);
  const maxIndex = metadata.lookupTableAnnotations.reduce((a, i) => i.index > a ? i.index : a, 0);
  const lut = [];
  for(let i = 0; i <= maxIndex; ++i) lut.push([0, 0, 0, 0]);
  metadata.lookupTableAnnotations.forEach(a => {
    if(a.index !== 0) lut[a.index] = [...a.color, 255]
  });

  // TODO: add support for multiple color luts when more than one
  // segmentation are rendered
  segmentation.config.color.addColorLUT(lut, 0);

/*
  console.log(metadata);
      metadata.lookupTableAnnotations.forEach((a, i) => {
        console.log("SETTING FOR", a.index)
        cornerstoneTools.segmentation.config.color.setColorForSegmentIndex(TOOL_GROUP_ID.SLICE, z[0], a.index, a.color)
      });
*/
}

const removeAllSegmentations = () => {
  Object.values(TOOL_GROUP_ID).forEach(toolGroupId => {
    const reprs = segmentation.state.getSegmentationRepresentations(toolGroupId);
    const uids = reprs?.map(({ segmentationRepresentationUID: uid }) => uid);
    if(uids) {
      segmentation.removeSegmentationsFromToolGroup(toolGroupId, uids, false);
    }
  });
}

const toolsReducers = {
  initTools: (state) => {
    const sliceTools = ToolGroupManager.createToolGroup(TOOL_GROUP_ID.SLICE);
    sliceTools.addTool(StackScrollMouseWheelTool.toolName);
    sliceTools.addTool(PanTool.toolName);
    sliceTools.addTool(ZoomTool.toolName);
    sliceTools.addTool(DataProbeTool.toolName, {});
    // sliceTools.addTool(RectangleROITool.toolName);
    // sliceTools.addTool(ReferenceCursors.toolName);
    sliceTools.addTool(TractsFilterTool.toolName);
    // sliceTools.addTool(SegmentationDisplayTool.toolName);

    // sliceTools.setToolEnabled(SegmentationDisplayTool.toolName);
    sliceTools.setToolActive(StackScrollMouseWheelTool.toolName);

    // sliceTools.setToolConfiguration(ReferenceCursors.toolName, {
        // positionSync: true,
        // disableCursor: false
      // });

      // sliceTools.setToolActive(ReferenceCursors.toolName);


    const volumeTools = ToolGroupManager.createToolGroup(TOOL_GROUP_ID.VOLUME);
    volumeTools.addTool(TrackballRotateTool.toolName);

    volumeTools.setToolActive(TrackballRotateTool.toolName, {
      bindings: [{
        mouseButton: MouseBindings.Primary
      }]
    });

    volumeTools.addTool(MouseWheelZoomTool.toolName);
    volumeTools.setToolActive(MouseWheelZoomTool.toolName);

    sliceTools.addTool(SegmentationDisplayTool.toolName);
    sliceTools.setToolEnabled(SegmentationDisplayTool.toolName);

    state.sliceTools = sliceTools;
    state.volumeTools = volumeTools;
/*
    const segConfig = segmentation.config.getGlobalConfig();
    segConfig.representations.LABELMAP.renderOutline = false;
    segConfig.representations.LABELMAP.fillAlpha = 0.9;
    segmentation.config.setGlobalConfig(segConfig);
*/
    state.activeTools = {
      [MouseBindings.Primary]:   undefined,
      [MouseBindings.Secondary]: PanTool.toolName,
    };
  },
  addToolsToViewport: {
    reducer: (state, action) => {
      const { viewportId, axis } = action.payload;
      if(state.sliceTools && axis !== AXIS.VOLUME) {
        state.sliceTools.addViewport(viewportId, RENDERING_ENGINE_ID);
      } else if(state.volumeTools && axis === AXIS.VOLUME) {
        state.volumeTools.addViewport(viewportId, RENDERING_ENGINE_ID);
      }
    },
    prepare: (viewportId, axis) => ({ payload: { viewportId, axis }})
  },
  initProbeTool: (state) => {
    let targetId = undefined;
    const thckId = selectValidThicknessVolumeId({ viewer: state });
    if(thckId) {
      targetId = thckId;
    } else {
      const layers = selectDataLayers({ viewer: state });
      if(layers?.length > 0) targetId = layers[0].volumeId;
    }

    if(targetId) {
      state.probeTargetId = targetId;
      state.sliceTools.setToolConfiguration(DataProbeTool.toolName, { targetId });
      setActiveTool(state, state.sliceTools, MouseBindings.Primary, DataProbeTool.toolName);
    } else {
      setActiveTool(state, state.sliceTools, MouseBindings.Primary, PanTool.toolName);
    }
  },
  setProbeToolTarget: (state, action) => {
    setProbeToolTargetId(state, action.payload);
  },
  setFilterTractsTarget: (state, action) => {
    const rect = action.payload?.data?.handles?.points;
    if(!rect) return;

    const layer = state.layers.findIndex(l => l.type === DATA_TYPE.TRACTOGRAPHY);
    if(layer < 0) return;

    getLayerVolumes(state, layer).forEach(volumeId => {
      const volume = cache.getVolume(volumeId);
      console.log(volumeId, volume);
      volume?.filter(rect);
    });

    getLayerActors(state, layer).forEach(actor => {
      actor.modified();
      actor.setSlices(state.cameraPos, true);
    });

    const { sliceMode } = state.layers[layer].options;
    if(sliceMode === TRACTS_SLICE_MODE.VOXELIZED || sliceMode === TRACTS_SLICE_MODE.DISABLED) {
      rebuildScene(state);
    } else {
      renderAllViewports(state);
    }
  },
  setMouseLeftTool: (state, action) => {
    setActiveTool(state, state.sliceTools, MouseBindings.Primary, action.payload);
  },
  setMouseRightTool: (state, action) => {
    setActiveTool(state, state.sliceTools, MouseBindings.Secondary, action.payload);
  },
  suspendActiveTool: (state, action) => {
    const tool = state.activeTools[MouseBindings.Primary];
    if(!tool) return;

    const { sliceTools } = current(state);
    sliceTools.setToolEnabled(tool);
    setTimeout(() => {
      sliceTools.setToolActive(tool, { bindings: [{ mouseButton: MouseBindings.Primary }]});
    }, 1000)
  },
  toolActivated: (state, action) => {
    const csViewport = state.renderingEngine.getViewport(state.activeViewport);
    const viewport = state.viewports.find(v => v.id === state.activeViewport);
    if(!csViewport || !viewport) return;

    switch(action.payload) {
    case 'hflip': {
      csViewport.flip({ flipHorizontal: true });
      viewport.flipHorizontal = !viewport.flipHorizontal;
      break;
    }
    case 'vflip': {
      csViewport.flip({ flipVertical: true });
      viewport.flipVertical = !viewport.flipVertical;
      break;
    }
    default:
      break;
    }
  },
  clearTools: (state) => {
    Object.values(TOOL_GROUP_ID).forEach(id => {
      ToolGroupManager.destroyToolGroup(id);
    });
    cornerstoneTools.annotation.state.removeAllAnnotations();
  }
};

export { TOOL_GROUP_ID, updateToolsState, setProbeToolTargetId };
export default toolsReducers;
