import styled from 'styled-components';
import { Layout } from 'antd';

const HeaderStyled = styled(Layout.Header)`
  height: 32px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid gray;
  margin-bottom: 8px;

  .ant-col {
    display: flex;
  }

  .ant-menu {
    background-color: unset;
    border: none;
    color: white;
    line-height: 32px;
    margin-right: auto;
  }

  .app-title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    margin-right: 16px;
    margin-left: 24px;
  }
`;

export default HeaderStyled;
