import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import { volumeLoader, cache } from '@cornerstonejs/core';
import { InputNumber, Radio, Select } from 'antd';
import {
  ResponsiveContainer, ComposedChart, ReferenceLine, XAxis, YAxis, Scatter
} from 'recharts';
import FunctionalGroupModeResultsDialogStyled from './FunctionalGroupModeResultsDialog.styled';
import {
  setError,
  setVolume,
  setOutput,
  setPoints,
  setRows,
  setCols,
  setROI,
  setFilterArray,
  setFilterValue,
  setSelectedPoint
} from '../../../../redux/fgm_viewer/fgm_viewer.slice';
import {
  selectViewportGridRows,
  selectViewportGridCols,
  selectCurrentPoint,
  selectCurrentROI,
  selectFilterArray,
  selectFilterValue
} from '../../../../redux/fgm_viewer/fgm_viewer.selectors';

const FunctionalGroupModeResultsDialog = (props) => {
  const dispatch = useDispatch();

  const {
    volumeId, resultsId,
    rows, cols, roi, selectedPoint, filterArray, filterValue,
    isVisible,
    ...modalProps
  } = props;

  const { open } = modalProps;

  const [ groupData, setGroupData ] = useState(undefined);

  const roiList = useMemo(() => {
    if(!resultsId) return undefined;
    const vol = cache.getVolume(resultsId);
    return vol?.data?.map((roi, i) => ({ label: roi.names[i], value: i }));
  }, [resultsId]);

  const onDisplay = useCallback(node => {
    if (node !== null) {
      dispatch(setOutput(node));
    }
  }, [dispatch]);

  const onMouseClick = useCallback(e => {
     const r = e.target.getBoundingClientRect();
     dispatch(setSelectedPoint(
       e.clientX - r.left,
       e.clientY - r.top,
       r.width,
       r.height
     ));
  }, [dispatch]);

  useEffect(() => {
    if(!volumeId || !open) return;
    volumeLoader.createAndCacheVolume(volumeId, {}).then(() => {
      dispatch(setVolume(volumeId));
    }).catch(error => {
      dispatch(setError(error));
    });
  }, [volumeId, open, dispatch]);

  useEffect(() => {
    if(resultsId) dispatch(setPoints(resultsId));
  }, [resultsId, dispatch])

  useEffect(() => {
    if(!selectedPoint || !resultsId) return;
    const data = cache.getVolume(resultsId)?.data;
    if(!data || selectedPoint >= data[roi].length) return;

    const res = data[roi].y.map((s, i) => ({ x: i, y: s[selectedPoint] }));
    let name = data[roi].names[selectedPoint].match(/^[^(]+/);
    if(name.length > 0) name = name[0].substring(name[0].indexOf('.') + 1);

    setGroupData({ data: res, name, numSubjects: data[roi].y.length - 1 });
  }, [selectedPoint, roi, resultsId]);

  return <FunctionalGroupModeResultsDialogStyled
    width={window.innerWidth - 32}
    footer={null}
    title="Group Results"
    centered={true}
    destroyOnClose={true}
    {...modalProps}
  >
    <div className="fgmr-settings">
      ROI
      <Select
        variant='borderless'
        options={roiList}
        value={roi}
        onChange={v => dispatch(setROI(v))}
      />

      Filter
      <span>
        <Radio.Group
          value={filterArray}
          onChange={e => dispatch(setFilterArray(e.target.value))}
        >
          <Radio value={0}> p </Radio>
          <Radio value={1}> pFDR </Radio>
        </Radio.Group>
      </span>

      <span>{`${filterArray ? "pFDR" : "p"} <`}</span>
      <InputNumber defaultValue={0.05} min={0} max={1} step={0.05}
        value={filterValue}
        onChange={v => dispatch(setFilterValue(v))}
      />

      Rows
      <InputNumber min={1} max={5}
        value={rows}
        onChange={v => dispatch(setRows(v))}
      />

      Columns
      <InputNumber min={1} max={10}
        value={cols}
        onChange={v => dispatch(setCols(v))}
      />
    </div>

    <div className="fgmr-chart">
      <canvas ref={onDisplay} onClick={onMouseClick}/>

      <ResponsiveContainer width="90%" height={120} style={{ alignSelf: "center"}}>
        <ComposedChart data={groupData?.data} margin={{ bottom: 24 }}>
          <ReferenceLine
            segment={[{ x: 0, y: 0 }, { x: groupData?.numSubjects, y: 0 }]}
            strokeDasharray="3 1"
          />
          { groupData?.data.map(({ x, y }) =>
            <ReferenceLine key={x}
              segment={[{ x, y: 0 }, { x, y }]}
              strokeDasharray="3 1"
            />)
          }
          <YAxis dataKey="y" type="number"
            label={groupData?.name}
            domain={[-1, 1]}
            tick={false}
            width={100}
          />
          <XAxis dataKey="x" type="number"
            label={"subjects"}
            domain={groupData ? ['dataMin - 1', 'dataMax + 1'] : [-1, 1]}
            tick={false}
          />
          <Scatter fill="#FF0000" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  </FunctionalGroupModeResultsDialogStyled>
}

const mapState = (state, props) => ({
  rows: selectViewportGridRows(state),
  cols: selectViewportGridCols(state),
  roi:  selectCurrentROI(state),
  filterValue: selectFilterValue(state),
  filterArray: selectFilterArray(state),
  selectedPoint: selectCurrentPoint(state)
});

export default (connect)(mapState, null)(FunctionalGroupModeResultsDialog);
