import React, { useRef, useState, useEffect } from 'react';
import { Popover, Button } from 'antd';
import LayoutConfigStyled from './LayoutConfig.styled';

const LayoutConfig = ({ maxCols, maxRows, onClose, onClick, ...props }) => {
  const ref = useRef(null);
  const [ cols, setCols ] = useState(1);
  const [ rows, setRows ] = useState(1);

  useEffect(() => {
    if(!ref.current) return;

    const onMouseOver = (e) => {
      const { left, top } = ref.current.getBoundingClientRect();
      setRows(Math.max(1, Math.ceil((e.pageY - top) / 24)));
      setCols(Math.max(1, Math.ceil((e.pageX - left) / 24)));
    };

    const element = ref.current;
    element.addEventListener("mouseover", onMouseOver);
    return () => element.removeEventListener("mouseover", onMouseOver);
  }, [ref]);

  return <LayoutConfigStyled ref={ref}
    onClick={() => {
      onClose();
      if(onClick) onClick(rows, cols);
    }}>
      { [...Array(maxRows || 3)].map((_, i) =>
        <span key={i}>
         { [...Array(maxCols || 3)].map((_, j) =>
           <div key={`c${j + i * (maxRows || 3)}`} style={{
            backgroundColor: i < rows && j < cols ? '#1890ff' : 'white'
          }}/>
         )}
        </span>
      )}
    </LayoutConfigStyled>
}

const LatyoutConfigWrapper = (props) => {
  const [ clicked, setClicked ] = useState(false);

  return <Popover trigger="click"
    destroyTooltipOnHide={true}
    onOpenChange={open => setClicked(open)}
    open={clicked}
    content={<LayoutConfig {...props} onClose={() => setClicked(false)}/>}
  >
    <Button type='link' size='small'>
      {props.icon}
    </Button>
  </Popover>
}

export default LatyoutConfigWrapper;
