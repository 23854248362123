import styled from 'styled-components';

const SignUpStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 7px;
  background-color: white;
  border: 1px #D7D7D7 solid;
  text-align: center;
  padding: 24px;
  padding-top: 12px;
  max-width: 450px;

  margin: auto;
  margin-top: 0px;
  margin-bottom: 40px;

  .ant-checkbox-wrapper {
    align-self: flex-start;
  }

  .ant-alert {
    margin-bottom: 24px;
    text-align: initial;
  }

  form {
    margin-top: 12px;
  }

  svg {
    margin-right: 5px;
  };

  img {
    max-width: 35%;
    align-self: center;
    margin-bottom: 16px;
  }
`;

export default SignUpStyled;
