import styled from 'styled-components';

const RadioDropdownStyled = styled.span`
  margin-left: 8px;

  > .ant-radio-button-wrapper {
    padding: 0 4px;
    border-radius: 8px !important;
    color: white;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    background: none;
    border: none;

    :hover {
      color: #9ccef9;
    }

    ::before {
      display: none; !important;
    }
  }

  > .ant-radio-button-wrapper-disabled {
    color: gray;
    :hover {
      color: gray;
    }
  }

  > .ant-btn {
    color: white;
    background: none;
    border: none;
    border-radius: 0;
    border-left: 1px solid gray;
    padding: 2px 4px;
    margin-left: 4px;
    height: 20px;
    font-size: 12px;

    .ant-typography {
      margin-right: 4px;
      position: relative;
      top: -2px;
      color: inherit;
      max-width: 84px;
    }

    svg {
      vertical-align: 0 !important;
    }

    :hover {
      color: #9ccef9;
    }
  }

  > .ant-btn[disabled] {
    background: none;
    color: gray;
    border-color: inherit;
  }
`;

export default RadioDropdownStyled;
