import styled from 'styled-components';
import { Modal } from 'antd';

const FunctionalGroupModeResultsDialogStyled = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    display: flex;
    svg {
      margin-right: 8px;
    }
  }

  .fgmr-settings {
    flex: 0;
    padding: 8px;
    padding-left: 24px;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 1fr;
    grid-auto-rows: min-content;
    row-gap: 8px;
    align-items: center;

    .ant-divider {
      margin: 0;
    }

    .ant-select {
      max-width: 180px;
      padding-right: 10px;

      .ant-select-selector {
        padding-left: 0;
      }
    }

    .ant-btn {
      margin-top: auto;
    }
  }

  .fgmr-chart {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 100%;
    padding: 8px;
    padding-bottom: 0;

    canvas {
      margin-bottom: 24px;
      width: min-content;
      align-self: center;
    }
  }



  .wide-box {
    grid-column: 1 / span 2;
  }
`;

export default FunctionalGroupModeResultsDialogStyled;
