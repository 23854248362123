import React, { useMemo, useState } from 'react';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Typography, Select, Radio, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeDropper,
  faArrowsUpDownLeftRight,
  faMagnifyingGlass,
  faStopwatch,
  faUpDown,
  faLeftRight,
  faBorderAll,
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import ToolBarStyled, { StatPanel } from './ToolBar.styled';
import RadioButton from './RadioButton';
import DataProbeTool from '../../../../modules/cornerstone/DataProbeTool';
import TractsFilterTool from '../../../../modules/cornerstone/TractsFilterTool';
import LayoutConfig from './LayoutConfig';
import {
  selectMouseLeftTool,
  selectMouseRightTool,
  selectDataLayers,
  selectHasTractsLayers,
  selectProbeToolTarget
} from '../../../../redux/viewer/viewer.selectors';
import {
  setMouseLeftTool,
  setProbeToolTarget,
  setViewportGrid,
  toolActivated,
} from '../../../../redux/viewer/viewer.slice';
import { DATA_TYPE } from '../../../../constants';

const { PanTool, ZoomTool, RectangleROITool } = cornerstoneTools;

const useTools = () => {
  const dispatch = useDispatch();

  const layers = useSelector(selectDataLayers, (a, b) =>
    a.length === b.length && a.every(({ volumeId }, i) => volumeId === b[i].volumeId)
  );

  const hasTracts = useSelector(selectHasTractsLayers);

  const probeTargetId = useSelector(selectProbeToolTarget);

  return useMemo(() => [
    {
      label: 'Probe',
      icon:  <FontAwesomeIcon icon={faEyeDropper}/>,
      value: DataProbeTool.toolName,
      text:  layers.find(l => l.volumeId === probeTargetId)?.name,
      disabled: layers.length === 0,
      menu: {
        items: layers.map(l => ({
          key: l.volumeId,
          label: l.name,
          icon:
            // for some reason Radio doesn't work outside of Radio.Group
            <Radio.Group value={l.volumeId === probeTargetId}>
              <Radio value={true}/>
            </Radio.Group>
        })),
        onClick: ({ key }) => {
          dispatch(setProbeToolTarget(key));
          dispatch(setMouseLeftTool(DataProbeTool.toolName));
        }
      }
    },
    {
      label: 'Pan',
      icon:  <FontAwesomeIcon icon={faArrowsUpDownLeftRight}/>,
      value: PanTool.toolName
    },
    {
      label: 'Zoom',
      icon:  <FontAwesomeIcon icon={faMagnifyingGlass}/>,
      value: ZoomTool.toolName
    },
    {
      label: 'Filter tracts',
      icon: <FontAwesomeIcon icon={faFilter}/>,
      value: TractsFilterTool.toolName,
      disabled: !hasTracts
    }
  ], [dispatch, layers, probeTargetId, hasTracts]);
}

const useStatelessTools = () => {
  const dispatch = useDispatch();

  return useMemo(() => [
    {
      label: 'Flip vertical',
      icon: <FontAwesomeIcon icon={faUpDown}/>,
      value: 'vflip'
    },
    {
      label: 'Flip horizontal',
      icon:  <FontAwesomeIcon icon={faLeftRight}/>,
      value: 'hflip'
    },
    {
      label: 'Viewports layout',
      value: 'layout',
      element: <LayoutConfig maxRows={3} maxCols={3}
        icon={<FontAwesomeIcon icon={faBorderAll}/>}
        onClick={(r, c) => dispatch(setViewportGrid(r, c))}
      />
    }
  ], [dispatch]);
}

const ToolBar = (props) => {
  const dispatch = useDispatch();
  const [ showStats, setShowStats ] = useState(false);

  const { mouseLeftTool, mouseRightTool, probeTargetId } = props;

  const toolOptions = useTools(probeTargetId);
  const statelessTools = useStatelessTools();

  return (<ToolBarStyled>
    <Typography.Text strong>
      LB:
    </Typography.Text>
    <Radio.Group buttonStyle='solid'
      defaultValue={toolOptions[0].value}
      value={mouseLeftTool}
      onChange={e => dispatch(setMouseLeftTool(e.target.value))}
    >
      { toolOptions.map(i =>
        <Tooltip
          key={i.value}
          title={i.label}
          placement={i.menu && "bottomLeft"}
          mouseEnterDelay={0.75}
        >
          <RadioButton disabled={i.disabled}
            key={i.value}
            value={i.value}
            menu={i.menu}
            text={i.text}
          >
            {i.icon}
          </RadioButton>
        </Tooltip>
      )}
    </Radio.Group>
    {
      statelessTools.map(i =>
        <Tooltip
          key={i.value}
          title={i.label}
          mouseEnterDelay={0.75}
        >
          { i.element ||
            <Button type='link' size='small'
              onClick={() => dispatch(toolActivated(i.value))}
            >
              {i.icon}
            </Button>
          }
        </Tooltip>
      )
    }
    <span>
      <Tooltip
        title={showStats ? "Hide stats" : "Show stats"}
        placement="bottomLeft"
        mouseEnterDelay={0.75}
      >
        <Button type='link' size='small'
          className={showStats && 'ant-btn-active'}
          disabled={!props.sceneInitTime}
          icon={<FontAwesomeIcon icon={faStopwatch}/>}
          onClick={() => setShowStats(!showStats)}
        />
      </Tooltip>
      { showStats && <>
        <StatPanel>
          <span> scene init </span>
          <span> { props.sceneInitTime } ms </span>
        </StatPanel>
      </>}
    </span>
    {/*
    <Select
      bordered={false}
      size={'small'}
      style={{width: '120px'}}
      options={toolOptions}
      value={mouseLeftTool}
      onChange={value => dispatch(setMouseLeftTool(value))}
    />
    */}
  </ToolBarStyled>);
};

const mapState = (state, props) => ({
  mouseLeftTool:  selectMouseLeftTool(state),
  mouseRightTool: selectMouseRightTool(state)
});

export default connect(mapState, null)(ToolBar);
