import { mriaApi, generateQuery } from '../api';

const BASH_COLORS = [
  ['[0;30m', '<span style="color:black">'],
  ['[0;31m', '<span style="color:darkred">'],
  ['[0;32m', '<span style="color:darkgreen">'],
  ['[0;33m', '<span style="color:darkgoldenrod">'],
  ['[0;34m', '<span style="color:darkblue">'],
  ['[0;35m', '<span style="color:purple">'],
  ['[0;36m', '<span style="color:darkcyan">'],
  ['[0;37m', '<span style="color:darkgray">'],
  ['[1;30m', '<span style="color:lightgray">'],
  ['[1;31m', '<span style="color:red">'],
  ['[1;32m', '<span style="color:green">'],
  ['[1;33m', '<span style="color:yellow">'],
  ['[1;34m', '<span style="color:dodgerblue">'],
  ['[1;35m', '<span style="color:magenta">'],
  ['[1;36m', '<span style="color:cyan">'],
  ['[1;37m', '<span style="color:white">'],
  ['[0m',     '</span>']
];

const formatBash = (str) => {
  let out = str.replaceAll('\u001b', '').replaceAll('\n', '<br>')
  BASH_COLORS.forEach(item => {
    out = out.replaceAll(item[0], item[1])
  });
  return out;
}

export const datasetsApi = mriaApi.injectEndpoints({
  endpoints: (build) => ({
    getDatasets: build.query({
      query: (arg) => '/datasets' + generateQuery(arg),
      transformResponse: rsp => {
        return { ...rsp, data: rsp.data?.map(i => ({ ...i, key: i.id })) };
      },
      providesTags: result => result
        ? [
            ...result.data?.map(({id}) => ({ type: 'Datasets', id })),
            { type: 'Datasets', id: 'LIST' },
          ]
        : [ { type: 'Datasets', id: 'LIST' } ],
    }),
    getDataset: build.query({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const patient = await baseQuery(`/datasets/${arg}`);
        if(patient.error) return { error: patient.error };

        const groups = await baseQuery(`/datasets/${arg}/groups`);
        if(groups.error) return { data: { error: groups.error }};

        const metadata = await baseQuery(`/datasets/${arg}/metadata`);
        if(metadata.error) return {
          data: { ...patient.data, groups: groups.data, metadataError: metadata.error }
        };

        return { data: {
          ...patient.data,
          groups: groups.data,
          metadata: metadata.data
        }};
      },
      providesTags: (res, err, id) => [{ type: "Datasets", id }]
    }),
    getDatasetLog: build.query({
      query: ({id, type}) => ({
        url: `/datasets/${id}/log/${type}`,
        responseHandler: 'text'
      }),
      transformResponse: rsp => formatBash(rsp),
      providesTags: (res, err, id) => [{ type: "Datasets", id }]
    }),
    getDatasetsCount: build.query({
      query: () => '/datasets/count',
      transformResponse: rsp => rsp?.count,
      providesTags: res => [{ type: 'Datasets', id: 'COUNT' }]
    }),
    deleteDataset: build.mutation({
      query: (id) => ({
        url: `/datasets/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (res, err, id) => [
        { type: 'Datasets', id: 'LIST' },
        { type: 'Datasets', id: 'COUNT'},
        { type: 'Datasets', id },
      ]
    }),
    deleteDatasets: build.mutation({
      query: (ids) => ({
        url: '/datasets',
        method: 'DELETE',
        body: { ids },
        headers:  { 'Content-Type': 'application/json;charset=utf-8' }
      }),
      invalidatesTags: (res, err, ids) => [
        { type: 'Datasets', id: 'LIST' },
        { type: 'Datasets', id: 'COUNT'},
        ...ids.map(id => ({ type: 'Datasets', id })),
      ]
    }),
    getGroups: build.query({
      query: (arg) => '/datasets/groups' + generateQuery(arg),
      transformResponse: rsp => {
        rsp.data.forEach(g => {
          g.tags.sort((a, b) => a > b);
          g.key = g.id;
        });
        return rsp;
      },
      providesTags: res => [{ type: 'Groups', id: 'LIST' }]
    }),
    getGroup: build.query({
      query: (id) => `/datasets/groups/${id}`,
      providesTags: (res, err, id) => [{ type: 'Groups', id }]
    }),
    getNextGroupId: build.query({
      query: () => '/datasets/groups/next_id',
      transformResponse: rsp => rsp && rsp.next_id,
      providesTags: res => [{ type: 'Groups', id: 'NEXT_ID' }]
    }),
    createGroup: build.mutation({
      query: (config) => ({
        url: '/datasets/groups',
        method: 'POST',
        body: config,
        headers:  { 'Content-Type': 'application/json;charset=utf-8' }
      }),
      invalidatesTags: [
        { type: 'Groups', id: 'LIST' }, { type: 'Groups', id: 'NEXT_ID' }
      ]
    }),
    updateGroup: build.mutation({
      query: ({ id, config }) => ({
        url: `/datasets/groups/${id}`,
        method: 'PUT',
        body: config,
        headers:  { 'Content-Type': 'application/json;charset=utf-8' }
      }),
      invalidatesTags: (res, err, {id}) => {
        return [{ type: 'Groups', id: 'LIST' }, { type: 'Groups', id }]
      }
    }),
    deleteGroup: build.mutation({
      query: (id) => ({
        url: `/datasets/groups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }]
    })
  })
});

export const {
  useGetDatasetsQuery: useDatasetsQuery,
  useGetDatasetQuery: useDatasetQuery,
  useGetDatasetsCountQuery: useDatasetsCount,
  useDeleteDatasetMutation: useDeleteDataset,
  useDeleteDatasetsMutation: useDeleteDatasets,
  useCreateGroupMutation: useCreateGroup,
  useUpdateGroupMutation: useUpdateGroup,
  useDeleteGroupMutation: useDeleteGroup,
  useGetGroupsQuery: useGroupsQuery,
  useGetGroupQuery: useGroupQuery,
  useGetNextGroupIdQuery: useNextGroupId,
  useGetDatasetLogQuery: useDatasetLog
} = datasetsApi;
