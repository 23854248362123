import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PatientsStyled = styled.div`
  .ant-table svg {
    margin-right: 8px;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
`;

export const NameLinkStyled = styled(Link)`
  display: block;
  max-width: 300px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export default PatientsStyled;
