import styled from 'styled-components';

const QAViewportStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding: 10px;
    color: #9ccef9;
    text-align: center;
`;

export default QAViewportStyled;
