import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faCircleExclamation,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

export const STATUS_ICON = Object.freeze({
  ready:            <FontAwesomeIcon icon={faCircleCheck} style={{color: 'darkgreen'}}/>,
  waiting:          <FontAwesomeIcon icon={faSpinner} spin style={{color: 'gray'}}/>,
  processing:       <FontAwesomeIcon icon={faSpinner} spin style={{color: 'gray'}}/>,
  invalid:          <FontAwesomeIcon icon={faCircleXmark} style={{color: 'darkred'}}/>,
  'internal-error': <FontAwesomeIcon icon={faCircleXmark} style={{color: 'darkred'}}/>,
  warning:          <FontAwesomeIcon icon={faCircleExclamation} style={{color: 'darkgoldenrod'}}/>
});
