import { cache } from '@cornerstonejs/core';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import { BaseActor, ActorsContainer } from './BaseActors';
import VolumeSlicesActor from './VolumeSlicesActor';

const THICKNESS_DISPLAY_MODE = Object.freeze({
  MODEL: 0,
  SLICES: 1
});

const THICKNESS_REPRESENTATION_MODE = Object.freeze({
  POINTS: 0,
  WIREFRAME: 1,
  SURFACE: 2
});

class ThicknessActor extends BaseActor {
  constructor(renderingEngine, volumeId, overlayIdx, surfaceIdx) {
    super(renderingEngine, volumeId);

    const { polyData: data } = cache.getVolume(volumeId);

    this.data = data;

    this.mapper.setInputData(this.createPolyData(overlayIdx, surfaceIdx));
    this.mapper.setColorModeToMapScalars();
    this.mapper.setUseLookupTableScalarRange(true);
    this.mapper.setScalarVisibility(1);

    this.uid = `${volumeId}#thck#${overlayIdx}#${surfaceIdx}`;

    this.actor.getProperty().setLighting(true)
    this.actor.getProperty().setRepresentationToSurface()
  }

  setRGBTransferFunction(ctf) {
    this.mapper.setLookupTable(ctf);
  }

  createPolyData(overlayIdx, surfaceIdx) {
    const polyData = vtkPolyData.newInstance();
    polyData.getPoints().setData(this.data.surfaces[surfaceIdx].points, 3);
    polyData.getPointData().setScalars(this.data.overlays[overlayIdx].scalars);
    polyData.getPolys().setData(this.filterFaces(overlayIdx, surfaceIdx));
    return polyData;
  }

  filterFaces(overlayIdx, surfaceIdx) {
    const t = this.data.overlays[overlayIdx].minThreshold;
    if(t === undefined) {
      return this.data.surfaces[surfaceIdx].faces
    }

    const { faces } = this.data.surfaces[surfaceIdx]
    const scalars = this.data.overlays[overlayIdx].scalars.getData()

    const skip = (i) => {
      return scalars[faces[i + 1]] < t ||
        scalars[faces[i + 2]] < t ||
        scalars[faces[i + 3]] < t;
    }

    const filteredFaces = [];
    for(let i = 0; i < faces.length; i += 4) {
      if(skip(i)) continue;
      filteredFaces.push(faces[i], faces[i + 1], faces[i + 2], faces[i + 3])
    }

    return Uint32Array.from(filteredFaces)
  }
};

class FreesurferActor extends ActorsContainer {
  constructor(renderingEngine, volumeId) {
    const actors = [];
    const { overlays } = cache.getVolume(volumeId).polyData;
    for(let i = overlays.length - 1; i >= 0; --i) {
      actors.push(new ThicknessActor(renderingEngine, volumeId, i, 0))
    }

    super(actors)

    if(actors.length > 1) {
      const ctf = vtkColorTransferFunction.newInstance();
      ctf.applyColorMap(vtkColorMaps.getPresetByName('Grayscale'));
      ctf.setMappingRange(...this.actors[0].data.overlays[1].dataRange);

      this.actors[0].setRGBTransferFunction(ctf)
    }
  }

  setRGBTransferFunction(ctf) {
    this.actors[this.actors.length > 1 ? 1 : 0].setRGBTransferFunction(ctf);
  }

  setRepresentation(mode) {
    this.actors.forEach(({ actor }) => {
      actor.getProperty().setRepresentation(mode)
    });
  }
}

class ThicknessCombinedActor extends ActorsContainer {
  constructor(renderingEngine, volumeId, displayMode = THICKNESS_DISPLAY_MODE.SLICES) {
    super([
      new FreesurferActor(renderingEngine, volumeId),
      new VolumeSlicesActor(renderingEngine, volumeId)
    ]);

    this.setDisplayMode(displayMode);
  }

  setDisplayMode(mode) {
    this.actors.forEach((a, i) => a.setVisibility(i === mode));
    this.mode = mode;
  }

  setVisibility(on) {
    if(!on) super.setVisibility(false);
    else this.setDisplayMode(this.mode);
  }

  setRepresentation(mode) {
    this.actors[0].setRepresentation(mode);
  }
}

export { THICKNESS_DISPLAY_MODE, THICKNESS_REPRESENTATION_MODE };
export default ThicknessCombinedActor;
