import { cache } from '@cornerstonejs/core';
import { BaseActor, PolyDataActor } from './BaseActors';

class MeshActor extends PolyDataActor {
  constructor(renderingEngine, volumeId) {
    super(renderingEngine, volumeId)
    this.polyData = cache.getVolume(volumeId).polyData;
  }

  createVolumeActor() {
      const { renderingEngine, volumeId, polyData } = this;
      const actor = new BaseActor(renderingEngine, volumeId);

      actor.mapper.setInputData(polyData);
      actor.actor.getProperty().setLighting(true);
      actor.actor.getProperty().setRepresentationToSurface();
      actor.uid = volumeId + "#mesh";

      return actor;
  }

  setColor(color) {
    this.volume.actor.getProperty().setColor(color);
  }

  setTractsMode(mode) {
    // do nothing
  }
}

export default MeshActor;
