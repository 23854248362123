import { cache } from '@cornerstonejs/core';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

class SlicePlanesActor {
  constructor(volumeId) {
    const { dimensions, spacing } = cache.getVolume(volumeId);
    this.dimensions = dimensions.map((d, i) => d * spacing[i]);

    const [xs, ys, zs] = this.dimensions;
    this.planes = [
      this._createPlaneActor(zs, ys, [1, 0, 0]), // x slice
      this._createPlaneActor(xs, zs, [0, 1, 0]), // y slice
      this._createPlaneActor(xs, ys, [0, 0, 1])  // z slice
    ];
  }

  delete() {
    this.planes.forEach(({ plane, actor }) => {
      actor.delete();
      plane.delete();
    });
  }

  addToViewport(viewport) {
    this.planes.forEach(({ actor }, i) => {
      viewport.addActor({ uid: `slicePlane${i}`, actor });
    })
  }

  setSlices(worldPos) {
    const { planes } = this;
    planes[0].plane.setCenter([worldPos[0], 0, 0]);
    planes[1].plane.setCenter([0, worldPos[1], 0]);
    planes[2].plane.setCenter([0, 0, worldPos[2]]);
  }

  _createPlaneActor(p1, p2, normal) {
    const planeSource = vtkPlaneSource.newInstance({
      xResolution: 1,
      yResolution: 1,
      point1: [p1, 0, 0],
      point2: [0, p2, 0],
    });

    planeSource.setNormal(normal);

    const mapper = vtkMapper.newInstance();
    mapper.setInputConnection(planeSource.getOutputPort());

    const actor = vtkActor.newInstance();
    actor.setMapper(mapper);
    actor.getProperty().setLighting(false);
    actor.getProperty().setRepresentationToWireframe();
    actor.getProperty().setColor(...normal);

    return { plane: planeSource, actor };
  }
}

export default SlicePlanesActor;
