import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { initializeViewer } from './redux/viewer/viewer.slice';
import Header from './components/Header/Header';
import Main from './components/Main/Main';

const App = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeViewer());
  }, [dispatch]);

  return (<Layout style={{ minHeight: '100vh' }}>
    <Header/>
    <Main/>
    {/*<FooterStyled/>*/}
  </Layout>);
}

export default App;
