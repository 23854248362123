import styled from 'styled-components';

const PatientStyled = styled.div`
  .ant-typography > svg {
    margin-right: 8px;
  }

  .ant-alert {
    margin: 8px 0;
  }

  .ant-collapse-header {
    padding: 8px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-content-active {
    margin-bottom: 8px;
  }

  .pi-main {
    border: 1px solid lightgray;
    border-radius: 8px;
    background: white;
    max-width: 55%;
    padding: 16px;
    padding-top: 8px;
  }

  .pi-general {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 4px 8px;

    margin-bottom: 4px;
  }

  .pi-groups {
    border: 1px solid #d9d9d9;
    padding: 4px 8px;
    margin-bottom: 4px;
  }

  .pi-serieses {
    border: 1px solid #d9d9d9;
    padding: 4px 8px;

    div {
      display: flex;
    }

    span {
      margin-left: auto;
    }
  }
`;

export default PatientStyled;
