import { createSlice } from '@reduxjs/toolkit';
import { ANALYSIS_MODE } from '../../constants';

const initialState = {
  mode:        ANALYSIS_MODE.SINGLE,
  name:        undefined,
  subjects:    [],
  parallel:    false,
  qa:          true,
  config:      {},
  tags:        undefined,
  missingTags: undefined
};

const matchRequiredTags = (state) => {
  if(!state.tags) return undefined;

  const res = Object.entries(state.config).reduce((r, [k, v]) => {
    if(!v.enabled) return r;
    const missingTags = v.requiredTags.reduce((a, t) => {
      return state.tags.find(st => st === t) ? a : [...a, t];
    }, []);
    if(missingTags.length > 0) {
      r.push({ title: state.config[k].title, tags: missingTags });
    }
    return r;
  }, []);
  return res.length > 0 ? res : undefined;
}

export const runSlice = createSlice({
  name: 'run',
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
      state.subjects = [];
      state.config = {};
      state.tags = undefined;
      state.missingTags = undefined;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setSubjects: (state, action) => {
      state.subjects = action.payload;
    },
    setParallel: (state, action) => {
      state.parallel = action.payload;
    },
    setQA: (state, action) => {
      state.qa = action.payload;
    },
    setAnalyzerEnabled: {
      reducer: (state, action) => {
        const { analyzer, on } = action.payload;
        const { type, requiredData: requiredTags, title } = analyzer;
        if(!(type in state.config)) {
          state.config[type] = { enabled: on, requiredTags, title, config: {} };
        } else {
          state.config[type].enabled = on;
        }
        state.missingTags = matchRequiredTags(state);
      },
      prepare: (analyzer, on) => ({ payload: { analyzer, on }})
    },
    setAnalyzerConfig: {
      reducer: (state, action) => {
        const { type, key, value, init } = action.payload;
        if(state.config[type]?.enabled && (!init || state.config[type].config[key] === undefined)) {
          state.config[type].config[key] = value;
        }
      },
      prepare: (type, key, value, init = false) => {
        return { payload: { type, key, value, init }}
      }
    },
    setSubjectsTags: (state, action) => {
      state.tags = action.payload;
      state.missingTags = matchRequiredTags(state);
    },
    clear: () => initialState
  }
});

export const {
  setMode, setName, setSubjects, setParallel, setQA,
  setAnalyzerEnabled, setAnalyzerConfig,
  setSubjectsTags,
  clear
} = runSlice.actions;

export default runSlice.reducer;
