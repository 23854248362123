import { Enums } from '@cornerstonejs/core';
const { OrientationAxis } = Enums;

const AXIS = Object.freeze({
  X: 0,
  Y: 1,
  Z: 2,
  VOLUME: 255
});

const toCornerstoneAxis = (axis) => {
  switch(axis) {
    case AXIS.X: return OrientationAxis.SAGITTAL;
    case AXIS.Y: return OrientationAxis.CORONAL;
    case AXIS.Z: return OrientationAxis.AXIAL;
    default: return OrientationAxis.AXIAL;
  }
}

const fromCornerstoneAxis = (axis) => {
  switch(axis) {
    case OrientationAxis.SAGITTAL: return AXIS.X;
    case OrientationAxis.CORONAL:  return AXIS.Y;
    case OrientationAxis.AXIAL:    return AXIS.Z;
    default: return undefined;
  }
}

const axisName = (axis) => {
  switch(axis) {
    case AXIS.X: return 'X';
    case AXIS.Y: return 'Y';
    case AXIS.Z: return 'Z';
    default: return undefined;
  }
}

const isValidAxis = (axis) => {
  return axis >= 0 && axis <= 2;
}

export { isValidAxis, axisName, toCornerstoneAxis, fromCornerstoneAxis };
export default AXIS;
