import React, { useMemo, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Typography, Progress, Upload, Button, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPlus,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import ViewerStyled, {
  ViewportsStyled,
  ViewerOverlay,
  SettingsHeader
} from './Viewer.styled';
import SceneSettings from './Settings/SceneSettings';
import CornerstoneViewport from './Viewport/CornerstoneViewport';
import ToolBar from './Settings/ToolBar';
import QADialog from './QA/QADialog';
import FunctionalGroupModeResultsDialog from './FunctionalGroupModeResultsDialog/FunctionalGroupModeResultsDialog';
import { DATA_TYPE } from '../../../constants';
import { THICKNESS_DISPLAY_MODE } from './Actors';
import {
  selectRenderingEngine,
  selectLoadProgress, selectIsDataLoaded,
  selectIsViewportsInitialized, selectIsViewportsCreated,
  selectValidVolumeIds,
  selectLayerLoadingOptions,
  selectProbeToolTarget,
  selectViewportsConfig,
  selectViewportGridRows,
  selectViewportGridCols,
  selectHasLayers
} from '../../../redux/viewer/viewer.selectors';
import {
  setAnalysisId,
  addLayers,
  loadProgress, loadError, loadFinished, resetLoadProgress, addLoadingRequest,
  initializeScene, updateScene,
  initTools, initProbeTool, initSegmentations, clearTools,
  clear as clearViewer,
  DEFAULT_TRACTS_SLICE_MODE,
  DEFAULT_TRACTS_QUALITY
} from '../../../redux/viewer/viewer.slice';
import {
  useAnalysis,
  useConnSources,
  useConnIcaSources,
  useConnIcaGroupSources,
  useDsiClusters,
  useGroupTractographyResults
} from '../../../redux/analyzes/analyzes.api';
import {
  useAnalysisAccessToken
} from '../../../redux/auth/auth.api';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as cornerstone from '@cornerstonejs/core';

const { volumeLoader } = cornerstone;
const {
  WEB_WORKER_PROGRESS,
  VOLUME_CACHE_VOLUME_ADDED
} = cornerstone.Enums.Events;

const STATIC_VOLUME_ID = 'nii:../../static/referenceT1.nii';

const getVolumeId = (file) => {
  let loader = undefined;
  let dataType = undefined;
  if(file.name.endsWith('.nii.gz') || file.name.endsWith('.nii')) {
    loader = 'nii';
    dataType = DATA_TYPE.FUNCTIONAL;
  } else if(file.name.endsWith('.mgz') || file.name.endsWith('.mgh')) {
    loader = 'mgz';
    dataType = DATA_TYPE.SEGMENTATION;
  } else if(file.name.endsWith('.trk') || file.name.endsWith('.trk.gz')) {
    loader = 'trk';
    dataType = DATA_TYPE.TRACTOGRAPHY;
  } else if(file.name.endsWith('.ply')) {
    loader = 'ply';
    dataType = DATA_TYPE.MESH;
  }

  const ext = file.name.split(',').pop();
  return { volumeId: `${loader}:/${file.uid}.${ext}`, dataType };
}

const getConnDir = (analysis) => {
  if(!analysis) return undefined;
  const connVersion = analysis.config
    ? analysis.config['conn_version']
    : analysis['conn_version'];

  return connVersion === 'v17' || connVersion === undefined
    ? 'ANALYSIS_01'
    : 'SBC_01';
}

// ----------------------------------------------------------------------------

const useFunctionalIndividualLayer = (analysis) => {
  const hasConnData = useMemo(() => {
    return !!analysis?.config.analyzers.find(i => i === 'conn') && analysis?.config.mode !== 'g';
  }, [analysis]);

  const hasIcaData = useMemo(() => {
    return hasConnData && !!analysis?.config.ica
  }, [hasConnData, analysis]);

  const { isSuccess: isAuthorized } = useAnalysisAccessToken(analysis?.id,
    { skip: !analysis }
  );

  const { data, error } = useConnSources(
    { id: analysis?.id, connDir: getConnDir(analysis) },
    { skip: !hasConnData || !isAuthorized }
  );

  const { data: icaData, error: icaError } = useConnIcaSources(
    analysis?.id,
    { skip: !hasIcaData || !isAuthorized }
  );

  return useMemo(() => {
    if(!hasConnData) return { ready: true };
    if(error || icaError || data?.length === 0) {
      return {
        ready: true,
        layers: [{
          name: 'Functional data',
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: STATIC_VOLUME_ID,
          default: true,
          error: 'Unable to load ROI list'
        }]
      };
    } else if(data && (!hasIcaData || icaData)) {
      const defaultROI = data.find(i => i.label.includes('DefaultMode.MPFC'));
      return {
        ready: true,
        layers: [{
          name: 'Functional data',
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: defaultROI?.value || data[0].value,
          default: true,
          options: {
            roiList: data,
            preset: 'erdc_rainbow_bright',
            interpolate: true,
            opacityRange: [ 0.3, undefined ]
          }
        },
        ...(hasIcaData ? [{
          name: "ICA data",
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: icaData[0].value,
          default: false,
          options: {
            roiList: icaData,
            preset: 'erdc_rainbow_bright',
            interpolate: true,
            opacityRange: [ 0.3, undefined ]
          }
        }] : [])]
      };
    }

    return { ready: false };
  }, [hasConnData, hasIcaData, data, error, icaData, icaError]);
}

// ----------------------------------------------------------------------------

const useTractsIndividualLayer = (analysis) => {
  const id = useMemo(() => analysis?.id, [analysis]);

  const hasDsiData = useMemo(() => {
    return !!analysis?.config.analyzers.find(i => i === 'dsi') && analysis?.config.mode !== 'g';
  }, [analysis]);

  const { isSuccess: isAuthorized } = useAnalysisAccessToken(analysis?.id,
    { skip: !analysis }
  );

  const { data } = useDsiClusters(
    analysis?.id,
    { skip: !hasDsiData || !isAuthorized }
  );

  return useMemo(() => {
    if(!hasDsiData) return { ready: true };

    const trctId = `trk:/results/${id}/dsi/tracts.trk.gz`;

    if(!data) return { ready: false }

    const layers = [{
      name: 'Tractography',
      type: DATA_TYPE.TRACTOGRAPHY,
      volumeId: trctId,
      default: true,
      children: [],
      options: {
        opacityRange: [0, 1],
        sliceMode: DEFAULT_TRACTS_SLICE_MODE,
        tractsQuality: DEFAULT_TRACTS_QUALITY,
        clusters: data.error ? undefined : data,
      },
      loadingOptions: {
        quality: DEFAULT_TRACTS_QUALITY
      }
    }];

    if(!data.error) {
      data.names.forEach(name => {
        layers[0].children.push({
          name: name,
          type: DATA_TYPE.MESH,
          volumeId: `ply:/results/${id}/dsi/models/${name}.ply`,
          hidden: true,
          visible: false
        });
      });
    }

    return { ready: true, layers };
  }, [hasDsiData, data, id]);
}

// ----------------------------------------------------------------------------

const generateIndividualLayers = (analysis, funcLayer, tractsLayers) => {
  const { id, config } = analysis;
  const dsi  = !!config.analyzers.find(i => i === 'dsi');
  const conn = !!config.analyzers.find(i => i === 'conn');
  const fs   = !!config.analyzers.find(i => i === 'freesurfer');

  const funcId = `nii:/results/${id}/conn/results/firstlevel/${getConnDir(config)}/BETA_Subject001_Condition001_Source001.nii`;
  const trctId = `trk:/results/${id}/dsi/tracts.trk.gz`;
  const thckId = `curv:/results/${id}/freesurfer/surf/`;
  const segmId = `mgz:/static/mni_icbm152/mri/aseg.mgz`;

  return [
    {
      name: 'Static',
      type: DATA_TYPE.FUNCTIONAL,
      volumeId: STATIC_VOLUME_ID,
      default: true,
      hidden: true,
      options: {
        preset: 'Grayscale',
        opacityRange: [ 15, 255 ],
        interpolate: true
      }
    },
    ...(funcLayer ? funcLayer : []),
    /*{
      name: 'Segmentation',
      type: DATA_TYPE.SEGMENTATION,
      volumeId: `mgz:/results/e3f160c4-aab2-4e5d-ac38-58c12589d232/freesurfer/mri/aseg.mgz`,
      default: true,
      options: {
        interpolate: false,
        opacityRange: [ 1, undefined ]
      }
    },*/
    ...(fs ? [{
      name: 'Segmentation',
      type: DATA_TYPE.SEGMENTATION,
      volumeId: segmId,
      default: true,
      options: {
        interpolate: false,
        opacityRange: [ 1, undefined ]
      }
    },
    /*{
      name: 'Thickness data',
      type: DATA_TYPE.FUNCTIONAL,
      volumeId: thckId,
      default: false,
      options: {
        preset: 'Cold and Hot',
        mappingRange: [ 0.01, 5 ],
        opacityRange: [ 0.01, 5 ],
        interpolate: false
      }
    },*/
    {
      name: 'Thickness data',
      type: DATA_TYPE.THICKNESS,
      volumeId: thckId,
      default: true,
      options: {
        preset: 'Cold and Hot',
        mappingRange: [ 0.01, 5 ],
        opacityRange: [ 0.01, 5 ],
        interpolate: false
      },
      loadingOptions: {
        sourceVolumeId: STATIC_VOLUME_ID,
        surfaces: [
          {
            surfUrl: `curv:/static/mni_icbm152/surf/`,
            surfSuffix: '.pial.T1',
            base: true
          }
        ],
        overlays: [
          {
            curvSuffix: '.thickness2conn_ref.curv',
            base: true,
            minThreshold: 0
          }
        ]
        //surfSuffix: '.pial.T1',
        //surfUrl: `curv:/static/mni_icbm152/surf/`,
        //curvSuffix: '.thickness2conn_ref.curv'
        /*surfUrl: `curv:/results/${id}/freesurfer/referenceT1_fs/surf/`,
        surfSuffix: `.pial.T1`,
        curvSuffix: '.thickness'*/
      }
    }] : []),
    ...(tractsLayers ? tractsLayers : []),
  ];
}

// ----------------------------------------------------------------------------

const useFunctionalGroupLayer = (analysis) => {
  const hasConnData = useMemo(() => {
    return !!analysis?.config.analyzers.find(i => i === 'conn') && analysis?.config.mode === 'g';
  }, [analysis]);

  const hasIcaData = useMemo(() => {
    return hasConnData && !!analysis?.config.ica
  }, [hasConnData, analysis]);

  const { isSuccess: isAuthorized } = useAnalysisAccessToken(analysis?.id,
    { skip: !analysis }
  );

  const funcId = useMemo(() => {
    if(!analysis) return undefined;
    const { id, config } = analysis;
    const { ids } = config;
    return `mat:/results/${id}/conn/results/secondlevel/SBC_01/${ids[0]}(1).${ids[1]}(-1)/rest/ROI.mat`
  }, [analysis]);

  const { data: icaData, error: icaError } = useConnIcaGroupSources(
    analysis?.id,
    { skip: !hasIcaData || !isAuthorized }
  );

  return useMemo(() => {
    if(!hasConnData) return { ready: true };
    if(icaError) {
      return {
        ready: true,
        layers: [{
          name: 'Functional data',
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: STATIC_VOLUME_ID,
          default: true,
          error: 'Unable to load ROI list'
        }]
      };
    } else if(!hasIcaData || icaData) {
      return {
        ready: true,
        layers: [{
          name: 'Functional',
          type: DATA_TYPE.CONN_GROUP,
          volumeId: funcId,
          default: true,
          children: [{
            name: 'Functional heat map',
            type: DATA_TYPE.FUNCTIONAL,
            volumeId: undefined,
            hasColorSettings: true,
            options: {
              preset: 'rainbow',
              opacity: 1.0,
              mappingRange: [ -45, 45 ],
              opacityRange: [ 150, undefined ],
              interpolate: true,
            }
          }],
          options: {
            preset: 'Grayscale',
            mappingRange: [0, 1],
            opacityRange: [0, 1],
            filterArray: 0,
            p: 0.05,
            roi: 0,
            mode: 0
          }
        },
        ...(hasIcaData ? [{
          name: "ICA data",
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: icaData[0].value,
          default: false,
          options: {
            roiList: icaData,
            preset: 'erdc_rainbow_bright',
            interpolate: true,
            opacityRange: [ 0.3, undefined ]
          }
        }] : [])]
      };
    }

    return { ready: false };
  }, [hasConnData, hasIcaData, icaData, icaError, funcId]);
}

// ----------------------------------------------------------------------------

const useTractsGroupLayer = (analysis) => {
  const hasGroupDsiData = useMemo(() => {
    return !!analysis?.config.analyzers.find(i => i === 'dsi') && analysis?.config.mode === 'g';
  }, [analysis]);

  const { isSuccess: isAuthorized } = useAnalysisAccessToken(analysis?.id,
    { skip: !analysis }
  );

  const { data, error } = useGroupTractographyResults(analysis?.id,
    { skip: !hasGroupDsiData || !isAuthorized }
  );

  return useMemo(() => {
    if(!analysis) return { ready: false };
    if(!hasGroupDsiData) return { ready: true };

    let errorMessage;

    if(error) {
      errorMessage = error.status === 404
        ? "Analysis result data not found"
        : "Unknown error"
    } else if(data && !Object.keys(data.files).length) {
      errorMessage = "No correlation found"
    }

    if(errorMessage) {
      return {
        ready: true,
        layers: [{
          name: 'Tractography',
          type: DATA_TYPE.FUNCTIONAL,
          volumeId: STATIC_VOLUME_ID,
          default: true,
          error: errorMessage
        }]
      };
    } else if(data) {
      const files = Object.values(data.files);
      const layers = [{
        name: 'Tractography',
        type: DATA_TYPE.TRACTOGRAPHY,
        volumeId: undefined,
        default: true,
        children: [],
        options: {
          opacityRange: [0, 1],
          groupData: data,
          colored: true,
          sliceMode: DEFAULT_TRACTS_SLICE_MODE,
          tractsQuality: DEFAULT_TRACTS_QUALITY,
        },
        loadingOptions: {
          quality: DEFAULT_TRACTS_QUALITY
        }
      }];

      for(let i = 0; i < files.length; ++i) {
        layers[0].children.push({
          name: files[i],
          type: DATA_TYPE.TRACTOGRAPHY,
          volumeId: files[i],
          hidden: true,
          visible: true,
          loadingOptions: {
            quality: DEFAULT_TRACTS_QUALITY
          },
          options: {
            sliceMode: DEFAULT_TRACTS_SLICE_MODE,
            tractsQuality: DEFAULT_TRACTS_QUALITY,
          }
        });
      }

      return { ready: true, layers };
    }

    return { ready: false };
  }, [analysis, hasGroupDsiData, data, error]);
}

// ----------------------------------------------------------------------------

const generateGroupLayers = (analysis, funcLayers, tractsLayers) => {
  const { id, config } = analysis;
  const { ids } = config

  const fs   = !!config.analyzers.find(i => i === 'freesurfer');
  const mrphId = `curv:/results/${id}/freesurfer/`;

  return [
    {
      name: 'Static',
      type: DATA_TYPE.FUNCTIONAL,
      volumeId: STATIC_VOLUME_ID,
      default: true,
      hidden: true,
      options: {
        preset: 'Grayscale',
        opacityRange: [ 15, 255 ],
        interpolate: true
      }
    },
    ...(funcLayers ? funcLayers : []),
    ...(fs ? [{
      name: 'Morphometry',
      type: DATA_TYPE.THICKNESS,
      volumeId: mrphId,
      default: true,
      options:  {
        preset: 'Cold and Hot',
        // opacityRange: [ 0.01, 5 ],
        mappingRange: [ 0.01, 1 ],
        thicknessMode: THICKNESS_DISPLAY_MODE.MODEL
        // interpolate: false
      },
      loadingOptions: {
        surfaces: [
          {
            surfUrl: `curv:/static/mni_icbm152/surf/`,
            surfSuffix: '.pial.T1',
            base: true
          }
        ],
        overlays: [
          {
            curvSuffix: `.thickness.10.${ids[0]}.normalized2conn_reference.curv`,
            base: true,
            minThreshold: 0.01
          },
          {
            curvUrl: `curv:/static/mni_icbm152/surf/`,
            curvSuffix: '.sulc',
            invert: true
          }
        ],
        // surfUrl: `curv:/static/mni_icbm152/surf/`,
        // curvUrl: `curv:/static/mni_icbm152/surf/`,
        // surfSuffix: '.pial.T1',
        // curvSuffix: //'.sulc',
        // curvSuffix: `.thickness.10.${ids[0]}.normalized2conn_reference.curv`,
        // mgzSuffix: '.thickness.10.control_vs_msa-c.normalized2mni_reference.mgh',
        // mgzUrl: 'mgz:/static/',
        sourceVolumeId: STATIC_VOLUME_ID,
        dilation: 2
      }
    }] : []),
    ...(tractsLayers ? tractsLayers : [])
  ];
}

const useVolumeLoader = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(selectIsDataLoaded);
  const hasLayers = useSelector(selectHasLayers);
  const isViewportsReady = useSelector(selectIsViewportsCreated);
  const volumeIds = useSelector(selectValidVolumeIds, shallowEqual);
  const options = useSelector(selectLayerLoadingOptions, shallowEqual);

  const [ isInitialRender, setIsInitialRender ] = useState(true);
  const [ prevVolumeIds, setPrevVolumeIds ] = useState([]);
  const [ newVolumeIds, setNewVolumeIds ] = useState([]);

  useEffect(() => {
    // if(!hasLayers) return;
    const { eventTarget } = cornerstone;
    const loadListener = ({ detail }) => {
      dispatch(loadProgress(detail));
    }
    const parseListener = ({ detail }) => {
      dispatch(loadFinished(detail.volume.volume));
    };

    eventTarget.addEventListener(WEB_WORKER_PROGRESS, loadListener);
    eventTarget.addEventListener(VOLUME_CACHE_VOLUME_ADDED, parseListener)
    return () => {
      eventTarget.removeEventListener(WEB_WORKER_PROGRESS, loadListener);
      eventTarget.removeEventListener(VOLUME_CACHE_VOLUME_ADDED, parseListener);
    }
  }, [dispatch, /*hasLayers*/]);

  useEffect(() => {
    const changedIds = volumeIds.reduce((a, volumeId, i) =>
      i > prevVolumeIds.length || volumeId !== prevVolumeIds[i]
        ? [...a, { volumeId, options: options[i] || {} }]
        : a
    , []);
    if(changedIds.length > 0) {
      setNewVolumeIds(changedIds);
      setPrevVolumeIds(volumeIds);
    }
  }, [prevVolumeIds, volumeIds, options]);

  useEffect(() => {
    if(!isViewportsReady || newVolumeIds.length === 0) return;

    const volumeIdsToLoad = newVolumeIds.filter(
      ({ volumeId }) => !cornerstone.cache.getVolumeLoadObject(volumeId)
    );

    if(volumeIdsToLoad.length === 0) {
      dispatch(updateScene());
      return;
    }
/*
    newVolumeIds.forEach(({ volumeId }) => {
      const vol = cornerstone.cache.getVolume(volumeId)
      if(vol) dispatch(loadFinished(vol, true))
    });
*/
    dispatch(resetLoadProgress());

    volumeIdsToLoad.forEach(({ volumeId: id, options }) => {
      if(id.startsWith('curv')) {
        dispatch(addLoadingRequest(id, 5));
        volumeLoader.createAndCacheVolume(id, options).catch(error => {
          dispatch(loadError(error.volumeIds || id, error));
        });
      } else {
        dispatch(addLoadingRequest(id, 1));
        volumeLoader.createAndCacheVolume(id, options).catch(error => {
          dispatch(loadError(id, error));
        });
      }
    });
  }, [dispatch, isViewportsReady, newVolumeIds]);

  useEffect(() => {
    if(!isLoaded) return;
    if(isInitialRender) {
      // delay is needed to update viewer state
      setTimeout(() => dispatch(initializeScene()), 100);
      setIsInitialRender(false);
    } else {
      updateScene();
    }
  }, [dispatch, isLoaded, isInitialRender]);
}

const useSceneInitTime = () => {
  const [ initEndTime, setInitEndTime ] = useState(undefined);

  const initStartTime = useSelector(state => state.viewer.initStartTime);
  const isViewportsReady = useSelector(selectIsViewportsInitialized);

  useEffect(() => {
    if(isViewportsReady && !initEndTime) setInitEndTime(new Date().getTime());
  }, [isViewportsReady, initEndTime]);

  return useMemo(() => {
    return initStartTime && initEndTime
      ? (initEndTime - initStartTime)
      : undefined
  }, [initStartTime, initEndTime]);
}

// ----------------------------------------------------------------------------

const Viewer = ({renderingEngine, ...props}) => {
  const { id } = useParams();
  const { width: vpWidth, height: vpHeight, ref } = useResizeDetector();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ showQAStats, setShowQAStats ] = useState(false);
  const [ showFGMResults, setShowFGMResults ] = useState(false);
  const [ fgmResultsId, setFgmResultsId ] = useState(undefined);

  const { isProbeToolReady } = props;

  const sceneInitTime = useSceneInitTime();

  const { data: analysis, isLoading, isSuccess } = useAnalysis(id);
  const { isSuccess: isAuthorized } = useAnalysisAccessToken(id, {
    refetchOnMountOrArgChange: true
  });

  const { ready: iFuncReady, layers: iFuncLayers } = useFunctionalIndividualLayer(analysis);
  const { ready: iTractsReady, layers: iTractsLayers } = useTractsIndividualLayer(analysis);
  const { ready: gFuncReady, layers: gFuncLayers } = useFunctionalGroupLayer(analysis);
  const { ready: gTractsReady, layers: gTractsLayers } = useTractsGroupLayer(analysis);

  useEffect(() => {
    dispatch(setAnalysisId(id));
    return () => {
      dispatch(clearViewer());
      cornerstone.cache.purgeCache();
    }
  }, [dispatch, id]);

  useEffect(() => {
    if(isSuccess && isAuthorized) {
      if(analysis?.config?.mode !== 'g') {
        if(iFuncReady && iTractsReady) {
          dispatch(addLayers(generateIndividualLayers(analysis, iFuncLayers, iTractsLayers)));
        }
      } else {
        if(gFuncReady && gTractsReady) {
          dispatch(addLayers(generateGroupLayers(analysis, gFuncLayers, gTractsLayers)));
        }
      }
    }
  },
  [ dispatch, analysis, isSuccess, isAuthorized,
    gFuncReady, gFuncLayers,
    gTractsReady, gTractsLayers,
    iFuncReady, iFuncLayers,
    iTractsReady, iTractsLayers
  ]);

  useEffect(() => {
    if(renderingEngine) {
      renderingEngine.resize(true, true, true);
      dispatch(updateScene());
    }
  }, [renderingEngine, dispatch, vpWidth, vpHeight]);

  // -- Tool groups -----------------------------------------------------------

  useEffect(() => {
    if(renderingEngine) {
      dispatch(initTools());
      return () => dispatch(clearTools());
    }
  }, [dispatch, renderingEngine]);

  useEffect(() => {
    if(!isProbeToolReady && props.isLoaded) dispatch(initProbeTool());
  }, [dispatch, isProbeToolReady, props.isLoaded]);

  // -- Volume loader ---------------------------------------------------------

  useVolumeLoader();

  // -- Component -------------------------------------------------------------

  return (<ViewerStyled>
    <div className='header'>
      <SettingsHeader>
        <Button type='link' style={{ padding: 0 }} onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft}/>
        </Button>
        <Typography.Text strong>
          ANALYSIS:
        </Typography.Text>
        <Typography.Text>
          { analysis?.name }
        </Typography.Text>
        <Tooltip title='Show QA stats' mouseEnterDelay={0.5}>
          <Button type='link' size='small'
            disabled={!analysis?.config?.qa}
            icon={<FontAwesomeIcon icon={faChartLine}/>}
            onClick={() => setShowQAStats(true)}
          />
        </Tooltip>
        <Upload
          itemRender={() => null}
          accept={'.nii,.nii.gz,.mgz,.mgh,.trk,.trk.gz,.ply'}
          customRequest={({ file }) => {
            const { volumeId, dataType } = getVolumeId(file);
            volumeLoader.createAndCacheVolume(volumeId, { file });
            dispatch(addLayers([{
              name: file.name,
              type: dataType,
              volumeId: volumeId,
              default: false,
              options: dataType === DATA_TYPE.MESH ? {} : {
                preset: 'jet',
                interpolate: true,
                opacityRange: [ 0.01, 256 ],
                tractsQuality: DEFAULT_TRACTS_QUALITY,
                sliceMode: DEFAULT_TRACTS_SLICE_MODE
              }
            }]));
          }}
        >
          <Tooltip title='Add layer from file' mouseEnterDelay={0.5}>
            <Button type='link' size='small'
              style={{marginRight: '-2px'}}
              icon={<FontAwesomeIcon icon={faPlus}/>}
            />
          </Tooltip>
        </Upload>
      </SettingsHeader>
      <ToolBar sceneInitTime={sceneInitTime}/>
    </div>

    <div className='sider'>
      <SceneSettings onShowFGMResults={(fgmResultsId) => {
        setShowFGMResults(true)
        setFgmResultsId(fgmResultsId);
      }}/>
    </div>

    <div className='content'>
      { (!props.isLoaded || !props.isViewportsReady) &&
        <ViewerOverlay>
          { !props.isLoaded ? 'Loading ...' : 'Initializing ...' }
          <Progress
            showInfo={false}
            percent={props.isLoaded ? 100 : props.loadProgress}
            status={props.isLoaded ? 'active' : 'normal'}
            strokeColor={'#9ccef9'}
          />
        </ViewerOverlay>
      }
      <ViewportsStyled ref={ref} rows={props.viewportRows} cols={props.viewportCols}>
        { props.viewportsConfig.map(v =>
          <CornerstoneViewport key={v.id} viewportId={v.id} rootRef={props.rootRef}/>
        )}
      </ViewportsStyled>
    </div>

    <QADialog
      open={showQAStats}
      onCancel={() => setShowQAStats(false)}
    />

    { analysis?.config?.mode === 'g' && analysis?.config?.analyzers?.find(i => i === 'conn') &&
      <FunctionalGroupModeResultsDialog
        volumeId={STATIC_VOLUME_ID}
        resultsId={fgmResultsId}
        open={showFGMResults}
        onCancel={() => setShowFGMResults(false)}
      />
    }
  </ViewerStyled>);
}

const mapState = (state, props) => ({
  renderingEngine:    selectRenderingEngine(state),
  loadProgress:       selectLoadProgress(state),
  isLoaded:           selectIsDataLoaded(state),
  isViewportsReady:   selectIsViewportsInitialized(state),
  isProbeToolReady:   !!selectProbeToolTarget(state),
  viewportsConfig:    selectViewportsConfig(state),
  viewportRows:       selectViewportGridRows(state),
  viewportCols:       selectViewportGridCols(state),
});

export { STATIC_VOLUME_ID };
export default connect(mapState, null)(Viewer);
