import { createSlice } from '@reduxjs/toolkit';
import { authApi } from "./auth.api";

const { refreshSession, login, logout } = authApi.endpoints;

const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
  uid: undefined,
  error: null
};

const getUID = (token) => JSON.parse(atob(token.split('.')[1])).uid;

const errorFromStatus = (status, additionalStatus) => {
  switch(status) {
    case 400: return "Both user name and password must be specified.";
    case 401: return "Unable to login: invalid user name or password.";
    case 403: return "Your account is not active. Please, finish registration by following the link sent to your e-mail."
    case 'FETCH_ERROR': return "Service is not available. Please, try again later.";
    default: break;
  }

  switch(additionalStatus) {
    case 503: return "Service is not available. Please, try again later.";
    default: return "Unknown error."
  }
}

const resetTokens = (state) => {
  state.accessToken = state.refreshToken = null;
  state.hasDataToken = false;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authError: (state, action) => {
      state.accessToken = state.refreshToken = null;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => login.matchFulfilled(action) || refreshSession.matchFulfilled(action),
      (state, { payload }) => {
        state.error = null;
        state.uid = getUID(payload.accessToken);
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
      }
    );
    builder.addMatcher(login.matchRejected, (state, action) => {
      state.error = Error(
        errorFromStatus(action.payload?.status, action.payload?.originalStatus)
      );
      resetTokens(state);
    });
    builder.addMatcher(refreshSession.matchRejected, (state, action) => {
      if(state.refreshToken !== undefined) {
        state.error = Error(errorFromStatus(action.payload?.status));
      }
      resetTokens(state);
    });
    builder.addMatcher(
      (action) => logout.matchFulfilled(action) || logout.matchRejected(action),
      state => {
        state.error = null;
        state.uid = undefined;
        resetTokens(state);
      }
    );
  }
});

export const { authError } = authSlice.actions;
export default authSlice.reducer;
