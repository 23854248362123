import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Select, InputNumber, FloatButton } from 'antd';
import AXIS, { axisName, toCornerstoneAxis } from '../../../../constants/axes';
import { STATIC_VOLUME_ID } from '../Viewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {
  selectRenderingEngine,
  selectIsToolsCreated,
  selectViewportAxis,
  selectViewportConfig,
  selectActiveViewport,
  selectIsBottomRightViewport
} from '../../../../redux/viewer/viewer.selectors';
import {
  setViewportCreated,
  setViewportInitialized,
  setViewportAxis,
  setActiveViewport,
  addToolsToViewport,
  suspendActiveTool,
  updateCameraPos
} from '../../../../redux/viewer/viewer.slice';
import CornerstoneViewportStyled from './CornerstoneViewport.styled';
import * as cornerstone from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { FeedbackButton } from '../../FeedbackDialog';

const { utilities } = cornerstoneTools;
const { ViewportType } = cornerstone.Enums;
const { IMAGE_RENDERED, CAMERA_MODIFIED } = cornerstone.Enums.Events;
const { MOUSE_WHEEL, MOUSE_DOWN } = cornerstoneTools.Enums.Events;

const CornerstoneViewport = ({ renderingEngine, isToolsReady, isActive, isBottomRight, config, viewportId, axis, rootRef, ...props}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [ sliceIdx, setSliceIdx ] = useState(undefined);
  const [ zoom, setZoom ] = useState(undefined);
  const [ volumeParams, setVolumeParams ] = useState(undefined);

  const { flipHorizontal, flipVertical } = config;

  const dirLeft = useMemo(() => {
    if(axis === AXIS.X) return flipHorizontal ? "A" : "P";
    return flipHorizontal ? "L" : "R";
  }, [axis, flipHorizontal]);

  const dirTop = useMemo(() => {
    if(axis === AXIS.Z) return flipVertical ? "A" : "P";
    return flipVertical ? "F" : "H";
  }, [axis, flipVertical]);

  const onCameraModified = useCallback(evt => {
    const { camera, previousCamera } = evt.detail;
    const viewport = renderingEngine.getViewport(viewportId)

    if(camera.focalPoint[axis] === previousCamera.focalPoint[axis] || !ref.current) return;

    setSliceIdx(viewport.getCurrentImageIdIndex());
    setZoom(viewport.getZoom().toFixed(2));
    dispatch(updateCameraPos(axis, Math.trunc(camera.focalPoint[axis])));
  }, [renderingEngine, dispatch, viewportId, axis]);

  useEffect(() => {
    if(!ref.current || !renderingEngine || !isToolsReady) return;

    renderingEngine.enableElement({
      viewportId,
      element: ref.current,
      type: axis === AXIS.VOLUME ? ViewportType.VOLUME_3D : ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        background: [0, 0, 0],
        orientation: toCornerstoneAxis(axis),
        parallelProjection: axis === AXIS.VOLUME ? false : true
      },
    });

    const firstRenderListener = ({ detail }) => {
      if(detail.viewportId !== viewportId) return;
      const actors = renderingEngine.getViewport(viewportId).getActors();
      if(actors.length > 0 || axis === AXIS.VOLUME) {
        dispatch(setViewportInitialized(viewportId));
        ref.current.removeEventListener(IMAGE_RENDERED, firstRenderListener);

        if(axis !== AXIS.VOLUME) {
          ref.current.addEventListener(CAMERA_MODIFIED, onCameraModified);

          const volume = cornerstone.cache.getVolume(STATIC_VOLUME_ID);
          setVolumeParams({
            spacing: volume.spacing[axis],
            dim: volume.dimensions[axis] - 1
          });

          const viewport = renderingEngine.getViewport(viewportId);
          const camera = viewport.getCamera();

          setSliceIdx(viewport.getCurrentImageIdIndex());
          setZoom(viewport.getZoom().toFixed(2));
          dispatch(updateCameraPos(axis, Math.trunc(camera.focalPoint[axis])));
        }
      }
    };

    const interactionListener = ({ detail }) => {
      dispatch(setActiveViewport(detail.viewportId));
    }

    ref.current.addEventListener(IMAGE_RENDERED, firstRenderListener);
    ref.current.addEventListener(MOUSE_WHEEL, interactionListener);
    ref.current.addEventListener(MOUSE_DOWN, interactionListener);

    dispatch(setViewportCreated(viewportId));
    dispatch(addToolsToViewport(viewportId, axis));

    return () => {
      renderingEngine.disableElement(viewportId);
    }
  }, [renderingEngine, isToolsReady, onCameraModified, viewportId, axis, dispatch]);

  return <CornerstoneViewportStyled ref={ref} isActive={isActive}>
    <div className="viewport-element"
      style={{position: 'relative', width: '100%', height: '100%'}}
    />
    <div className="overlay">
      { isBottomRight && <FeedbackButton rootRef={rootRef}/> }
      <div className="header">
        <Select
          variant='borderless'
          value={axis}
          options={[
            { label: "AXIAL",    value: AXIS.Z },
            { label: "CORONAL",  value: AXIS.Y },
            { label: "SAGITTAL", value: AXIS.X },
            { label: "3D",       value: AXIS.VOLUME }
          ]}
          onChange={value => {
            ref.current.removeEventListener(CAMERA_MODIFIED, onCameraModified);
            renderingEngine.disableElement(viewportId);
            dispatch(setViewportAxis(viewportId, value));
          }}
          onMouseDown={() => dispatch(suspendActiveTool())}
        />
        { axis !== AXIS.VOLUME &&
          <div className="dir-top">
            {dirTop}
          </div>
        }
      </div>
      { axis !== AXIS.VOLUME &&
        <div className="dir-left">
          {dirLeft}
        </div>
      }
      { (axis !== AXIS.VOLUME && sliceIdx !== undefined) &&
        <div className="footer">
          <span>
            <FontAwesomeIcon icon={faMagnifyingGlass}/>: {zoom}x
          </span>
          <span>
            {axisName(axis)}:
            <InputNumber
              variant='borderless'
              min={0}
              max={volumeParams?.dim}
              value={sliceIdx}
              onChange={val => {
                let ds = 1;
                if(axis === AXIS.X) ds *=  -1;
                if(flipHorizontal) ds *= -1;
                if(flipVertical) ds *= -1;
                const delta = (sliceIdx - val) /** volumeParams.spacing*/ * ds;
                const viewport = renderingEngine.getViewport(viewportId);
                utilities.scroll(viewport, { delta, volumeId: STATIC_VOLUME_ID });
              }}
              onStep={() => dispatch(suspendActiveTool())}
              onMouseDown={() => dispatch(suspendActiveTool())}
            />
          </span>
        </div>
      }
    </div>
  </CornerstoneViewportStyled>
}

const mapState = (state, props) => ({
  renderingEngine: selectRenderingEngine(state),
  isToolsReady:    selectIsToolsCreated(state),
  isActive:        selectActiveViewport(state) === props.viewportId,
  config:          selectViewportConfig(state, props.viewportId),
  axis:            selectViewportAxis(state, props.viewportId),
  isBottomRight:   selectIsBottomRightViewport(state, props.viewportId)
});

export default connect(mapState, null)(CornerstoneViewport);
