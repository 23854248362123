import { BaseActor, PolyDataActor } from './BaseActors'
import { cache } from '@cornerstonejs/core';

import vtkSphereMapper from '@kitware/vtk.js/Rendering/Core/SphereMapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';

import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkStringArray from '@kitware/vtk.js/Common/Core/StringArray';

class PointVolumeActor extends BaseActor {
  constructor(renderingEngine, volumeId) {
    super(renderingEngine, volumeId,
      vtkActor.newInstance(),
      vtkSphereMapper.newInstance()
    );

    const { data } = cache.getVolume(volumeId);

    this.uid = `${volumeId}#vol`;
    this.data = data;

    this.mapper.setRadius(3.5);
    this.setData();
  }

  setData(array = 0, prob = 1.0, roi = 0) {
    const data = this.data[roi];

    const pos = [];
    const vals = [];

    data.pos.forEach((p, i) => {
      if(data.p[array][i] < prob) {
        pos.push(...p);
        vals.push(data.vals[i]);
      }
    });

    const va = vtkDataArray.newInstance({
      numberOfComponents: 1,
      values: Float32Array.from(vals),
    });
    va.setName('Scalars');

    const polyData = vtkPolyData.newInstance();
    polyData.getPoints().setData(Float32Array.from(pos), 3);
    polyData.getPointData().setScalars(va);

    this.mapper.setInputData(polyData);
    this.mapper.modified();
  }
}

class PointCloudSliceActor extends BaseActor {
  constructor(renderingEngine, volumeId, axis) {
    super(renderingEngine, volumeId,
      vtkActor.newInstance(),
      vtkSphereMapper.newInstance()
    );

    const { data } = cache.getVolume(volumeId);

    this.uid  = `${volumeId}#${axis}`;
    this.axis = axis;
    this.data = data;

    this.mapper.setRadius(3.5);
    // this.mapper.setScalarModeToUsePointFieldData();
    // this.mapper.setColorByArrayName('Scalars');
    // this.mapper.setColorModeToMapScalars();
    // this.mapper.selectColorArray('Scalars');
    // this.mapper.setScaleArray('scale');
    // this.mapper.setScaleFactor(0.5);

    this.setSlices([0, 0, 0]);
  }

  setSlices(worldPos, array = 0, prob = 1.0, roi = 0) {
    const { axis } = this;
    const data = this.data[roi];

    const pos = [];
    const vals = [];
    const names = [];

    data.pos.forEach((p, i) => {
      if((p[axis] < worldPos[axis] - 3.5) || (p[axis] > worldPos[axis] + 3.5) || data.p[array][i] > prob) return;
      pos.push(...p);
      vals.push(data.vals[i]);
      names.push(data.names[i]);
    });

    const va = vtkDataArray.newInstance({
      name: 'Scalars',
      numberOfComponents: 1,
      values: Float32Array.from(vals),
    });

    const na = vtkStringArray.newInstance({
      name: 'Names',
      numberOfComponents: 1,
      values: names
    });
/*
    const sa = vtkDataArray.newInstance({
      numberOfComponents: 1,
      values: Float32Array.from(vals.map(v => Math.abs(v)))
    });
    sa.setName('scale');
*/
    const polyData = vtkPolyData.newInstance();
    polyData.getPoints().setData(Float32Array.from(pos), 3);
    polyData.getPointData().setScalars(va);
    polyData.getPointData().addArray(na);

    // NOTE: this is workaroound for current implementation of Cornerstone's
    // viewport that doesn't support both volume and polydata
    // actors in the same viewport
    const csData = {
      getDirection: () => [1, 0, 0, 0, 1, 0, 0, 0, 1],
      getExtent: () => [0, 0, 0, 0, 0, 0],
      extentToBounds: () => [0, 0, 0, 0, 0, 0],
      ...polyData
    };

    this.mapper.setInputData(csData);
    this.mapper.modified();
  }
}

class PointCloudActor extends PolyDataActor {
  constructor(renderingEngine, volumeId, p = 1.0, roi = 0) {
    super(renderingEngine, volumeId);
    this.p = p;
    this.filterArray = 0;
    this.roi = roi;
    this.worldPos = [0, 0, 0];
    this.ctf = vtkColorTransferFunction.newInstance();
    this.ctf.addRGBPointLong(-1, 0, 0, 1, 0, 1);
    this.ctf.addRGBPointLong(1, 1, 0, 0, 0, 1);
    this.ctf.setNanColor([0.5, 0.5, 0.5, 1]);
    this.ctf.setMappingRange(-1, 1);
  }

  createSliceActor(axis) {
    const { renderingEngine, volumeId, ctf } = this;
    const actor = new PointCloudSliceActor(renderingEngine, volumeId, axis);
    actor.setMapperLookupTable(ctf);
    actor.setSlices(this.worldPos, this.filterArray, this.p, this.roi);
    return actor;
  }

  createVolumeActor() {
    const { renderingEngine, volumeId, ctf } = this;
    const actor = new PointVolumeActor(renderingEngine, volumeId);
    actor.setMapperLookupTable(ctf);
    actor.setData(this.filterArray, this.p, this.roi);
    return actor;
  }

  setSlices(worldPos) {
    this.worldPos = [...worldPos];
    this.sliced.forEach(actor =>
      actor.setSlices(worldPos, this.filterArray, this.p, this.roi)
    );
  }

  setROI(roi) {
    this.roi = roi;
    this._update();
  }

  setFilterArray(index) {
    this.filterArray = index;
    this._update();
  }

  setPValue(value) {
    this.p = value;
    this._update();
  }

  _update() {
    this.volume?.setData(this.filterArray, this.p, this.roi);
    this.setSlices(this.worldPos);
  }
}

export default PointCloudActor;
