import React from 'react';
import { Table } from 'antd';
import { useUserList } from '../../../redux/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons';

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (text, record) =>
        <>
          { record.activated
            ? <FontAwesomeIcon icon={faCircleCheck} style={{color: 'darkgreen'}}/>
            : <FontAwesomeIcon icon={faCircleXmark} style={{color: 'gray'}}/>
          }
          <span style={{marginLeft: '8px'}}>{text}</span>
        </>
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Registered',
      dataIndex: 'created',
      render: (value) => new Date(value * 1000).toISOString().split('T')[0]
    },
    {
      title: 'Last active',
      dataIndex: 'last_active',
      render: (value) => {
        if(value === 0) return 'Never'
        else return new Date(value * 1000).toISOString().split('T')[0]
      }
    },
    {
      title: 'Analyzes',
      dataIndex: 'analyzes_created',
    },
    {
      title: 'Patients',
      dataIndex: 'patients_created',
    }
  ];


const UserList = (props) => {
  const { data: users, isLoading, isError } = useUserList()

  return <Table
    rowKey={ record => record.id }
    loading={isLoading}
    pagination={false}
    columns={columns}
    dataSource={users}
  />
}

export default UserList
