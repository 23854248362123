import * as cornerstone from '@cornerstonejs/core';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import { fromCornerstoneAxis } from '../../../../constants/axes';

const { cache, eventTarget } = cornerstone;
const { VOLUME_LOADED } = cornerstone.Enums.Events;

class BaseActor {
  constructor(renderingEngine, volumeId, actor = undefined, mapper = undefined) {
    this.volumeId = volumeId;
    this.renderingEngine = renderingEngine;
    this.uid = undefined; // should be implemented in subclasses
    this.ctf = null;
    this.sof = null;
    this.rgbaFilter = null;
    this.ready = false;

    if(actor && mapper) {
      this.actor = actor;
      this.mapper = mapper;
    } else if(actor || mapper) {
      throw new Error(`Both actor and mapper must be specified when initializing ${volumeId}`)
    } else {
      this.actor = vtkActor.newInstance();
      this.mapper = vtkMapper.newInstance();
      this.actor.getProperty().setLighting(false);
    }

    this.actor.setMapper(this.mapper);

    const init = (volume) => {
      this.dimensions = volume.dimensions;
      this.spacing = volume.spacing;
      this._initialize(volume); // subclass initializer
      this.ready = true;
    }

    this._onLoad = new Promise(resolve => {
      const volume = cache.getVolume(volumeId);
      if(!volume?.isReady) {
        const onLoad = ({ detail }) => {
          if(detail.volume.volumeId !== volumeId) return;
          eventTarget.removeEventListener(VOLUME_LOADED, onLoad);
          init(detail.volume);
          resolve(true);
        }
        eventTarget.addEventListener(VOLUME_LOADED, onLoad);
      } else {
        init(volume);
        resolve(true);
      }
    });
  }

  _initialize(volume) {
    // do nothing; should be implemented in subclasses
  }

  worldPosToIndex(worldPos) {
    const { dimensions: d, spacing: s } = this;
    return worldPos.map((p, i) => Math.max(Math.min(Math.round(p / s[i] + d[i] / 2), d[i]), 0));
  }

  delete() {
    this.actor.delete();
    this.mapper.delete();
    this.rgbaFilter?.delete();
  }

  onLoad(callback) {
    this._onLoad.then(() => callback(this));
  }

  modified() {
    this.rgbaFilter?.modified();
    this.actor.modified();
    this.mapper.modified();
  }

  setSlices(worldPos) {
    // do nothing
  }

  setMapperLookupTable(ctf) {
    this.ctf = ctf;
    this.mapper.setLookupTable(ctf);
  }

  setRGBTransferFunction(fn) {
    this.ctf = fn;
    if(!this.ready) return;
    if(this.rgbaFilter) this.rgbaFilter.setLookupTable(fn);
    else this.actor.getProperty().setRGBTransferFunction(0, fn);
  }

  setScalarOpacity(fn) {
    this.sof = fn;
    if(!this.ready) return;
    if(this.rgbaFilter) this.rgbaFilter.setPiecewiseFunction(fn);
    else this.actor.getProperty().setScalarOpacity(0, fn);
  }

  setVisibility(on) {
    this.actor.setVisibility(on);
  }

  setOpacity(value) {
    this.actor.getProperty().setOpacity(value);
  }

  getProperty() {
    return this.actor.getProperty();
  }

  setProperty(callback) {
    callback(this.actor.getProperty());
  }

  setMapperProperty(callback) {
    callback(this.mapper);
  }

  removeFromViewport(viewportId) {
    const { renderingEngine, uid } = this;
    const viewport = renderingEngine.getViewport(viewportId);
    if(viewport.getActor(uid)) viewport.removeActors([uid]);
  }

  addToViewport(viewportId) {
    const { renderingEngine, actor, uid, volumeId } = this;
    const viewport = renderingEngine.getViewport(viewportId);
    if(!viewport) return;
    if(!viewport.getActor(uid)) {
      const referenceId = uid.startsWith("VOLUME") ? undefined : volumeId
      viewport.addActor({ referenceId, uid, actor });
    }
  }
};

class ActorsContainer {
  constructor(actors) {
    this.actors = actors;
    this._onLoad = Promise.all(actors.map(a => a.onLoad));
  }

  delete() {
    this.actors.forEach(actor => actor.delete());
  }

  onLoad(callback) {
    this._onLoad.then(() => callback(this));
  }

  addToViewport(viewportId) {
    this.actors.forEach(actor => actor.addToViewport(viewportId));
  }

  removeFromViewport(viewportId) {
    this.actors.forEach(actor => actor.removeFromViewport(viewportId));
  }

  setVisibility(on) {
    this.actors.forEach(actor => actor.setVisibility(on));
  }

  setOpacity(value) {
    this.actors.forEach(actor => actor.setOpacity(value));
  }

  setRGBTransferFunction(fn) {
    this.actors.forEach(actor => actor.setRGBTransferFunction(fn));
  }

  setScalarOpacity(fn) {
    this.actors.forEach(actor => actor.setScalarOpacity(fn));
  }

  setProperty(fn) {
    this.actors.forEach(actor => actor.setProperty(fn));
  }

  setMapperProperty(fn) {
    this.actors.forEach(actor => actor.setMapperProperty(fn));
  }

  setSlices(worldPos, force) {
    this.actors.forEach(actor => actor.setSlices(worldPos, force));
  }

  modified() {
    this.actors.forEach(actor => actor.modified());
  }
};

class PolyDataActor {
  constructor(renderingEngine, volumeId) {
    this.renderingEngine = renderingEngine;
    this.volumeId = volumeId;
    this.sliced = [];
    this.volume = undefined;
  }

  createSliceActor(axis) {
    // must be implemented in subclasses
    throw Error("Not implemented");
  }

  createVolumeActor() {
    // must be implemented in subclasses
    throw Error("Not implemented");
  }

  delete() {
    this.sliced.forEach(actor => actor.delete());
    this.sliced = [];
    this.volume.delete();
  }

  addToSliceViewports(viewports) {
    const { renderingEngine, volumeId } = this;

    if(this.sliced.length > 0) {
      this.sliced.forEach(actor => actor.delete());
      this.sliced = [];
    }

    viewports.forEach((v, i) => {
      const axis = fromCornerstoneAxis(renderingEngine.getViewport(v.id).defaultOptions.orientation);
      const actor = this.createSliceActor(axis);
      actor.uid = `${actor.uid}#${i}`;
      actor.actor.setPosition(-2, -2, 2);
      // actor.setShowOnTop(true, v);
      // actor.setProperty(p => p.setInterpolationTypeToNearest());
      this.sliced.push(actor);
      actor.addToViewport(v.id);
    });
  }

  addToVolumeViewport(viewportId) {
    if(!this.volume) this.volume = this.createVolumeActor();
    this.volume.addToViewport(viewportId);
  }

  addToViewport(viewportId) {
    this.addToVolumeViewport(viewportId);
  }

  removeFromViewport(viewportId) {
    const { renderingEngine, volume, sliced } = this;
    const viewport = renderingEngine.getViewport(viewportId);
    if(viewport.getActor(volume.uid)) viewport.removeActors([volume.uid]);
    sliced.forEach(actor => actor.removeFromViewport(viewportId));
  }

  setVisibility(on) {
    this.volume?.setVisibility(on)
  }

  setOpacity(value) {
    // TODO: investigate why opacity doesn't work for sliced view
    this.sliced.forEach(actor => actor.setOpacity(value));
    this.volume?.setOpacity(value);
  }

  setSlices(worldPos, force) {
    this.sliced.forEach(actor => actor.setSlices(worldPos, force));
    this.volume?.setSlices(worldPos, force);
  }

  setMapperProperty(callback) {
    this.sliced?.forEach(actor => actor.setMapperProperty(callback));
    this.volume?.setMapperProperty(callback);
  }

  modified() {
    this.sliced.forEach(actor => actor.modified());
    this.volume?.modified();
  }
}

export { BaseActor, ActorsContainer, PolyDataActor };
