import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const SpinButtonStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 24px;

  .ant-btn {
    height: 8px;
    font-size: 2px;
  }

  .ant-btn[disabled] {
    color: gray;
  }
`;

const SpinButton = (props) => {
  const { onUpClick, onDownClick } = props;
  const { upDisabled, downDisabled } = props;

  // NOTE: Tooltip cannot be added here
  // since it breaks layout in this case
  return (<SpinButtonStyled>
    <Button type='link' size='small' disabled={upDisabled}
      icon={<FontAwesomeIcon icon={faAngleUp}/>}
      onClick={onUpClick}
    />
    <Button type='link' size='small' disabled={downDisabled}
      icon={<FontAwesomeIcon icon={faAngleDown}/>}
      onClick={onDownClick}
    />
  </SpinButtonStyled>);
}

export default SpinButton;
