import { createSelector } from '@reduxjs/toolkit';
import { cache } from '@cornerstonejs/core';

export const selectRenderingEngine = (state) => state.qa.renderingEngine;
export const selectViewports = (state) => state.qa.viewports;
export const selectViewportAxis = (state, id) => {
  return state.qa.viewports.find(v => v.id === id)?.axis;
}

export const selectIsQAReady = createSelector(
  [
    state => state.qa.volumeId,
    state => state.qa.segmentationId,
    state => state.qa.viewports
  ],
  (volId, segId, vps) => volId && segId && vps.every(v => v.ready)
);

export const selectViewportGridCols = (state) => state.qa.cols;
export const selectViewportGridRows = (state) => state.qa.rows;
export const selectViewportGridAxis = (state) => state.qa.axis;
export const selectSliceStep = (state) => state.qa.step;
export const selectSliceStepAuto = (state) => state.qa.autoStep;
export const selectReferenceSlice = (state) => state.qa.refSlice;
export const selectBackgroundThreshold = (state) => state.qa.bkgThreshold;
export const selectBackgroundMin = (state) => state.qa.bkgRange[0];
export const selectBackgroundMax = (state) => state.qa.bkgRange[1];

export const selectVolumeSpacing = (state, id) => {
  if(!state.qa.volumeId || !id) return undefined;
  const volume = cache.getVolume(state.qa.volumeId);
  return volume ? volume.spacing[selectViewportAxis(state, id)] : undefined;
}

export const selectVolumeSize = (state, id) => {
  if(!state.qa.volumeId || !id) return undefined;
  const volume = cache.getVolume(state.qa.volumeId);
  return volume ? volume.dimensions[selectViewportAxis(state, id)] : undefined;
}
