import { BaseTool } from '@cornerstonejs/tools';
import { getEnabledElement } from '@cornerstonejs/core';
import vtkMath from '@kitware/vtk.js/Common/Core/Math';

class MouseWheelZoomTool extends BaseTool {
  static toolName;

  constructor(
    toolProps = {},
    defaultToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch']
    }
  ) {
    super(toolProps, defaultToolProps);
  }

  mouseWheelCallback(evt) {
    const { element, wheel } = evt.detail;
    const { viewport } = getEnabledElement(element);
    const { position, focalPoint, viewPlaneNormal } = viewport.getCamera();

    const distance = vtkMath.distance2BetweenPoints(position, focalPoint);
    const zoomScale = 10 * Math.sqrt(distance) / element.clientHeight;

    const k = wheel.direction * zoomScale;
    const newPos = position.map((p, i) => p + k * viewPlaneNormal[i]);

    viewport.setCamera({ position: newPos });
    viewport.render();
  }
}

MouseWheelZoomTool.toolName = 'MouseWheelZoom';
export default MouseWheelZoomTool;
