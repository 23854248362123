import { mriaApi, generateQuery } from '../api';
import * as mat4js from 'mat-for-js';

const TRACTS_COLORS_OLD = [
  0x222034,
  0x45283c,
  0x663931,
  0x8f563b,
  0xdf7126,
  0xd9a066,
  0xeec39a,
  0xfbf236,
  0x99e550,
  0x6abe30,
  0x37946e,
  0x4b692f,
  0x524b24,
  0x323c39,
  0x3f3f74,
  0x306082,
  0x5b6ee1,
  0x639bff,
  0x5fcde4,
  0xcbdbfc,
  0x9badb7,
  0x847e87,
  0x696a6a,
  0x595652,
  0x76428a,
  0xac3232,
  0xd95763,
  0xd77bba,
  0x8f974a,
  0x8a6f30
];

const TRACTS_COLORS = [
  0xe6194b,
  0x3cb44b,
  0xffe119,
  0x4363d8,
  0xf58231,
  0x911eb4,
  0x46f0f0,
  0xf032e6,
  0xbcf60c,
  0xfabebe,
  0x008080,
  0xe6beff,
  0x9a6324,
  0xfffac8,
  0x800000,
  0xaaffc3,
  0x808000,
  0xffd8b1,
  0x000075,
  0x808080
];

const hexToRgb = (hex) => {
  let bigint = hex;// parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

export const analyzesApi = mriaApi.injectEndpoints({
  endpoints: (build) => ({
    getAnalyzes: build.query({
      query: (args) => '/analyzes' + generateQuery(args),
      transformResponse: rsp => ({ ...rsp, data: rsp.data?.map(i => ({
        ...i, config: JSON.parse(i.config), key: i.id
      }))}),
      providesTags: res => [{ type: 'Analyzes', id: 'LIST' }]
    }),
    getAnalysis: build.query({
      query: (id) => `/analyzes/${id}`,
      transformResponse: rsp => rsp && { ...rsp, config: JSON.parse(rsp.config) },
      providesTags: res => res && [{ type: 'Analyzes', id: res.id }]
    }),
    getAnalyzersConfig: build.query({
      query: () => '/analyzes/config',
      providesTags: [{ type: 'Analyzes', id: 'CONFIG' }]
    }),
    getNextId: build.query({
      query: () => `/analyzes/next_id`,
      transformResponse: rsp => rsp && rsp.next_id,
      providesTags: res => res && [{ type: 'Analyzes', id: 'NEXT_ID' }]
    }),
    getGroupTractographyResults: build.query({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const rsp = await fetch(`/results/${id}/dsi/result.json`);
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };
        const data = await rsp.json();
        data.colors = {};

        for(let t in data.files) {
          const volumeId = `trk:/results/${id}/dsi/${data.files[t]}`;
          data.files[t] = volumeId;
          data.colors[volumeId] = (t === 'pos' || t === 'inc') ? [255, 0, 0] : [0, 0, 255];
        }

        Object.values(data.names).forEach(v => v.sort());

        return { data };
      }
    }),
    getConnSources: build.query({
      queryFn: async ({ id, connDir }, api, extraOptions, baseQuery) => {
        const urlPrefix = `/results/${id}/conn/results/firstlevel/${connDir}`;

        const rsp = await fetch(urlPrefix + '/_list_sources.txt');
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        const data = await rsp.text();
        return {
          data: data?.split('\n').map(s => {
            const [ value, label ] = s.split('=').map(s => s.trim());
            return {
              value: value && `nii:${urlPrefix}/BETA_Subject001_Condition001_${value}.nii`,
              label
            };
          }).filter(i => !!i.value)
        }
      },
      providesTags: (res, _, arg) => res && [{ type: 'CONN', id: arg.id }]
    }),
    getConnIcaSources: build.query({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const urlPrefix = `/results/${id}/conn/results/firstlevel/group-ICA`;
        const rsp = await fetch(urlPrefix + '/ICA.ROIs.txt');
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        const data = await rsp.text();
        return {
          data: data?.split('\n').map(s => {
            const value = "0".repeat(Math.max(3 - s.length, 0)) + s
            return {
              value: s && `nii:${urlPrefix}/BETA_Subject001_Condition001_Measure001_Component${value}.nii`,
              label: `Component ${value}`
            };
          }).filter(i => !!i.value)
        }
      }
    }),
    getConnIcaGroupSources: build.query({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const urlPrefix = `/results/${id}`;
        const rsp = await fetch(urlPrefix + '/conn/ica_clusters.txt');
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        const data = await rsp.text();
        return {
          data: data?.split('\n').map((s, i) => {
            const clusterId = s.match(/\d+$/)?.[0];
            return {
              value: s && `nii:${urlPrefix}/${s}/spmT_0001.nii`,
              label: `Component ${clusterId || i}`,
              clusterId: Number(clusterId || i)
            };
          }).filter(i => !!i.value).sort((a, b) => a.clusterId > b.clusterId)
        }
      }
    }),
    getDsiClusters: build.query({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const labelsRsp = await fetch(`/results/${id}/dsi/clusters.label.txt`);
        const namesRsp = await fetch(`/results/${id}/dsi/clusters.name.txt`);
        if(!labelsRsp.ok || !namesRsp.ok) return {
          data: {
            error: "Unable to load clusters data"
          }
        };

        const labels = await labelsRsp.text();
        const names = await namesRsp.text();
        const data = { data: {
          labels: labels?.split(' ').map(l => parseInt(l)),
          names: names?.split(' ').filter(n => /\S/.test(n))
        }};

        const nameData = data.data.names.map(n => {
          if(n.endsWith('_R') || n.endsWith('_L')) return { name: n.slice(0, -2), hemi: true, prefix: '_' }
          else if(n.endsWith('R') || n.endsWith('L')) return { name: n.slice(0, -1), hemi: true, prefix: '' }
          return { name: n, hemi: false }
        });
        const namesOnly = [];
        nameData.forEach(n => {
          if(!namesOnly.find(i => i.name === n.name)) namesOnly.push(n)
        });
        namesOnly.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        const displayedNames = [];
        data.data.colors = Array(data.data.names.length).fill(undefined);

        const addCluster = (name, color) => {
          const index = data.data.names.findIndex(n => n === name)
          displayedNames.push({ name, index })
          data.data.colors[index] = color;
        }

        namesOnly.forEach((n, i) => {
          const color = hexToRgb(TRACTS_COLORS[i % TRACTS_COLORS.length])
          if(n.hemi) {
            addCluster(n.name + n.prefix + 'L', color)
            addCluster(n.name + n.prefix + 'R', color)
          } else {
            addCluster(n.name, color)
          }
        });

        data.data.displayedNames = displayedNames;

        /*
        data.data.colors = data.data.names.map((_, i) =>
          hexToRgb(TRACTS_COLORS[i % TRACTS_COLORS.length])
        );
        */
        return data;
      },
      providesTags: (res, _, arg) => res && [{ type: 'DSI', id: arg }]
    }),
    doAnalyze: build.mutation({
      query: (body) => ({
        url: '/analyzes/run',
        method: 'POST',
        body
      }),
      invalidatesTags: [
        { type: 'Analyzes', id: 'LIST' }, { type: 'Analyzes', id: 'NEXT_ID' }
      ]
    }),
    deleteAnalysis: build.mutation({
      query: (id) => ({
        url: `/analyzes/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [
        { type: 'Analyzes', id: 'LIST' }
      ]
    }),
    getMatFile: build.query({
      queryFn: async (url, api, extraOptions, baseQuery) => {
        const rsp = await fetch(url);
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        const mat = await rsp.arrayBuffer();
        try {
          const { data } = mat4js.read(mat);
          return { data };
        } catch(error) {
          return { error };
        }
      },
    }),
    getTractsQAFile: build.query({
      queryFn: async (id) => {
        const rsp = await fetch(`/results/${id}/dsi/qa.json`)
        // const rsp = await fetch(`/static/qa.json`)
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        return { data: await rsp.json() };
      }
    }),
    getFreeSurferQAFile: build.query({
      queryFn: async (id) => {
        const rsp = await fetch(`/results/${id}/freesurfer/fsqc-results.csv`)
        // const rsp = await fetch(`/static/fsqc-results.csv`)
        if(!rsp.ok) return {
          error: { status: rsp.status, data: rsp.statusText }
        };

        return { data: await rsp.text() };
      }
    })
  })
});

export const {
  useGetAnalyzesQuery: useAnalyzesQuery,
  useGetAnalysisQuery: useAnalysis,
  useDoAnalyzeMutation: useDoAnalyze,
  useGetConnSourcesQuery: useConnSources,
  useGetConnIcaSourcesQuery: useConnIcaSources,
  useGetConnIcaGroupSourcesQuery: useConnIcaGroupSources,
  useGetDsiClustersQuery: useDsiClusters,
  useGetGroupTractographyResultsQuery: useGroupTractographyResults,
  useGetNextIdQuery: useNextAnalysisId,
  useDeleteAnalysisMutation: useDeleteAnalysis,
  useGetAnalyzersConfigQuery: useAnalyzersConfig,
  useGetMatFileQuery: useMatFile,
  useGetTractsQAFileQuery: useTractsQAFile,
  useGetFreeSurferQAFileQuery: useFreeSurferQAFile,
} = analyzesApi;
