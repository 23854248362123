import React from 'react';
import { connect } from 'react-redux';
import { Alert, Typography, Button, Input, Form } from "antd";
import { Navigate, Link } from 'react-router-dom';
import { selectIsLoggedIn } from "../../../redux/auth/auth.selectors";
import { useSignUp } from '../../../redux/auth/auth.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SignUpStyled from '../SignUp/SignUp.styled';
import logo from '../../../logo.png';

const ErrorCode = Object.freeze({
  NoError: 0,
  FieldsMissing: 1,
  UsernameExists: 2,
  EmailExists: 3,
  EmailInvalid: 4,
  NameTooLong: 5,
  PasswordTooShort: 6,
  InternalError: 7
});

const SignUp = ({ isLoggedIn, ...props }) => {
  const [ form ] = Form.useForm();
  const [ doSignUp, { error, isLoading, isSuccess }] = useSignUp();

  if(isLoggedIn) return <Navigate replace to="/analyzes"/>;

  return (<SignUpStyled>
    <img src={logo} alt={"Logo"}/>
    <Typography.Title level={4}>
      Sign Up
    </Typography.Title>
    { isSuccess
      ? <>
          <Alert showIcon type="success" message="Account successfully created!"/>
          <span>
            To finish the registration in <b>NeuroGraphix</b>, please follow the link sent to <b>{form.getFieldValue('email')}</b>
          </span>
        </>
      : <>
          <Form
            form={form}
            name="register"
            onFinish={doSignUp}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              validateStatus = {
                error?.data?.code === ErrorCode.UsernameExists || error?.data?.code === ErrorCode.NameTooLong ? "error" : undefined }
              help = {
                error?.data?.code === ErrorCode.UsernameExists
                  ? "Username is occupied"
                  : error?.data?.code === ErrorCode.NameTooLong
                    ? "Username is too long"
                    : undefined
              }
              rules={[{
                required: true,
                message: 'User name cannot be empty',
              }]}
            >
              <Input
                placeholder={"User name"}
                prefix={<FontAwesomeIcon icon={faUser}/>}
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateStatus = {
                error?.data?.code === ErrorCode.EmailExists || error?.data?.code === ErrorCode.EmailInvalid ? "error" : undefined
              }
              help = {
                error?.data?.code === ErrorCode.EmailExists
                  ? "Email is occupied"
                  : error?.data?.code === ErrorCode.EmailExists
                    ? "Invalid e-mail"
                    : undefined
              }
              rules={[{
                type: 'email',
                message: 'Invalid e-mail',
              },
              {
                required: true,
                message: 'E-mail must be specified',
              }]}
            >
              <Input
                placeholder={"E-mail"}
                prefix={<FontAwesomeIcon icon={faEnvelope}/>}
              />
            </Form.Item>
            <Form.Item
              name="password"
              hasFeedback
              rules={[{
                required: true,
                message: 'Password must be specified',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.length >= 6) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password must contain at least 6 characters'));
                },
              })]}
            >
              <Input.Password
                placeholder={"Password"}
                prefix={<FontAwesomeIcon icon={faLock}/>}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              hasFeedback
              dependencies={['password']}
              rules={[{
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              })]}
            >
              <Input.Password
                placeholder={"Confirm password"}
                prefix={<FontAwesomeIcon icon={faLock}/>}
              />
            </Form.Item>

            { (error?.data?.code === ErrorCode.InternalError || error?.data?.code === ErrorCode.FieldsMissing)
              && <Alert showIcon type="error" message="Internal error occured. Please try again later"/>
            }

            <Form.Item>
              <Button type="primary" htmlType="submit" size="large"
                loading={isLoading}
              >
                Register
              </Button>
            </Form.Item>
          </Form>


          <span>
            Already have an account? <Link to="/signin"> Sign in </Link>
          </span>
        </>
      }
  </SignUpStyled>);
};

const mapState = (state) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

export default connect(mapState)(SignUp);
