import React, { useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button, Popconfirm, Pagination, Tag, Typography, message } from 'antd';
import { useAnalyzesQuery } from '../../../redux/analyzes/analyzes.api';
import {
  useDatasetsQuery,
  useGroupsQuery
} from '../../../redux/datasets/datasets.api';
import {
  useDeleteAnalysis
} from '../../../redux/analyzes/analyzes.api';
import AnalyzesStyled, { AnalysisItemStyled } from './Analysis.styled.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faCircleCheck,
  faCircleXmark,
  faCircleExclamation,
  faSpinner,
  faStopwatch,
  faBan,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
  faTrashCan
} from '@fortawesome/free-regular-svg-icons';


const DEFAULT_PAGE_SIZE = 1;

const ANALYSIS_TYPES = Object.freeze({
  dsi:        'tractography',
  conn:       'functional',
  freesurfer: 'morphometry'
});

const STATUS_ICONS = Object.freeze({
  created:  <FontAwesomeIcon icon={faCirclePlus}/>,
  waiting:  <FontAwesomeIcon icon={faSpinner} spin style={{color: 'gray'}}/>,
  running:  <FontAwesomeIcon icon={faSpinner} spin style={{color: 'gray'}}/>,
  success:  <FontAwesomeIcon icon={faCircleCheck} style={{color: 'darkgreen'}}/>,
  unstable: <FontAwesomeIcon icon={faCircleExclamation} style={{color: 'darkgoldenrod'}}/>,
  error:    <FontAwesomeIcon icon={faCircleXmark} style={{color: 'darkred'}}/>,
  aborted:  <FontAwesomeIcon icon={faBan}/>
});

const AnalysisItem = ({ data, ids, ...props }) => {
  const navigate = useNavigate();
  const [ deleteAnalysis ] = useDeleteAnalysis();
  const { id, name, status, created, finished, config } = data;

  const { patients } = useDatasetsQuery({ ids }, {
    selectFromResult: ({ data }) => ({
      patients: data?.data.filter(i => {
        return config.ids.find(j => j === i.id);
      }).map(p => {
        return <Link key={p.id} to="/datasets">{p.name} [{p.sex}{p.age}]</Link>
      })
    }),
    skip: config.mode === 'g'
  });

  const { groups } = useGroupsQuery({ ids }, {
    selectFromResult: ({ data }) => ({
      groups: data?.data && config.ids.map(i => {
        return data.data.find(g => g.id === i)?.name || "[deleted]";
      }).join(', ')
    }),
    skip: config.mode === 'i'
  });

  return <AnalysisItemStyled onClick={() => navigate(`/results/${id}`)}>
    <div className='a-name'>
      <i> Analysis </i>
      <Typography.Title level={4}> { name } </Typography.Title>
    </div>
    <div className='a-info'>
      <b> Status: </b>
      <b> { STATUS_ICONS[status] }{ status } </b>
      <span> { finished ? "Finished:" : "Started:" } </span>
      <span>
        { new Date((finished || created) * 1000).toLocaleString() }
        {/* finished && <span className='a-duration'>
          <FontAwesomeIcon icon={faStopwatch}/>
          {finished - created}s
        </span> */}
      </span>
      <span> Subject: </span>
      <span>
        <FontAwesomeIcon icon={config.mode === 'i' ? faUser : faUsers }/>
        { config.mode === 'i' ? patients : groups }
      </span>
      <span> { config.analyzers?.length > 1 ? "Types:" : "Type:" } </span>
      <span>
        { config.analyzers.map(a => <Tag key={a}>{ANALYSIS_TYPES[a]}</Tag>) }
      </span>
    </div>
    <div className='a-actions'>
      <Popconfirm
        title="Delete the study?"
        description="Are you sure to delete this task?"
        onConfirm={e => {
          e.stopPropagation();
          deleteAnalysis(id);
        }}
        onCancel={e => e.stopPropagation()}
        okText="Yes"
        cancelText="No"
      >
        <Button type='link' icon={<FontAwesomeIcon icon={faTrashCan}/>}
          onClick={e => e.stopPropagation()}
        />
      </Popconfirm>
    </div>
  </AnalysisItemStyled>;
}

const Analysis = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPage = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get('page')) || 1;
  }, [location]);

  const pageSize = localStorage.getItem("analyzesPageSize") || DEFAULT_PAGE_SIZE;

  const { data: analyzes, isLoading } = useAnalyzesQuery({
    from: (currentPage - 1) * pageSize,
    count: pageSize
  });

  const { data, total } = analyzes || {};

  const currentPatientIds = useMemo(() => {
    return [...new Set(
      data?.filter(i => i.config?.mode === 'i').map(i => i.config.ids).flat()
    )].join(',');
  }, [data]);

  const currentGroupsIds = useMemo(() => {
    return [...new Set(
      data?.filter(i => i.config?.mode === 'g').map(i => i.config.ids).flat()
    )].join(',');
  }, [data]);

  useDatasetsQuery(
    { ids: currentPatientIds },
    { skip: !data || currentPatientIds.length === 0 }
  );

  useGroupsQuery(
    { ids: currentGroupsIds },
    { skip: !data || currentGroupsIds.length === 0 }
  );

  const analyzesList = useMemo(() => {
    return data?.map(d =>
      <AnalysisItem key={d.id} data={d}
        ids={d.config?.mode === 'i' ? currentPatientIds : currentGroupsIds}
      />
    );
  }, [data, currentPatientIds, currentGroupsIds]);

  return (<AnalyzesStyled>
    <div className='al-header'>
      <Typography.Title level={4}>
        Analyzes
      </Typography.Title>
      <Button type='primary' onClick={() => navigate('/run')}>
        Run new analysis
      </Button>
    </div>
    { analyzesList }
    <Pagination
      showSizeChanger
      pageSize={pageSize}
      pageSizeOptions={[1, 5, 10, 20, 50]}
      onShowSizeChange={(_, size) => {
        localStorage.setItem("analyzesPageSize", size)
      }}
      current={currentPage}
      total={total}
      onChange={(page) => navigate(location.pathname + `?page=${page}`)}
    />
  </AnalyzesStyled>);
}

export default Analysis;
