import React, { useEffect, useState, useRef } from 'react';
import * as html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import { useUser } from '../../redux/api';
import Analysis from './Analysis/Analysis';
import Run from './Analysis/Run';
import Datasets from './Datasets/Datasets';
import Patient from './Datasets/Patients/Patient';
import Upload from './Upload/Upload';
import Viewer from './Viewer/Viewer';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import UserActivation from './SignUp/UserActivation';
import AdminPage from './Admin/AdminPage';
import FeedbackDialog, { FeedbackButton } from './FeedbackDialog';
import MainStyled from './Main.styled';
import { selectIsLoggedIn } from '../../redux/auth/auth.selectors';
import { useRefreshSession } from '../../redux/auth/auth.api';


// const uuidRegex = "([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"

const ACCESS_TOKEN_UPDATE_INTERVAL = 5 * 60 * 1000;

const Main = ({ isLoggedIn, ...props }) => {
  const { data: user, isLoading: isUserLoading } = useUser(undefined, { skip: !isLoggedIn });
  const location = useLocation();

  const refreshTimers = useRef(null);
  const [refreshSession, { isUninitialized, isLoading }] = useRefreshSession();

  const [ rootRef, setRootRef ] = useState(null);

  useEffect(() => {
    // attempt to auto login on mount
    if(isUninitialized) refreshSession();
  }, [isUninitialized, refreshSession]);

  useEffect(() => {
    if(isLoggedIn) {
      refreshTimers.current = [
        setInterval(refreshSession, ACCESS_TOKEN_UPDATE_INTERVAL),
      ];
    } else if(refreshTimers.current) {
      refreshTimers.current.forEach(clearInterval);
    }

    return () => {
      refreshTimers.current?.forEach(clearInterval);
    }
  }, [isLoggedIn, refreshSession]);

  if(!isLoggedIn && (isLoading || isUninitialized)) {
    return <Spin tip="Loading" delay={250}><></></Spin>
  }

  if(!isLoggedIn && !(location.pathname.startsWith('/signin') || location.pathname.startsWith('/signup'))) {
    // setRefererCookie(location.pathname + location.search);
    console.log("REDIRECTING")
    return <Navigate replace to={"/signin"} state={{ redirectTo: location }}/>
  }

  if(isUserLoading) {
    return <Spin tip="Loading" delay={250}><></></Spin>
  }

  return (<div ref={ref => setRootRef(ref)}>
    <Routes>
      <Route path="/results/:id" element={<Viewer rootRef={rootRef}/>}/>
      <Route path="*" element={<MainStyled>
        { isLoggedIn && <FeedbackButton rootRef={rootRef}/> }
        <Row justify={'center'}>
          <Col xl={18} lg={20} xs={22}>
            <Routes>
              <Route exact path="/" element={<Navigate replace to="/signin"/>}/>
              <Route exact path="/signin" element={<SignIn/>}/>
              <Route path="/signin" element={<Navigate replace to="/signin"/>}/>
              <Route exact path="/signup" element={<SignUp/>}/>
              <Route path="/signup/:token" element={<UserActivation/>}/>
              <Route exact path="/run" element={<Run/>}/>
              <Route path="/analysis" element={<Analysis/>}/>
              <Route exact path="/datasets" element={<Datasets/>}/>
              <Route path="/datasets/:id" element={<Patient/>}/>
              <Route path="/upload" element={<Upload/>}/>
              { user?.isAdmin &&
                <Route path="/admin" element={<AdminPage/>}/>
              }
              <Route path="*" element={<Navigate replace to={"/datasets"}/>}/>
            </Routes>
          </Col>
        </Row>
      </MainStyled>}/>
    </Routes>
  </div>);
}

const mapState = (state, props) => ({
  isLoggedIn: selectIsLoggedIn(state)
});

export default connect(mapState, null)(Main);
