import { ImageVolume, cache } from '@cornerstonejs/core';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { fetchUrl, triggerVolumeLoadedEvent, triggerVolumeCacheAddedEvent } from '../cornerstone/fetcher';
import BinaryReader from '../binReader';
import { loadMGZ } from '../mgz/mgzLoader';

// Reads string until \n\n
// More info at http://www.grahamwideman.com/gw/brain/fs/surfacefileformats.htm
const surfStringReader = (data, offset) => {
  let str = "";
  let breakFound = false
  for(let i = offset; i < data.byteLength; ++i) {
    const ch = data.getUint8(i);

    if(ch === 0x0a) {
      if(breakFound) break;
      else breakFound = true;
    } else {
      breakFound = false;
    }

    if(ch !== 0) str += String.fromCharCode(ch);
  }

  return { data: str, size: str.length + 1 };
}

const int3Reader = (data, offset, isLittleEndian) => {
  return { data: data.getInt32(offset, isLittleEndian) >> 8, size: 3 }
}

const minMax = (a, b) => {
  return [ Math.min(a[0], b[0]), Math.max(a[1], b[1]) ];
}

const concatTypedArrays = (a, b) => {
  const c = new a.constructor(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
}

// ----------------------------------------------------------------------------

class CurvLoadError extends Error {
  constructor(volumeId, message) {
    super(message);
    this.volumeIds = [volumeId, ...['lh', 'rh'].map(i =>
      [`${volumeId}/${i}.pial`, `${volumeId}/${i}.thickness`]
    ).flat()];
  }
}

// ----------------------------------------------------------------------------

const loadHemiSurface = async (baseUrl, baseName, options) => {
  const surfUrl = `${options.surfUrl || baseUrl}/${baseName}${options.surfSuffix || '.pial'}`
  const surfData = await fetchUrl(surfUrl);

  triggerVolumeCacheAddedEvent({ volume: { volumeId: surfUrl } });

  const rs = new BinaryReader(surfData);
  rs.addCustomReader('surf-string', surfStringReader);
  rs.addCustomReader('int3', int3Reader);
  rs.checkLittleEndian('int3', 0, -2);

  const surfHeader = {
    magic:        rs.scan('int3'),
    creator:      rs.scan('surf-string'),
    vertexCount:  rs.scan('int'),
    faceCount:    rs.scan('int')
  };

  if(surfHeader.magic !== -2) {
    throw new Error(`Invalid surface file: ${surfUrl}`);
  }

  const vertexArray = new Float32Array(surfHeader.vertexCount * 3);
  for(let i = 0; i < surfHeader.vertexCount; ++i) {
    const [ x, y, z ] = rs.scan('float', 3);
    vertexArray[3 * i] = x;
    vertexArray[3 * i + 1] = y;
    vertexArray[3 * i + 2] = z;
  }

  const facesArray = []
  for(let i = 0; i < surfHeader.faceCount; ++i) {
    facesArray.push(rs.scan('int', 3));
  }

  return {
    points:  vertexArray,
    faces: facesArray,
  }
}

const loadSurface = async (baseUrl, options) => {
  const startTime = new Date().getTime();

  const [ lh, rh ] = await Promise.all([
    loadHemiSurface(baseUrl, 'lh', options),
    loadHemiSurface(baseUrl, 'rh', options),
  ]).catch(error => {
    throw new CurvLoadError(baseUrl, error.message);
  });

  const vertexArray = concatTypedArrays(lh.points, rh.points);
  const facesArray = new Uint32Array(4 * (lh.faces.length + rh.faces.length));
/*
  const addFaces = (faces, start = 0, offset = 0) => {
    let j = start;
    for(let i = 0; i < faces.length; ++i) {
      facesData[j] = faces[i].length;
      for(let k = 0; k < faces[i].length; ++k) {
        facesData[j + k + 1] = faces[i][k] + offset
      }
      j += faces.length + 1
    }
  }
*/
  let j = 0;
  for(let i = 0; i < lh.faces.length; ++i) {
    facesArray[j] = lh.faces[i].length;
    for(let k = 0; k < lh.faces[i].length; ++k) {
      facesArray[j + k + 1] = lh.faces[i][k]
    }
    j += lh.faces[i].length + 1
  }

  const offset = lh.points.length / 3
  for(let i = 0; i < rh.faces.length; ++i) {
    facesArray[j] = rh.faces[i].length;
    for(let k = 0; k < rh.faces[i].length; ++k) {
      facesArray[j + k + 1] = rh.faces[i][k] + offset
    }
    j += rh.faces[i].length + 1
  }

  return {
    points:  vertexArray,
    faces: facesArray,
    elapsed: new Date().getTime() - startTime
  };
}

// ----------------------------------------------------------------------------

const loadHemiOverlay = async (baseUrl, baseName, options) => {
  const curvSuffix = options.curvSuffix || '.thickness';
  const mgzSuffix  = options.mgzSuffix;
  const curvMode   = !mgzSuffix;

  const curvUrl = `${options.curvUrl || baseUrl}/${baseName}${curvSuffix}`;
  const mgzUrl = `${options.mgzUrl || baseUrl}/${baseName}${mgzSuffix}`;

  const data = await (curvMode ? fetchUrl(curvUrl) : loadMGZ(mgzUrl, {}));

  triggerVolumeCacheAddedEvent({ volume: { volumeId: curvMode ? curvUrl : mgzUrl } });

  let header = undefined;
  let rt = undefined;
  if(curvMode) {
    rt = new BinaryReader(data);
    rt.addCustomReader('int3', int3Reader);
    rt.checkLittleEndian('int', 11, 1);

    header = {
      magic:          rt.scan('int3'),
      vertexCount:    rt.scan('int'),
      faceCount:      rt.scan('int'),
      valsPerVertex:  rt.scan('int')
    };

    if(header.magic !== -1 || header.valsPerVertex !== 1) {
      throw new Error(`Invalid curvature file: ${curvUrl}`);
    }
  } else {
    header = data.header;
    header.vertexCount = data.header.width;

    if(header.height !== 1 || header.depth !== 1 || header.nframes !== 1) {
      throw new Error(`Invalid curvature file: ${mgzUrl}`);
    }
  }

  let minT = Infinity;
  let maxT = -Infinity;

  const dataArray = new Float32Array(header.vertexCount);
  for(let i = 0; i < header.vertexCount; ++i) {
    const t = curvMode ? rt.scan('float') : data.data[i];
    dataArray[i] = options.invert ? -t : t;

    if(t < minT) minT = t;
    else if(t > maxT) maxT = t;
  }

  return {
    scalars: dataArray,
    bounds: {
      t: [minT, maxT]
    }
  }
}

const loadOverlay = async (baseUrl, options) => {
  const startTime = new Date().getTime();

  const [ lh, rh ] = await Promise.all([
    loadHemiOverlay(baseUrl, 'lh', options),
    loadHemiOverlay(baseUrl, 'rh', options),
  ]).catch(error => {
    throw new CurvLoadError(baseUrl, error.message);
  });

  const scalarsArray = concatTypedArrays(lh.scalars, rh.scalars);
  const dataRange = minMax(lh.bounds.t, rh.bounds.t);

  const da = vtkDataArray.newInstance({
    numberOfComponents: 1,
    values: scalarsArray,
  });
  da.setName('Scalars');

  return {
    scalars: da,
    elapsed: new Date().getTime() - startTime,
    dataRange,
    minThreshold: options.minThreshold
  }
}

// ----------------------------------------------------------------------------

const loadModel = async (volumeId, { sourceVolumeId, dilation, ...options }) => {

  const data = await Promise.all([
    ...options.surfaces.map(s => loadSurface(volumeId, s)),
    ...options.overlays.map(o => loadOverlay(volumeId, o)),
    cache.getVolumeLoadObject(sourceVolumeId).promise
  ]).catch(error => {
    throw new CurvLoadError(volumeId, error.message);
  });

  let i = 0;
  const surfaces = [];
  const overlays = [];
  for(; i < options.surfaces.length; ++i) {
    surfaces.push(data[i]);
  }
  for(; i < options.surfaces.length + options.overlays.length; ++i) {
    overlays.push(data[i])
  }
  const sourceVolume = data[i]

  const voxelizeIdx = options.overlays.findIndex(i => i.base);
  const surfaceIdx = options.surfaces.findIndex(i => i.base);
  let voxelizationTime = undefined;
  let voxelData = undefined;
  let minT = 0;
  let maxT = 0;
  if(voxelizeIdx > -1 && surfaceIdx > -1) {
    // build voxel volume
    const startTime = new Date().getTime();
    const [ vxs, vys, vzs ] = sourceVolume.dimensions;
    const [ sx, sy, sz ] = sourceVolume.spacing;

    voxelData = new Array(vxs * vys * vzs).fill(undefined);

    const vertexArray = surfaces[surfaceIdx].points
    const scalarsArray = overlays[voxelizeIdx].scalars.getData()
    minT = overlays[voxelizeIdx].dataRange[0]
    maxT = overlays[voxelizeIdx].dataRange[1]

    const setValue = (x, y, z, value) => {
      const idx = x + vxs * y + vxs * vys * z;
      //if(voxelData[idx]) voxelData[idx].push(value);
      //else voxelData[idx] = [ value ];
      voxelData[idx] = [ value ];
    }

    const dilate = (x, y, z, value, r) => {
      for(let i = Math.max(z - r, 0); i < Math.min(z + r, vzs); ++i) {
        for(let j = Math.max(y - r, 0); j < Math.min(y + r, vys); ++j) {
          for(let k = Math.max(x - r, 0); k < Math.min(x + r, vxs); ++k) {
            setValue(k, j, i, value);
          }
        }
      }
    }

    for(let i = 0; i < vertexArray.length; i += 3) {
      const x = Math.trunc(vxs / 2 - vertexArray[i] / sx);
      const y = Math.trunc(vys / 2 + vertexArray[i + 1] / sy);
      const z = Math.trunc(vzs / 2 + vertexArray[i + 2] / sz);
      if(!dilation || dilation < 1) setValue(x, y, z, scalarsArray[i / 3]);
      else dilate(x, y, z, scalarsArray[i / 3], dilation);
    }

    voxelData = voxelData.map(v => {
      return v?.length ? v.reduce((a, i) => a + i, 0) / v.length : 0
    });

    voxelizationTime = new Date().getTime() - startTime;
  }

  // console.log(`Thickness parsing time ${lh.elapsed + rh.elapsed} ms`);
  // console.log(`Thickness voxelization time ${elapsed} ms`)

  return {
    dims:       sourceVolume.dimensions,
    spacing:    sourceVolume.spacing,
    origin:     sourceVolume.origin,
    metadata:   {
      ...sourceVolume.metadata,
      FrameOfReferenceUID: 'default',
      Modality: 'Thickness',
      Units: 'mm',
      minThickness: minT,
      maxThickness: maxT,
      dataRange: [minT, maxT],
      parsingTime: data.reduce((a, i) => i.elapsed ? a + i.elapsed : a, 0),
      voxelizationTime
    },
    scalarData: new Float32Array(voxelData),
    polyData:   { surfaces, overlays },
    size: voxelData.length * 4,
  };
}

// ----------------------------------------------------------------------------

const thicknessVolumeLoader = (volumeId, options) => {
  return {
    promise: loadModel(volumeId, options).then(volume => {
      const vol = new ImageVolume({
        volumeId,
        imageIds: [],
        dimensions:  volume.dims,
        spacing:     volume.spacing,
        origin:      volume.origin,
        metadata:    volume.metadata,
        direction:   [1, 0, 0, 0, 1, 0, 0, 0, 1],
        scalarData:  volume.scalarData,
        sizeInBytes: volume.size
      });

      vol.polyData = volume.polyData;
      vol.csRenderable = true;
      vol.isReady = true;

      triggerVolumeLoadedEvent(vol);
      return vol;
    }),
    cancel: undefined /* TODO */
  }
}

export default thicknessVolumeLoader;
