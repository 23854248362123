import styled from 'styled-components';

const StatPanel = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 8px;

  span:first-child {
    line-height: 10px;
    font-size: 10px;
  }

  span:last-child {
    line-height: 22px;
  }
`;

const ToolBarStyled = styled.span`
  padding: 0 8px;
  display: flex;
  align-items: center;
  width: calc(75% - 1px);

  .ant-typography {
    color: white;
  }

  .ant-radio-group {
    display: flex;

    svg {
      vertical-align: -0.1em;
    }
  }

  .ant-btn {
    margin-left: 4px;
    color: white;

    :hover {
      color: #9ccef9;
    }
  }

  .ant-btn:not(.ant-dropdown-trigger) > svg {
    font-size: 16px;
  }

  .ant-select {
    color: white;
    margin-left: 0.3em;
    padding-right: 10px;

    .ant-select-selector {
      padding-left: 0;
      border-bottom: 1px solid #9ccef9 !important;
    }

    .ant-select-arrow {
      color: #9ccef9;
    }
  }

  > span:last-child {
    display: flex;
    align-items: center;
    margin-left: auto;

    .ant-btn {
      border-radius: 8px;
      color: white;
      > svg {
        font-size: 16px;
      }

      :hover:not([disabled]) {
        color: #9ccef9;
      }
    }

    .ant-btn[disabled] {
      color: gray;
      vertical-align: 0px;
    }

    .ant-btn-active {
      background-color: #1890ff;

      :hover {
        color: white !important;
        background-color: #40a9ff;
      }
    }
  }
`;

export { StatPanel };
export default ToolBarStyled;
