import styled from 'styled-components';

const GroupsStyled = styled.div`
  .ant-btn {
    padding: 0;
    height: 18px;
  }

  .actions-box > svg {
    transition: color 0.3s;
    cursor: pointer;
    margin-right: 8px;

    :hover {
      color: gray;
      transition: color 0.3s;
    }
  }
`;

export default GroupsStyled;
