import React, { useEffect, useMemo, useRef } from 'react';
import { Spin, Typography } from 'antd';
import {
  ComposedChart, LineChart, Label, Legend, Area, Line, CartesianGrid, XAxis, YAxis
} from 'recharts';
import { QAPlotStyled } from './QADialog.styled';
import QASlicesPlot from './QASlicesPlot';
import { useMatFile } from '../../../../redux/analyzes/analyzes.api';

const resizeCanvas = (canvas, width, height) => {
  const tempCanvas = document.createElement("canvas");
  const tctx = tempCanvas.getContext("2d");
  const [ cw, ch ] = [ canvas.width, canvas.height ];

  tempCanvas.width = cw;
  tempCanvas.height = ch;
  tctx.drawImage(canvas, 0, 0);

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(tempCanvas, 0, 0, cw, ch, 0, 0, width, height);

  tempCanvas.remove();
}

// ----------------------------------------------------------------------------

const QADenoisingPlot = ({ url }) => {
  const { data, error } = useMatFile(url, { skip: !url });

  const plotData = useMemo(() => {
    if(!data) return undefined;

    const labels = data.results_label.map(l => String.fromCharCode(...l));
    const values = [];
    for(let i = 1; i < data.results_patch[0].length; ++i) {
      values.push({
        name: data.results_patch[0][i],
        aad:  data.results_patch[1][i],
        abd:  data.results_patch[2][i],
        ad:   data.results_patch[3][i],
        bd:   data.results_patch[4][i]
      });
    }

    const {
      MeanBefore, StdBefore, IntersectionBefore, PercentSignificantBefore,
      MeanAfter, StdAfter, IntersectionAfter, PercentSignificantAfter
    } = data.results_info

    return {
      values,
      title: labels[0],
      footer: labels.splice(1).join('\n'),
      xLabel: String.fromCharCode(...data.results_info.units),
      legendLabel: "expected shape of distribution under Null Hypothesis (if no QC-FC associations exist; random permutations)",
      bdLabel: [
        `mean=${MeanBefore}, std=${StdBefore} (${IntersectionBefore}% match with NH)`,
        `${PercentSignificantBefore[0]}% edges with p<.05, ${PercentSignificantBefore[1]}% edges with q<.05`
      ].join('\n'),
      adLabel: [
        `mean=${MeanAfter}, std=${StdAfter} (${IntersectionAfter}% match with NH)
        ${PercentSignificantAfter[0]}% edges with p<.05, ${PercentSignificantAfter[1]}% edges with q<.05`
      ].join('\n')
    };
  }, [data]);

  if(error) return "Error";
  if(!data) return <Spin/>;

  const { values, xLabel, adLabel, bdLabel, legendLabel, footer, title } = plotData;

  return <QAPlotStyled>
    <Typography.Title level={4}>
      {title}
    </Typography.Title>
    <ComposedChart width={600} height={162} data={values} margin={{ bottom: 24 }}>
      <Legend verticalAlign="top"
        wrapperStyle={{ paddingBottom: '16px' }}
        formatter={(value) =>
          <span style={{color: 'black', fontSize: '8pt'}}>{value}</span>
        }
      />
      <CartesianGrid stroke="#ccc" />
      <Line legendType="plainline" name={legendLabel} type="monotone" dataKey="bd" stroke="#FF0000" dot={false} strokeDasharray="5 5" strokeWidth={2}/>
      <Area legendType="none" type="monotone" dataKey="abd" fill="#ddd" stroke="#777" />
      <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} tickCount={10}>
        <Label x={5} y={68} style={{fontWeight: 'bold'}}
          content={<text>Before denoising</text>}
        />
        <Label x={5} y={83} style={{fontSize: '8pt', whiteSpace: 'pre-line'}}
          content={<text>{bdLabel}</text>}
        />
      </XAxis>
    </ComposedChart>

    <ComposedChart width={600} height={124} data={values} margin={{ bottom: 24 }}>
      <Line type="monotone" dataKey="ad" stroke="#FF0000" dot={false} strokeDasharray="5 5" strokeWidth={2}/>
      <CartesianGrid stroke="#ccc" />
      <Area type="monotone" dataKey="aad" fill="#ddd" stroke="#777" />
      <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} tickCount={10}>
        <Label value={xLabel} position="bottom" offset={0} />
        <Label x={5} y={30} style={{fontWeight: 'bold'}}
          content={<text>After denoising</text>}
        />
        <Label x={5} y={45} style={{fontSize: '8pt', whiteSpace: 'pre-line'}}
          content={<text>{adLabel}</text>}
        />
      </XAxis>
    </ComposedChart>
    <p>
      {footer}
    </p>
  </QAPlotStyled>
}

// ----------------------------------------------------------------------------

const QATimeseriesPlot = ({ url, scaleX, scaleY }) => {
  const canvasRef = useRef(null);
  const { data, error, isLoading } = useMatFile(url, { skip: !url });

  const plotData = useMemo(() => {
    if(!data) return undefined;

    const { xlabels, xcov, xcov_name } = data.conn_args[1];
    const values = xcov.map((v, i) => ({
      name: i,
      z: v[0],
      m: v[1],
      o: v[0]
    }));

    return {
      values,
      title: String.fromCharCode(...xlabels[0]),
      labels: xcov_name.map(i => String.fromCharCode(...i))
    };
  }, [data]);

  useEffect(() => {
    if(!data || isLoading) return;

    const { nX, y, datalim } = data.conn_args[1];
    const w = nX[2];
    const h = nX[3];

    const canvas = canvasRef.current;
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, w, h);
    const img = imageData.data;

    let n = 0;
    for(let i = 0; i < y.length; ++i) {
      for(let j = 0; j < y[i].length; ++j) {
        const val = Math.round(y[i][j] / datalim * 255);
        img[n] = val;
        img[n + 1] = val;
        img[n + 2] = val;
        img[n + 3] = 255;
        n += 4;
      }
    }

    ctx.putImageData(imageData, 0, 0);

    if(scaleX || scaleY) {
      resizeCanvas(canvas, w * (scaleX || 1), h * (scaleY || 1));
    }
  }, [data, isLoading, scaleX, scaleY]);

  if(error) return "Error";
  if(!data || isLoading) return <Spin/>;

  return <QAPlotStyled>
    <Typography.Title level={4}>{plotData.title}</Typography.Title>
    <canvas ref={canvasRef}/>
    <LineChart  width={600} height={50} data={plotData.values} margin={{bottom: 0}}>
      <Line type="monotone" dataKey="z"  dot={false}/>
      <CartesianGrid stroke="#ccc" vertical={false}/>
      <XAxis type="number" dataKey="name" hide domain={['dataMin', 'dataMax']}/>
      <YAxis type="number" domain={['auto', 'auto']}>
        <Label value={plotData.labels[0]} position="right"/>
      </YAxis>
    </LineChart>
    <LineChart  width={600} height={50} data={plotData.values} margin={{top: 0, bottom: 0}}>
      <Line type="monotone" dataKey="m" dot={false}/>
      <CartesianGrid stroke="#ccc" vertical={false}/>
      <XAxis type="number" dataKey="name" hide domain={['dataMin', 'dataMax']}/>
      <YAxis type="number" domain={['auto', 'auto']}>
        <Label value={plotData.labels[1]} position="right"/>
      </YAxis>
    </LineChart>
    <LineChart  width={600} height={50} data={plotData.values} margin={{top: 0, bottom: 0}}>
      <Line type="monotone" dataKey="o" dot={false}/>
      <CartesianGrid stroke="#ccc" vertical={false}/>
      <XAxis type="number" dataKey="name" hide domain={['dataMin', 'dataMax']}/>
      <YAxis type="number" domain={['auto', 'auto']}>
        <Label value={plotData.labels[2]} position="right"/>
      </YAxis>
    </LineChart>
  </QAPlotStyled>;
}

export { QATimeseriesPlot, QADenoisingPlot, QASlicesPlot };
