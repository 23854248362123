import React, { useState } from 'react';
import { Spin } from 'antd';
import { LogViewStyled, LogViewTabsStyled } from './LogView.styled';
import { useDatasetLog } from '../../../../redux/datasets/datasets.api';

const LogViewInternal = ({ log, ...props }) => {
  const { data, isLoading, isError } = log;

  return <LogViewStyled>
    { isLoading
      ? <Spin style={{margin: "auto"}}/>
      : isError
        ? <span className='log-error'>Unable to load data</span>
        : data.length === 0
          ? <span style={{margin: "auto"}}>No data</span>
          : <div className='log-content' dangerouslySetInnerHTML={{ __html: data }} />
    }
  </LogViewStyled>
}

const LogView = (props) => {
  const { id } = props;

  const [ activeLog, setActiveLog ] = useState('output')
  const log = useDatasetLog({ id, type: activeLog });

  return <LogViewTabsStyled
    activeKey={activeLog}
    onChange={setActiveLog}
    items={[
      {
        label: 'Output',
        key: 'output',
        children: <LogViewInternal log={log}/>,
      },
      {
        label: 'Error',
        key: 'error',
        children: <LogViewInternal log={log}/>
      }
    ]}
  />
}

export default LogView;
