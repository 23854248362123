import { mriaApi, generateQuery, tagTypes } from '../api';

export const authApi = mriaApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (login) => ({
        url: '/users/login',
        method: 'POST',
        body: login,
        headers: {
          'Authorization': 'none',
          'Content-Type':  'application/json;charset=utf-8'
        }
      })
    }),
    refreshSession: build.mutation({
      query: () => ({
        url: '/users/refresh_session',
        method: 'POST',
        headers: { 'Authorization': 'none' }
      })
    }),
    logout: build.mutation({
      query: () => ({
        url: '/users/logout',
        method: 'POST'
      }),
      invalidatesTags: tagTypes
    }),
    getAnalysisAccessToken: build.query({
      query: (ids) => '/analyzes/token' + generateQuery({
        ids: (ids instanceof Array) ? ids.join(',') : ids
      })
    }),
    signup: build.mutation({
      query: (user) => ({
        url: '/users/signup',
        method: 'POST',
        body: user,
        headers: {
          'Authorization': 'none',
          'Content-Type':  'application/json;charset=utf-8'
        }
      })
    }),
    activateUser: build.query({
      query: (token) => ({
        url: `/users/activate/${token}`,
        headers: {
          'Authorization': 'none'
        }
      })
    })
  })
});

export const {
  useLoginMutation: useLogin,
  useRefreshSessionMutation: useRefreshSession,
  useLogoutMutation: useLogout,
  useGetAnalysisAccessTokenQuery: useAnalysisAccessToken,
  useSignupMutation: useSignUp,
  useActivateUserQuery: useActivateUser,
} = authApi;
