import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { selectAccessToken } from './auth/auth.selectors';

export const generateQuery = (params, fullQuery = true) => {
  if(!params || Object.keys(params).length === 0) return '';
  let url = fullQuery ? '?' : '&';
  for (let p in params) {
    if(params[p] !== undefined && params[p] !== null) url += p + '=' + params[p] + '&';
  }
  return url.slice(0, -1);
}
/*
export const createBaseQuery = (api) => {
  return async (url, options) => {
    const token = selectAccessToken(api.getState());
    try {
      const rsp = await fetch(getBackendURL() + url + generateQuery(options), {
        credentials: 'same-origin',
        headers: { "Authorization" : `Bearer ${token}` }
      });

      return rsp.status === 200
        ? { data: await rsp.json(), status: 200 }
        : { error: "Invalid response: " + rsp.statusText, status: rsp.status };

    } catch(error) {
      return { error };
    }
  }
}
*/
// ---------------------------------------------------------------------------

const PERMISSION_ADMIN = 0x8000;

export const tagTypes = [
  'User', 'Datasets', 'Groups', 'Analyzes', 'Feedback'
];

export const mriaApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
      const token = selectAccessToken(getState());
      if(headers.get('Authorization') === 'none') headers.delete('Authorization');
      else if(token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    }
  }),
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getUser: build.query({
      query: () => '/users/me',
      transformResponse: (user) => user && {
        ...user,
        isAdmin: !!(user.permissions & PERMISSION_ADMIN)
      },
      providesTags: [ 'User' ]
    }),
    getUserList: build.query({
      query: () => '/users/list'
    }),
    getFeedbackAccessToken: build.query({
      query: () => '/users/feedback/access_token',
      providesTags: [{ type: 'Feedback', id: 'TOKEN' }]
    }),
    getFeedback: build.query({
      query: () => '/users/feedback',
      providesTags: [{ type: 'Feedback', id: 'LIST' }]
    }),
    setFeedbackStatus: build.mutation({
      query: ({ id, status }) => ({
        url: '/users/feedback/status',
        method: 'POST',
        body: { id, status }
      }),
      invalidatesTags: [
        { type: 'Feedback', id: 'LIST' }
      ]
    })
  })
});

export const {
  useGetUserQuery: useUser,
  useGetUserListQuery: useUserList,
  useGetFeedbackAccessTokenQuery: useFeedbackAccessToken,
  useGetFeedbackQuery: useFeedbackList,
  useSetFeedbackStatusMutation: useSetFeedbackStatus
} = mriaApi;
