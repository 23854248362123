import { mriaApi } from '../api';
import JSZip from 'jszip';
import { setCompressionProgress, setProgress, setError } from './upload.slice';
import { selectUploadData, selectGroup } from './upload.selectors';
import { selectAccessToken } from '../auth/auth.selectors';
import { readDicomData } from '../../modules/dcm/parser';

const sendUploadRequest = (uploadData, groupData, token, dispatch) => {
  const data = new FormData();
  const metadata = {
    group: groupData,
    patients: uploadData.map(i => i.patient)
  };

  data.append('metadata', JSON.stringify(metadata));
  uploadData.forEach(({ file }, i) => {
    data.append(`file${i}`, file);
  });

  let xhr = new XMLHttpRequest();
  xhr.open("POST", "/api/datasets/upload");
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    const progress = Math.min(Math.trunc(event.loaded / event.total * 100), 99);
    dispatch(setProgress(progress));
  }

  xhr.onloadstart = () => {
    dispatch(setProgress(0));
  };

  xhr.onloadend = () => {
    if(Math.trunc(xhr.status / 100) === 2) {
      dispatch(setProgress(100));
    } else if(xhr.status) {
      dispatch(setError(`Unable to upload dataset: ${xhr.status}`));
    }
  }

  xhr.onerror = () => {
    dispatch(setError(`Unable to upload dataset: ${xhr.statusText}`));
  }

  xhr.onabort = () => {
    dispatch(setError("Aborted"));
  }

  // dispatch(setUploadRequest(id, xhr));
  xhr.send(data);
}


const compressData = async (data, dispatch) => {
  const totalSize = data.reduce((a, { files }) => {
    return a + files.reduce((b, f) => b + f.size, 0);
  }, 0);
  let currentSize = 0;

  const res = [];
  for(let i = 0; i < data.length; ++i) {
    const { patient, files } = data[i];
    const zip = new JSZip();

    const progressBase = i / data.length * 100;

    for(let j = 0; j < files.length; ++j) {
      const data = await readDicomData(files[j].file);
      zip.file(`${j}.dcm`, data);

      currentSize += files[j].size;
      dispatch(setCompressionProgress(Math.floor(progressBase + currentSize / totalSize / data.length * 50)));
    }

    res.push({
      patient,
      file: await zip.generateAsync(
        { type: 'blob', compression: 'DEFLATE' },
        (metadata) => {
          dispatch(setCompressionProgress(Math.floor(progressBase + (50 + metadata.percent / 2) / data.length)));
        }
      )
    });
  }

  dispatch(setCompressionProgress(100));

  return res;
}

const uploadData = async (arg, api, extraOptions, baseQuery) => {
  const state = api.getState();
  const data  = await compressData(selectUploadData(state), api.dispatch);

  const token = selectAccessToken(api.getState());

  sendUploadRequest(data, selectGroup(state), token, api.dispatch);

  return { data: undefined };
}

export const uploadApi = mriaApi.injectEndpoints({
  endpoints: (build) => ({
    upload: build.mutation({
      queryFn: uploadData,
      invalidatesTags: res => [
        { type: 'Datasets', id: 'LIST' },
        { type: 'Groups', id: 'LIST' }
      ]
    }),
    sendFeedback: build.mutation({
      query: ({ path, category, message, image }) => {
        const data = new FormData();
        data.append('path', path);
        data.append('category', category);
        data.append('message', message.substring(0, 1000));
        data.append('image', image);

        return {
          url: '/users/feedback',
          method: 'POST',
          body: data
        }
      }
    })
  })
});

export const {
  useUploadMutation: useUploadRequest,
  useSendFeedbackMutation: useSendFeedbackRequest
} = uploadApi;
