import styled from 'styled-components';

const UserBadgeStyled = styled.div`
  line-height: 32px;
  color: white;
  transition: background-color 0.3s;
  padding: 0px 20px;

  svg:first-child {
    font-size: 16px;
    margin-right: 4px;
  }

  :hover {
    cursor: pointer;
    background-color: #1890ff;
    transition: background-color 0.3s;
  }
`;

export default UserBadgeStyled;
