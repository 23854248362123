import { fetchData, elapsed } from '../cornerstone/fetcher';
import { ungzip } from 'pako';
import BinaryReader from '../binReader';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkPLYReader from '@kitware/vtk.js/IO/Geometry/PLYReader';
import PlyImageVolume from './plyVolume';

const loadPly = (volumeId, options) => fetchData(volumeId, options)
  .then(data => elapsed(() => {
    const reader = vtkPLYReader.newInstance();
    reader.parseAsArrayBuffer(data);
    return reader.getOutputData();
  }
));

const plyVolumeLoader = (volumeId, options) => {
  return {
    promise: loadPly(volumeId, options).then(polyData => {
      const plyVolume = new PlyImageVolume({
        volumeId,
        imageIds: [],
        dimensions: [1, 1, 1],
        spacing:    [1, 1, 1],
        origin:     [0, 0, 0],
        metadata:   {
          FrameOfReferenceUID: 'default',
          Modality: 'CT',
          PhotometricInterpretation: 'MONOCHROME2',
          PixelRepresentation: 0,
        },
        direction:   [1, 0, 0, 0, 1, 0, 0, 0, 1],
        scalarData:  new Uint8Array().fill(0),
        sizeInBytes: 1,
        polyData:    polyData
      });

      return plyVolume;
    }),
    cancel: undefined /* TODO */
  }
}

export default plyVolumeLoader;
