import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Typography, Button, Input, Checkbox, Tabs } from "antd";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  selectIsLoggedIn,
  selectAuthError
} from "../../../redux/auth/auth.selectors";
import { useLogin } from '../../../redux/auth/auth.api';
import SignInStyled from './SignIn.styled';
import logo from '../../../logo.png';

const SignIn = ({ isLoggedIn, ...props }) => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  const [ login, setLogin ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ remember, setRemember ] = useState(false);
  const [ doLogin, { isLoading: loggingIn }] = useLogin();

  const signInHandler = () => doLogin({ login, password, remember });

  if(isLoggedIn) {
    if (locationState) {
      const { redirectTo } = locationState;
      return <Navigate replace to={`${redirectTo.pathname}${redirectTo.search}`}/>
    } else {
      return <Navigate replace to='/analyzes'/>
    }
  }

  return (<SignInStyled>
    <img src={logo} alt={"Logo"}/>
    <Typography.Title level={4}>
      Sign In
    </Typography.Title>
    <Input
      placeholder={"Login"}
      prefix={<FontAwesomeIcon icon={faUser}/>}
      onChange={e => setLogin(e.target.value)}
      value={login}
    />
    <Input.Password
      placeholder={"Password"}
      prefix={<FontAwesomeIcon icon={faLock}/>}
      onChange={e => setPassword(e.target.value)}
      value={password}
      onPressEnter={signInHandler}
    />
    <Checkbox
      onChange={() => setRemember(!remember)}
      checked={remember}
    >
      Remember
    </Checkbox>
    { props.errorMessage &&
      <Alert type="error" message={props.errorMessage}/>
    }
    <Button type='primary' size='large'
      loading={loggingIn}
      onClick={signInHandler}
    >
      Sign In
    </Button>
    <span>
      Do not have an account? <Link to="/signup"> Click here to sign up </Link>
    </span>
  </SignInStyled>);
};

const mapState = (state) => ({
  isLoggedIn:   selectIsLoggedIn(state),
  errorMessage: selectAuthError(state)
});

export default connect(mapState)(SignIn);
