import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Alert, Typography, Spin } from "antd";
import { Navigate, Link, useParams } from 'react-router-dom';
import { selectIsLoggedIn } from "../../../redux/auth/auth.selectors";
import { useActivateUser } from '../../../redux/auth/auth.api';
import SignUpStyled from '../SignUp/SignUp.styled';

const EXPECTED_TOKEN_LENGTH = 64

const UserActivation = ({ isLoggedIn, ...props }) => {
  const { token } = useParams()

  const isValidToken = useMemo(() => {
    return token?.length >= EXPECTED_TOKEN_LENGTH && token?.length < 2 * EXPECTED_TOKEN_LENGTH
  }, [token]);

  const { error, isLoading, isSuccess } = useActivateUser(token,
    { skip: !isValidToken || isLoggedIn }
  )

  if(isLoggedIn) return <Navigate replace to="/analyzes"/>;

  return (<SignUpStyled>
    <Typography.Title level={4}>
      Sign Up
    </Typography.Title>
    { isValidToken && !error
      ? isLoading
        ? <Spin/>
        : (isSuccess && <>
            <Alert showIcon type="success" message={
              <>
                <span>
                  Your account is successfully activated!
                </span>
                <br/>
                <span>
                  Now you can <Link to="/signin">log in</Link> using your credentials.
                </span>
              </>
            }/>
          </>)
      : <Alert showIcon type="error" message="Invalid activation link"/>
    }
  </SignUpStyled>)
};

const mapState = (state) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

export default connect(mapState)(UserActivation);
