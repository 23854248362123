import styled from 'styled-components';
import { Modal } from 'antd';

const QADialogStyled = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-Height: calc(100vh - 100px)
  }

  .qa-content {
    display: flex;
    flex-grow: 1;

    .ant-menu {
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: thin;
    }
  }

  .qa-data {
    width: 100%;
  }
`;

export const QAPlotStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  padding-top: 10px;

  canvas {
    margin-bottom: 24px;
  }

  > h4 {
    margin-bottom: 24px;
    text-align: center;
  }

  > p {
    font-size: 8pt;
    white-space: pre-line;
    text-align: center;
    margin-top: 12px;
  }
`;

export default QADialogStyled;
