import React, { useMemo } from 'react';
import { Table, Typography, Spin } from 'antd';
import { useTractsQAFile } from '../../../../redux/analyzes/analyzes.api';

const QATracts = ({ id, dataKey, ...props }) => {
  const { data, error, isLoading } = useTractsQAFile(id, { skip: !dataKey });

  const columns = useMemo(() => {
    if(!data) return undefined;
    const columns = data[dataKey]?.headers?.map((h, i) => (
      { title: h, dataIndex: `${i}` }
    ));
    if(dataKey === 'qc_fib') {
      columns.splice(1, 0, { title: 'R', dataIndex: 'R' });
    }
    return columns;
  }, [data, dataKey]);

  const dataSource = useMemo(() => {
    if(!data) return undefined;
    if(dataKey !== 'qc_fib') return data[dataKey].data;
    return data[dataKey].data.map(d => ({
      ...d,
      R: data.R.find(n => n.name === d['0'])?.value
    }));
  }, [data, dataKey]);

  if(!data || isLoading) return <Spin/>;
  if(error) return "Error";

  return <div>
    <Table
      rowKey={(record) => record['0']}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      size='small'
    />
    {
      data[dataKey].outliers !== undefined &&
      <Typography.Text>
        Total outliers: {data[dataKey].outliers}
      </Typography.Text>
    }
  </div>

}

export default QATracts;
