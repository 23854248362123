import React, { useEffect, useCallback, useMemo } from 'react';
import { Select, Slider, Checkbox } from 'antd';
import { connect, useDispatch } from 'react-redux';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import {
  setRGBTransferFunctionPreset, setRGBTransferFunctionRange,
  setRGBTransferFunctionInterpolation, setOpacityRange, layerModified
} from '../../../../redux/viewer/viewer.slice';
import {
  selectVisualOptions,
  selectVolumeDataRange,
  selectRenderingEngine,
  selectSliceViewportIds,
  selectVolumeViewportIds
} from '../../../../redux/viewer/viewer.selectors';

const COLORMAP_PRESET_OPTIONS = [...vtkColorMaps.rgbPresetNames]
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  .map(name => ({ label: name, value: name }));

const SliceVisualizationSettings = ({id, vo, ...props}) => {
  const dispatch = useDispatch();
  const { renderingEngine, dataRange } = props;
  const { sliceViewportIds, volumeViewportIds } = props;

  const [ min, max ] = useMemo(() => {
    return dataRange || [0.0, 1.0]
  }, [dataRange]);

  const onSettingsChange = useCallback(() => {
    dispatch(layerModified(id));
  }, [dispatch, id]);

  useEffect(() => {
    renderingEngine?.renderViewports(sliceViewportIds);
  }, [renderingEngine, sliceViewportIds, vo]);

  return (<>
    Color map
    <Select
      variant='borderless'
      value={vo.preset}
      options={COLORMAP_PRESET_OPTIONS}
      onChange={value => {
        dispatch(setRGBTransferFunctionPreset(id, value));
        onSettingsChange();
      }}
    />
    Range
    <Slider range min={min} max={max} step={max > 2 ? 0.1 : 0.01}
      value={vo.mappingRange}
      onChange={range => dispatch(setRGBTransferFunctionRange(id, range))}
      onAfterChange={onSettingsChange}
    />
    Threshold
    <Slider range min={0.0} max={Math.max(Math.abs(min), Math.abs(max))} step={max > 2 ? 0.1 : 0.01}
      value={vo.opacityRange}
      onChange={range => dispatch(setOpacityRange(id, range))}
      onAfterChange={onSettingsChange}
    />
    Interpolation
    <Checkbox
      checked={vo.interpolate}
      onChange={e => {
        dispatch(setRGBTransferFunctionInterpolation(id, e.target.checked));
        renderingEngine.renderViewports(volumeViewportIds);
      }}
    >
    </Checkbox>
  </>);
}

const mapState = (state, { id }) => ({
  vo:                selectVisualOptions(state, id),
  renderingEngine:   selectRenderingEngine(state),
  dataRange:         selectVolumeDataRange(state, id),
  sliceViewportIds:  selectSliceViewportIds(state),
  volumeViewportIds: selectVolumeViewportIds(state)
});

export default connect(mapState, null)(SliceVisualizationSettings);
