import styled from 'styled-components';
import { Tabs } from 'antd';

const LogViewTabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0
  }
`;

const LogViewStyled = styled.div`
  height: 300px;
  max-height: 300px;
  font-family: monospace;
  background: black;
  color: lightgray;
  overflow: auto;
  padding: 4px;
  display: flex;

  .log-error {
    color: red;
    margin: auto;
  }
`

export { LogViewTabsStyled, LogViewStyled }
