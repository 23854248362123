import styled from 'styled-components';

const UploadStyled = styled.div`
  padding-bottom: 16px;

  .ant-btn > svg {
    margin-right: 8px;
  }
`;

export default UploadStyled;
