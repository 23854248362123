import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Typography } from 'antd';
import UploadForm from './UploadForm';
import UploadProgress from './UploadProgress';
import UploadStyled from './Upload.styled';
import { selectIsUploadStarted } from '../../../redux/upload/upload.selectors';
import { clear } from '../../../redux/upload/upload.slice';

const Upload = ({ isUploading, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clear());
  }, [dispatch]);

  return (<UploadStyled>
    <Typography.Title level={4}> Upload new dataset </Typography.Title>
    { isUploading
      ? <UploadProgress/>
      : <UploadForm/>
    }
  </UploadStyled>);
}

const mapState = (state, props) => ({
  isUploading: selectIsUploadStarted(state)
})

export default connect(mapState, null)(Upload);
