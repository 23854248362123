import React, { useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button, Dropdown, Typography, Tabs, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DatasetsStyled from './Datasets.styled';
import Patients from './Patients/Patients';
import Groups from './Groups/Groups';
import GroupDialog from './Groups/GroupDialog';
import {
  useDatasetsCount,
  useDeleteDatasets,
  useCreateGroup,
  useUpdateGroup
} from '../../../redux/datasets/datasets.api';
import {
  setSelectedDatasets, setEditGroup, setShowGroupDialog
} from '../../../redux/datasets/datasets.slice';
import {
  selectCheckedDatasets,
  selectEditedGroup,
  selectShowGroupDialog
} from '../../../redux/datasets/datasets.selectors';

const Datasets = ({ selectedDatasets, editGroup, showGroupDialog, ...props }) => {
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { data: datasetsCount } = useDatasetsCount();

  const [ createGroup, { isSuccess: groupCreated } ] = useCreateGroup();
  const [ updateGroup, { isSuccess: groupUpdated } ] = useUpdateGroup();
  const [ deleteDatasets, { isSuccess: datasetsDeleted } ] = useDeleteDatasets();

  const activeTab = useMemo(() => {
    return searchParams.get('tab') || 'patients';
  }, [searchParams]);

  useEffect(() => {
    if(groupCreated) message.success("New group successfully created");
  }, [groupCreated]);

  useEffect(() => {
    if(groupUpdated) message.success("Group successfully updated");
  }, [groupUpdated]);

  useEffect(() => {
    if(datasetsDeleted) message.success("Datasets successfully deleted");
  }, [datasetsDeleted]);

  return (<DatasetsStyled>
    <Typography.Title level={4}> Datasets </Typography.Title>
    <GroupDialog open={showGroupDialog}
      destroyOnClose={true}
      group={editGroup}
      onCancel={() => {
        dispatch(setEditGroup(undefined));
        dispatch(setShowGroupDialog(false));
      }}
      onOk={({ id, ...config }) => {
        if(editGroup && !editGroup.isNew) {
          updateGroup({ id, config });
        } else {
          createGroup(config);
        }
        dispatch(setSelectedDatasets([]));
        dispatch(setEditGroup(undefined));
        dispatch(setShowGroupDialog(false));
      }}
    />

    <Tabs activeKey={activeTab}
      tabBarExtraContent={activeTab === 'groups'
        ? { right: <>
            <Button
              icon={<FontAwesomeIcon icon={faUsers}/>}
              onClick={() => dispatch(setShowGroupDialog(true))}
            >
              Create new group
            </Button>
            <Button
              disabled={true}
              icon={<FontAwesomeIcon icon={faTrashCan}/>}
            >
              Delete
            </Button>
          </>}
        : { right: <>
            <Dropdown.Button
              disabled={selectedDatasets.length === 0}
              onClick={() => {
                dispatch(setEditGroup({
                  isNew: true,
                  editable: false,
                  patients: selectedDatasets
                }));
                dispatch(setShowGroupDialog(true));
              }}
              menu={{
                items: [{
                  label: 'Add to existing group',
                  icon: <FontAwesomeIcon icon={faPlus} />,
                }],
                onClick: () => {
                  dispatch(setEditGroup({
                    isNew: false,
                    editable: true,
                    patients: selectedDatasets
                  }))
                  dispatch(setShowGroupDialog(true));
                }
              }}
            >
              <FontAwesomeIcon icon={faUsers}/>
              Create group
            </Dropdown.Button>
            <Button
              icon={<FontAwesomeIcon icon={faTrashCan}/>}
              disabled={selectedDatasets.length === 0}
              onClick={() => deleteDatasets(selectedDatasets)}
            >
              Delete
            </Button>
          </>}
      }
      destroyInactiveTabPane={true}
      onChange={tab => setSearchParams({ tab })}
      items={[
        {
          key: 'patients',
          label: `Patients`,
          children: <Patients/>,
        },
        {
          key: 'groups',
          label: `Groups`,
          children: <Groups/>,
          disabled: !datasetsCount
        }
      ]}
    />
  </DatasetsStyled>);
}

const mapState = (state, props) => ({
  selectedDatasets: selectCheckedDatasets(state),
  showGroupDialog: selectShowGroupDialog(state),
  editGroup: selectEditedGroup(state)
});

export default connect(mapState, null)(Datasets);
