import styled from 'styled-components';
import { Radio } from 'antd';

const QASlicesPlotStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 16px;

    .ant-input-number {
      margin: 0 8px;
      min-width: 75px;
    }

    .ant-btn {
      margin-left: 8px;
    }

    svg {
      margin-right: 8px;
    }

    .ant-collapse {
      margin-left: auto;
    }

    .ant-collapse-item {
      .ant-collapse-expand-icon .anticon {
        top: calc(50% + 1px) !important;
      }
    }

    .ant-collapse-content {
      display: none;
    }

    .ant-select {
      margin: 0 8px;
      min-width: 100px;
    }

    .ant-slider {
      width: 100%;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;

export const QASlicesDisplay = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, 1fr);

  background-color: black;
  width: 100%;
  height: 100%;
`;

export const UnitsSelect = styled(Radio.Group)`
  display: flex;
  flex-direction: column;

  .ant-radio {
    scale: 80%;
    position: relative;
    top: 0px;
  }

  .ant-radio-wrapper {
    line-height: 12px;

    > span:last-child {
      position: relative;
      top: -4px;
      font-size: 12px;
    }
  }
`;

export default QASlicesPlotStyled;
