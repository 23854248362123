import styled from 'styled-components';

const CornerstoneViewportStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  border: ${props => props.isActive ? "1px solid #1890ff" : "1px solid gray"};

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 5px;
    font-weight: bold;
    color: #9ccef9;

    .ant-input-number {
      width: calc(2.5em + 22px);

      .ant-input-number-input {
        color: #9ccef9;
        font-weight: bold;
        padding: 0;
        padding-left: 0.7em;
      }

      .ant-input-number-handler-wrap {
        background: none;
      }

      .ant-input-number-handler {
        border: none;
        .anticon {
          color: #9ccef9;
        }
      }
    }

    .ant-select {
      color: #9ccef9;
      padding-right: 10px;
      width: min-content;

      .ant-select-arrow {
      }

      .ant-select-selection-item {
        color: #9ccef9;
        font-weight: bold;
      }

      .ant-select-selector {
        padding-left: 0;
        border-radius: 0;
        border-bottom: 1px solid transparent;
      }

      :hover {
        .ant-select-selector {
          border-radius: 0;
          border-bottom: 1px solid white !important;
        }

        .ant-select-arrow {
          color: white;
        }
      }
    }

    .ant-select-open {
      width: 105.25px;
    }
  }

  .tag {
    text-transform: uppercase;
  }

  .dir-top {
    margin-right: calc(50% - 0.15em);
  }

  .dir-left {
    margin: auto 0;
  }

  .dir-top,.dir-left {
    color: white;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dir-left, .dir-top {
    user-select: none;
  }

  .footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 2px;

    > span {
      user-select: none;
    }
    /* align-items: center; */

    /*span:last-child {
      margin-left: auto;
    }*/
  }
`;

export default CornerstoneViewportStyled;
