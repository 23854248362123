import styled from 'styled-components';
import { Collapse } from 'antd';

const SegmentationSettingsStyled = styled.div`
  .scroll-box {
    max-height: 154px;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .ant-radio-wrapper {
    color: white;
  }

  .filter-box {
    display: flex;
    align-items: center;

    .ant-btn {
      width: 16px;
      margin-right: 8px;
      color: #9ccef9;

      :hover {
        color: white;
      }
    }

    input::placeholder {
      color: lightgray;
    }
  }

  .empty-box {
    display: inline;
    color: lightgray;
    padding-left: 1px;

    > :first-child {
      margin-right: 8px;
    }
  }

  .ant-typography {
    color: white;
    max-width: 200px;
  }

  .ant-input-affix-wrapper {
    padding-left: 0px;
    padding-right: 0px;
    color: #9ccef9;

    > input {
      color: lightgray;
      border-bottom: 1px solid #9ccef9;
    }

    .ant-input-clear-icon {
      color: inherit;
    }
  }

  .ant-checkbox-wrapper {
    width: 100%;

    > span:last-child {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`;

const CollapsiblePanelStyled = styled(Collapse)`
  .ant-collapse-header {
    color: lightgray !important;

    .ant-collapse-expand-icon > span {
      right: 0 !important;
    }
  }

  .ant-collapse-header, .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const ColorPicker = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${props => `rgb(${props.color[0]}, ${props.color[1]}, ${props.color[2]})`};
  border: 1px solid white;
`;

export { ColorPicker, CollapsiblePanelStyled };
export default SegmentationSettingsStyled;
