import * as cornerstone from '@cornerstonejs/core';

const { WEB_WORKER_PROGRESS, VOLUME_CACHE_VOLUME_ADDED, VOLUME_LOADED } = cornerstone.Enums.Events;
const { imageIdToURI } = cornerstone.utilities;

const triggerLoadStartEvent = (imageId, bytesTotal) => {
  const url = imageIdToURI(imageId);
  cornerstone.triggerEvent(
    cornerstone.eventTarget,
    WEB_WORKER_PROGRESS,
    { imageId, url, loaded: 0, total: bytesTotal || 0, percentComplete: 0 }
  );
}

const triggerVolumeLoadedEvent = (volume) => {
  cornerstone.triggerEvent(
    cornerstone.eventTarget,
    VOLUME_LOADED,
    { volume }
  );
}

const triggerVolumeCacheAddedEvent = (volume) => {
  cornerstone.triggerEvent(
    cornerstone.eventTarget,
    VOLUME_CACHE_VOLUME_ADDED,
    { volume }
  );
}

// File fetcher bound to cornerstone events
const fetchUrl = (imageId) => {
  const url = imageIdToURI(imageId)
  return fetch(url).then(async rsp => {
    const bytesTotal = parseInt(rsp.headers.get('Content-Length'));
    triggerLoadStartEvent(imageId, bytesTotal);

    if(!bytesTotal) return rsp.arrayBuffer();

    const reader = rsp.body.getReader();

    let bytesLoaded = 0;
    let chunks = [];

    while(true) {
      const { done, value } = await reader.read();

      if(done) {
        break;
      }

      bytesLoaded += value.length;
      chunks.push(value);

      if(bytesTotal) {
        cornerstone.triggerEvent(
          cornerstone.eventTarget,
          WEB_WORKER_PROGRESS,
          {
            imageId,
            url,
            loaded: bytesLoaded,
            total: bytesTotal,
            percentComplete: Math.round(bytesLoaded / bytesTotal * 100)
          }
        );
      }

    }

    const buffer = new ArrayBuffer(bytesLoaded);
    const data = new Uint8Array(buffer);
    let offset = 0;
    chunks.forEach(chunk => {
      data.set(chunk, offset);
      offset += chunk.length;
    });

    return buffer;
  })
}

const fetchLocalFile = (imageId, file) => {
  const url = `file:///${file.name}`;

  triggerLoadStartEvent(imageId, file.size);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = reject;
    reader.onprogress = ({ loaded, total }) => {
      cornerstone.triggerEvent(
        cornerstone.eventTarget,
        WEB_WORKER_PROGRESS,
        {
          imageId, url, loaded, total,
          percentComplete: Math.round(loaded / total * 100)
        }
      );
    }

    reader.readAsArrayBuffer(file);
  });
}

const fetchData = (imageId, options) => {
  return options.file
    ? fetchLocalFile(imageId, options.file)
    : fetchUrl(imageId);
}

const elapsed = async (fn) => {
  const start = new Date();
  const result = await fn();
  const end = new Date();
  const elapsed = end.getTime() - start.getTime();

  return {
    ...result,
    metadata: {
      ...(result.metadata || {}),
      parsingTime: (result.metadata?.parsingTime || 0) + elapsed
    }
  };
}

export {
  fetchLocalFile, fetchUrl, fetchData, elapsed,
  triggerVolumeLoadedEvent, triggerVolumeCacheAddedEvent
};
