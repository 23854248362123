const DATA_TYPE = Object.freeze({
  STATIC:       0,
  FUNCTIONAL:   1,
  TRACTOGRAPHY: 2,
  THICKNESS:    3,
  SEGMENTATION: 4,
  CONN_GROUP:   5,
  MESH:         6
});

export default DATA_TYPE;
