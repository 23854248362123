import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from 'antd';
import UserList from './UserList';
import FeedbackList from './FeedbackList';

const AdminPage = (props) => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const activeTab = useMemo(() => {
    return searchParams.get('tab') || 'users';
  }, [searchParams]);

  return <div>
    <Tabs activeKey={activeTab}
      destroyInactiveTabPane={true}
      onChange={tab => setSearchParams({ tab })}
      items={[
        {
          key: 'users',
          label: `Users`,
          children: <UserList/>,
        },
        {
          key: 'feedback',
          label: `Feedback`,
          children: <FeedbackList/>,
        }
      ]}
    />
  </div>
}

export default AdminPage
