import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import { useUser } from '../../redux/api';
import HeaderStyled from './Header.styled';
import UserBadge from './UserBadge';
import { selectIsLoggedIn } from '../../redux/auth/auth.selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBrain,
  faDatabase,
  faFileArrowUp,
  faPlay,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../logo_header.png';

const items = [
  {
    key: 'run',
    icon: <FontAwesomeIcon icon={faPlay}/>,
    label: <Link to='/run'>Run</Link>
  },
  {
    key: 'analysis',
    icon: <FontAwesomeIcon icon={faBrain}/>,
    label: <Link to='/analysis'>Studies</Link>
  },
  {
    key: 'datasets',
    icon: <FontAwesomeIcon icon={faDatabase}/>,
    label: <Link to='/datasets'>Datasets</Link>
  },
  {
    key: 'upload',
    icon: <FontAwesomeIcon icon={faFileArrowUp}/>,
    label: <Link to='/upload'>Upload</Link>
  }
];

const findLocation = (pathname, items) => {
  const path = pathname.split('/');
  if(path.length < 2) return ['analysis'];
  const i = items.find(i => i.key === path[1]);
  return i ? [i.key] : ['analysis'];
}

const Header = ({ isLoggedIn, ...props }) => {
  const { pathname } = useLocation();

  const { data: user, isLoading: isUserLoading } = useUser(undefined, { skip: !isLoggedIn });
  const menuItems = useMemo(() => {
    return user?.isAdmin
      ? [ ...items, {
          key: 'admin',
          icon: <FontAwesomeIcon icon={faUserTie}/>,
          label: <Link to='/admin'>Admin</Link>
        }]
      : items
  }, [user]);

  const activeKey = findLocation(pathname, menuItems);

  if(pathname.includes('/results')) return null;

  return (<HeaderStyled>
    <Row justify='center'>
      <Col xl={18} lg={20} xs={22}>
        <img src={logo} width={32} height={32} alt="Logo"/>
        <span className='app-title'>
          NeuroGraphix
        </span>
        { isLoggedIn && !isUserLoading &&
          <Menu mode='horizontal' theme='dark'
            disabledOverflow
            defaultSelectedKeys={activeKey}
            selectedKeys={activeKey}
            items={menuItems}
          />
        }
        { isLoggedIn && !isUserLoading &&
          <UserBadge isLoggedIn={isLoggedIn}/>
        }
      </Col>
    </Row>
  </HeaderStyled>);
}

const mapState = (state) => ({
  isLoggedIn: selectIsLoggedIn(state),

})

export default connect(mapState, null)(Header);
