import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import {
  useDeleteGroup,
  useGroupsQuery
} from '../../../../redux/datasets/datasets.api';
import {
  setEditGroup,
  setShowGroupDialog
} from '../../../../redux/datasets/datasets.slice';
import { STATUS_ICON } from '../../../../constants';
import GroupsStyled from './Groups.styled';

const Groups = (props) => {
  const dispatch = useDispatch();
  const [ deleteGroup ] = useDeleteGroup();

  const { data: groups, isLoading } = useGroupsQuery();

  const { data, total } = useMemo(() => {
    return groups
      ? { ...groups, data: groups.data.map(i => ({ ...i, key: i.id })) }
      : {}
  }, [groups]);

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name < b.name,
      sortDirections: [ 'ascend', 'descend', 'ascend' ],
      render: (text, record) =>
        <Button type="link"
          onClick={() => {
            dispatch(setEditGroup({ ...record, editable: true, isNew: false }));
            dispatch(setShowGroupDialog(true));
          }}
        >
          { STATUS_ICON[record.status] }
          { text?.length ? text : <i>{'<unnamed>'}</i> }
        </Button>
    },
    {
      title: 'Entries',
      dataIndex: 'patients',
      render: (_, record) => record.patients.length
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: (a, b) => a.created - b.created,
      sortDirections: [ 'ascend', 'descend', 'ascend' ],
      defaultSortOrder: 'descend',
      render: (text) => new Date(Number(text) * 1000).toLocaleString()
    },
    {
      title: 'Data',
      dataIndex: 'tags',
      render: (_, record) => {
         return record.tags.map(t => <Tag key={t}>{t}</Tag>)
      }
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (<div className="actions-box">
        <Tooltip title="Delete" mouseEnterDelay={0.5}>
          <FontAwesomeIcon icon={faTrashCan}
            onClick={() => deleteGroup(record.id)}
          />
        </Tooltip>
      </div>)
    }
  ], [deleteGroup, dispatch]);

  return (<GroupsStyled>
    <Table
      pagination={false}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      rowSelection={{}}
    />
  </GroupsStyled>);
}

export default Groups;
