import styled from 'styled-components';

const FeedbackDialogStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  min-width: 300px;

  .ant-btn {
    margin-top: 24px;
  }
`;

export default FeedbackDialogStyled;
