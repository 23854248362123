import React, { useEffect } from 'react';
import { Table, Typography, Image } from 'antd';
import {
  useFeedbackAccessToken,
  useFeedbackList,
  useSetFeedbackStatus
} from '../../../redux/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';

const STATUS_ICON = Object.freeze({
  new:     <FontAwesomeIcon icon={faCirclePlus} style={{color: 'darkgreen'}}/>,
  closed:  <FontAwesomeIcon icon={faCircleCheck} style={{color: 'gray'}}/>,
  invalid: <FontAwesomeIcon icon={faCircleXmark} style={{color: 'darkred'}}/>,
});

const columns = [
  {
    title: '#',
    dataIndex: 'id',
    sorter: (a, b) => a.id < b.id,
    defaultSortOrder: 'ascend',
    render: (value) => `#${value}`
  },
  {
    dataIndex: 'status',
    render: (value) => STATUS_ICON[value]
  },
  {
    title: 'Type',
    dataIndex: 'category',
    shouldCellUpdate: (a, b) => a.status !== b.status,
    render: (value, record) =>
      <Typography.Text strong={record.status === 'new'}>{value}</Typography.Text>
  },
  {
    title: 'Path',
    dataIndex: 'path',
    render: (value) => value.replace(/^\/fmri/, "")
  },
  {
    title: 'Message',
    dataIndex: 'message',
    render: (text) => text.substring(0, 36) + (text.length > 36 ? '...' : ''),
  },
  {
    title: 'User',
    dataIndex: 'user_name'
  },
  {
    title: 'Date',
    dataIndex: 'created',
    render: (value) => new Date(value * 1000).toLocaleString()
  }
];


const FeedbackList = (props) => {
  const { isSuccess: isTokenLoaded, refetch: refetchToken } = useFeedbackAccessToken(
    undefined, { refetchOnMountOrArgChange: true }
  );

  const { data, isLoading, isError } = useFeedbackList(
    undefined, {
      skip: !isTokenLoaded,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    const i = setInterval(() => refetchToken(), 14 * 60 * 1000)
    return () => clearInterval(i)
  }, [refetchToken]);

  const [ setFeedbackStatus ] = useSetFeedbackStatus();

  return <Table
    rowKey={record => record.id}
    expandable={{
      expandedRowRender: (record) => (
        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: "10px"
          }}
        >
          <Image
            width={200}
            src={"/feedback_data/" + record['image_file']}
          />
          <span style={{textWrap: "wrap", overflowWrap: "anywhere"}}>
            {record.message}
          </span>
        </div>
      ),
      onExpand: (open, record) => {
        if(open && record.status === 'new') {
          setFeedbackStatus({ id: record.id, status: 'active' })
        }
      },
      expandRowByClick: true,
    }}
    loading={isLoading}
    columns={columns}
    dataSource={data}
    pagination={false}
  />
}

export default FeedbackList;
