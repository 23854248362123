import { current } from '@reduxjs/toolkit';
import * as cornerstone from '@cornerstonejs/core';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import {
  SlicePlanesActor,
  VolumeSlicesActor,
  ThicknessActor,
  TractsSlicesActor,
  PointCloudActor,
  MeshActor,
  TRACTS_SLICE_MODE,
  THICKNESS_DISPLAY_MODE,
  THICKNESS_REPRESENTATION_MODE
} from '../../components/Main/Viewer/Actors';
import { DATA_TYPE, AXIS, CONN_GROUP_MODE, TRACTS_COLOR_MODE } from '../../constants';
import {
  selectVolumeViewportIds,
  selectSliceViewportIds,
  selectViewportIds
} from './viewer.selectors';
import {
  getLayer,
  findLayerIndex,
  forEachLayer,
  _deleteLayer,
  setLayerVolumeId,
} from './viewer.slice';
import { setProbeToolTargetId } from './toolManager';

const { cache } = cornerstone;

export const DEFAULT_TRACTS_SLICE_MODE = TRACTS_SLICE_MODE.VOXELIZED;
export const DEFAULT_TRACTS_QUALITY = 1;
export const DEFAULT_THICKNESS_MODE = THICKNESS_DISPLAY_MODE.SLICES;
export const DEFAULT_THICKNESS_REPRESENTATION = THICKNESS_REPRESENTATION_MODE.SURFACE;

const identity = i => i;

const isInitialized = (state) => {
  return state.layers.every(l => l.error || l.actor !== undefined || l.volumeId === undefined) && state.slicePlanes;
}

const isCameraInitialized = (state) => {
  return state.cameraPos.every(p => p !== undefined);
}

const clearLayer = (layer) => {
  const { ctf, sof, actor } = layer;
  actor?.delete();
  // ctf?.delete();
  // sof?.delete();
}

const clearScene = (state) => {
  if(state.renderingEngine) {
    state.renderingEngine.getViewports().forEach(viewport => {
      viewport.removeAllActors();
      // state.scene.renderingEngine.disableElement(viewport.id);
    });
  }

  forEachLayer(state, clearLayer)
}

const renderAllViewports = (state) => {
  const { renderingEngine } = state;
  renderingEngine.renderViewports(renderingEngine.getViewports().map(v => v.id));
}

const initVolumeViewport = (state, id) => {
  const v = state.renderingEngine.getViewport(id);

  state.slicePlanes.addToViewport(v);

  v.setCamera({
    position:         [-120, 120, 80],
    focalPoint:       [0, 0, 0],
    viewUp:           [-0.1, 0.1, 1.0],
    viewPlaneNormal:  [-0.6, 0.6, 0.4]
  })

  forEachLayer(state, ({ actor }) => {
    actor?.modified();
  });
}

// ----------------------------------------------------------------------------

const updateTractography = (state, index, sliceMode) => {
  getLayerActors(state, index).forEach(actor => {
    actor.modified();
    actor.setSlices(state.cameraPos, true);
  });

  switch(sliceMode) {
  case TRACTS_SLICE_MODE.VOXELIZED:
  case TRACTS_SLICE_MODE.DISABLED:
  case TRACTS_SLICE_MODE.CLUSTERED:
    rebuildScene(state);
    break
  default:
    renderAllViewports(state);
  }
}

// ----------------------------------------------------------------------------

const getVolumeDataScaling = (layer) => {
  return cache.getVolume(layer.volumeId)?.metadata?.dataRangeScaling || identity;
}

const getVolumeDataRange = (layer) => {
  return cache.getVolume(layer.volumeId)?.metadata?.dataRange;
}

const setInterpolation = (prop, on) => {
  if(!prop.setInterpolationType) return;
  if(on) prop.setInterpolationTypeToLinear();
  else prop.setInterpolationTypeToNearest();
}

const setScalarOpacityRange = (sof, ranges, value = 1.0, zeroPoint) => {
  sof.removeAllPoints();
  if(ranges[0][0] < 0) ranges[0][0] = 0;
  for(let i = 0; i < ranges.length; ++i) {
    sof.addPointLong(ranges[i][0], value, 1.0, 1.0);
    sof.addPointLong(ranges[i][1], 0, 1.0, 1.0);
  }
}

const resetScalarOpacityRange = (state, idx) => {
  const { options } = getLayer(state, idx);
  getLayerGroup(state, idx).forEach(l => {
    if(!l.volumeId) return;
    const dataRange = getVolumeDataRange(l);
    if(!dataRange) return;

    const scale = getVolumeDataScaling(l);
    let [ min, max ] = l.options.opacityRange;
    if(isNaN(min)) min = dataRange[0];
    if(isNaN(max)) max = dataRange[1];

    let usedRange = [];
    if(dataRange[0] < 0) {
      const _min = Math.min(Math.abs(min), Math.abs(max));
      const _max = Math.max(Math.abs(min), Math.abs(max));
      if(min > 0) {
        usedRange.push(
          [-_max, -_min].map(scale),
          [ _min,  _max].map(scale)
        );
      } else {
        usedRange.push([-max, max].map(scale));
      }
    } else {
      usedRange.push([min, max].map(scale));
    }

    setScalarOpacityRange(l.sof, usedRange, options.opacity);
  });
}

const resetOpacity = (state, idx) => {
  const { type, options, sof } = getLayer(state, idx);
  switch(type) {
  case DATA_TYPE.TRACTOGRAPHY:
  case DATA_TYPE.THICKNESS:
  case DATA_TYPE.CONN_GROUP:
    getLayerActors(state, idx).forEach(actor => actor?.setOpacity(options.opacity));
    break;
  case DATA_TYPE.SEGMENTATION:
    sof.removeAllPoints();
    sof.addPointLong(0, 0.0, 0.5, 1.0);
    Object.entries(options.visibleScalars).forEach(([k, v]) => {
      const value = parseInt(k, 10);
      if(value === 0) return;

      const opacity = v ? options.opacity : 0.0;
      sof.addPointLong(value, opacity, 0.5, 1.0);
    });
    return;
  default:
    break;
  }

  resetScalarOpacityRange(state, idx);
}

const resetMappingRange = (state, idx) => {
  const layer = getLayer(state, idx);
  const { ctf, options } = layer;
  const scale = getVolumeDataScaling(layer);
  ctf.setMappingRange(...options.mappingRange.map(scale));
}

const resetInterpolation = (state, idx) => {
  const { renderingEngine } = state;
  const { volumeId, actor, options } = getLayer(state, idx);
  const on = options.interpolate;

  selectSliceViewportIds({ viewer: state }).forEach(v => {
    const actor = renderingEngine.getViewport(v).getActor(volumeId)?.actor;
    if(actor) setInterpolation(actor.getProperty(), on);
  });

  actor.setProperty(p => setInterpolation(p, on));
}

const initColorFunctions = (state, layer) => {
  if(!layer || !Object.keys(layer.options).length) return;
  if(layer.type === DATA_TYPE.MESH) return;

  const { preset, mappingRange, opacityRange } = layer.options;
  const { metadata } = cache.getVolume(layer.volumeId);
  const scale = metadata?.dataRangeScaling || identity;
  const unscale = metadata?.dataRangeUnscale || identity;

  if(metadata?.lookupTable) {
    layer.ctf.delete();
    layer.ctf = metadata.lookupTable;
  } else {
    if(preset) {
      layer.ctf.applyColorMap(vtkColorMaps.getPresetByName(preset));
    }

    if(mappingRange && !layer.resetMappingRange) {
      layer.ctf.setMappingRange(...mappingRange.map(scale));
    } else if(metadata) {
      let range = [ metadata.minPixelValue, metadata.maxPixelValue ];
      let [ min, max ] = range.map(unscale);
      if(min < 0) {
        const v = Math.min(Math.abs(min), Math.abs(max));
        range = [ -v, v ].map(scale);
      }
      layer.ctf.setMappingRange(...range);
      layer.options.mappingRange = range.map(unscale);
      layer.resetMappingRange = false;
    }
  }

  if(metadata?.lookupTableAnnotations) {
    layer.options.visibleScalars = {};
    metadata.lookupTableAnnotations.forEach(a => {
      layer.options.visibleScalars[a.index] = true;
    });
  }

  if(metadata?.scalarOpacity) {
    layer.sof.delete();
    layer.sof = metadata.scalarOpacity;
  } else {
    layer.sof.setClamping(false);
    if(opacityRange) {
      let [ min, max ] = opacityRange.map(scale);
      if(isNaN(min)) min = metadata.minPixelValue;
      if(isNaN(max)) max = metadata.maxPixelValue;
      layer.options.opacityRange = [unscale(min), unscale(max)];
      layer.options.opacity = layer.options.opacity || 1.0;
      resetScalarOpacityRange(state, findLayerIndex(state, layer));
      // console.log(layer.volumeId, min, max)
      // setScalarOpacityRange(layer.sof, [[min, max]], layer.options?.opacity || 1.0);
    } else if(metadata) {
      setScalarOpacityRange(layer.sof, [[0.01, metadata.maxPixelValue ]], layer.options?.opacity || 1.0);
      layer.options.opacityRange = [0.01, unscale(metadata.maxPixelValue)];
    }
  }
}

const initActor = (layer) => ({ volumeActor, volumeId }) => {
  const { ctf, sof, options, visible } = layer;

  volumeActor.getProperty().setScalarOpacity(0, sof);
  volumeActor.getProperty().setRGBTransferFunction(0, ctf);
  // volumeActor.getMapper().setBlendModeToAdditiveIntensity();
  volumeActor.getMapper().setBlendModeToMaximumIntensity();

  if(options?.interpolate) {
    volumeActor.getProperty().setInterpolationTypeToLinear();
  } else {
    volumeActor.getProperty().setInterpolationTypeToNearest();
  }

  volumeActor.setVisibility(visible);
}

const getLayerGroup = (state, index) => {
  const layer = getLayer(state, index);
  if(!layer.children) return [layer];

  const group = [layer];
  for(let i = 0; i < layer.children.length; ++i) {
    const idx = index instanceof Array ? [...index, i] : [index, i];
    group.push(...getLayerGroup(state, idx));
  }

  return group;
}

const getLayerActors = (state, index) => {
  return getLayerGroup(state, index).filter(l => l.actor).map(l => l.actor);
}

const getLayerVolumes = (state, index) => {
  return getLayerGroup(state, index).map(l => l.volumeId).filter(id => !!id);
}

// -- Scene state -------------------------------------------------------------

const selectCornerstoneActors = (state) => {
  const layers = [];
  forEachLayer(state, l => {
    if(!l.error && !!l.volumeId && (l.hasColorSettings || l.type === DATA_TYPE.SEGMENTATION) && cache.getVolume(l.volumeId)?.isReady) {
      layers.push({
        volumeId: l.volumeId,
        visible: l.visible,
        callback: initActor(l)
      })
    }
  });

  return layers;
}

const selectPolyDataActors = (state) => {
  const layers = [];
  // TODO: only two level trees are currently supported
  for(let i = 0; i < state.layers.length; ++i) {
    getLayerGroup(state, i).forEach(l => {
      if(!l.error && l.actor && (l.type === DATA_TYPE.TRACTOGRAPHY || l.type === DATA_TYPE.CONN_GROUP)) {
        layers.push({
          actor: l.actor,
          visible: l.visible && state.layers[i].visible
        });
      }
    });
  }

  return layers;
}

const createActor = (state, layer) => {
  if(!layer.volumeId || !cache.getVolume(layer.volumeId)) return null;

  let actor = null;
  const { renderingEngine } = state;
  const { type, volumeId, ctf, sof, hasColorSettings, options } = current(layer);

  const volumeViewportIds = selectVolumeViewportIds({ viewer: state });

  switch(type) {
  case DATA_TYPE.TRACTOGRAPHY: {
    actor = new TractsSlicesActor(renderingEngine, volumeId, layer.options.sliceMode);
    volumeViewportIds.forEach(id => actor.addToVolumeViewport(id));
    break;
  }
  case DATA_TYPE.THICKNESS: {
    if(layer.options.thicknessMode === undefined) {
      layer.options.thicknessMode = DEFAULT_THICKNESS_MODE;
    }
    if(layer.options.representationMode === undefined) {
      layer.options.representationMode = DEFAULT_THICKNESS_REPRESENTATION;
    }

    actor = new ThicknessActor(renderingEngine, volumeId, layer.options.thicknessMode);
    actor.setRGBTransferFunction(ctf);
    volumeViewportIds.forEach(id => actor.addToViewport(id));
    break;
  }
  case DATA_TYPE.SEGMENTATION: {
/*
    const { lookupTable, scalarOpacity } = cache.getVolume(volumeId).metadata;
    const actor = new VolumeSlicesActor(renderingEngine, volumeId);
    actor.setRGBTransferFunction(lookupTable || ctf);
    actor.setScalarOpacity(scalarOpacity || sof);
    actor.addToViewport(VIEWPORT_ID.VOLUME);
    actors.push(actor);
*/
    break;
  }
  case DATA_TYPE.CONN_GROUP: {
    actor = new PointCloudActor(
      renderingEngine, volumeId, options.p || 1.0, options.roi || 0
    );
    volumeViewportIds.forEach(id => actor.addToVolumeViewport(id));
    break;
  }
  case DATA_TYPE.MESH: {
    actor = new MeshActor(renderingEngine, volumeId);
    volumeViewportIds.forEach(id => actor.addToVolumeViewport(id));
    break;
  }
  default:
    break;
  }

  if(hasColorSettings && type !== DATA_TYPE.THICKNESS) {
    actor = new VolumeSlicesActor(renderingEngine, volumeId);
    actor.setRGBTransferFunction(ctf);
    actor.setScalarOpacity(sof);
    volumeViewportIds.forEach(id => actor.addToViewport(id));
  }

  return actor;
}

const reorderActors = (state) => {
  const volumeViewportIds = selectVolumeViewportIds({ viewer: state });

  forEachLayer(state, ({ actor }) => {
    volumeViewportIds.forEach(id => actor?.removeFromViewport(id));
  });

  forEachLayer(state, ({ actor }) => {
    volumeViewportIds.forEach(id => actor?.addToViewport(id));
  });
}

const updateLayerVisibity = (state, idx) => {
  const { renderingEngine } = state;
  const { volumeId, visible, type, options, actor } = getLayer(state, idx);

  if(visible && actor) {
    actor.setSlices(state.cameraPos);
  }

  if(type === DATA_TYPE.TRACTOGRAPHY || (type === DATA_TYPE.CONN_GROUP && options.mode === CONN_GROUP_MODE.POINT_CLOUD)) {
    // this is due to a bug in cornerstone/vtk
    rebuildScene(state);
    return;
  }

  const sliceViewportIds = selectSliceViewportIds({ viewer: state });

  getLayerGroup(state, idx).forEach(l => {
    sliceViewportIds.forEach(v => {
      const actorEntry = renderingEngine.getViewport(v).getActors().find(
        a => a.uid === l.volumeId
      );

      if(actorEntry) actorEntry.actor.setVisibility(visible);
    });

    l.actor?.setVisibility(visible);
  });


  renderingEngine.renderViewports(selectViewportIds({ viewer: state }));
}

const rebuildScene = (state, resetCamera = false) => {
  if(!isInitialized(state)) return;

  const { renderingEngine } = state;

  const sliceViewportIds = selectSliceViewportIds({ viewer: state });
  const saveCameraIds = state.viewports.filter(v => v.axis !== AXIS.VOLUME && !v.recreated).map(v => v.id);

  const cams = saveCameraIds.map(id =>
    renderingEngine.getViewport(id).getCamera()
  );

  const curState = current(state);
  const polyDataActors = selectPolyDataActors(curState);
  const sliceViewports = curState.viewports.filter(v => v.axis !== AXIS.VOLUME);

  cornerstone.setVolumesForViewports(
    renderingEngine,
    selectCornerstoneActors(curState),
    sliceViewportIds
  ).then(() => {
    polyDataActors.forEach(a => {
      if(a.visible) {
        a.actor.addToSliceViewports(sliceViewports);
        a.actor.modified();
      }
    });

    if(!resetCamera) {
      saveCameraIds.forEach((v, i) => {
        renderingEngine.getViewport(v).setCamera(cams[i]);
      });
    }

    renderingEngine.renderViewports(sliceViewportIds);
  });

  state.layers.forEach(({ visible, hidden }, i) => {
    if(hidden) return;
    getLayerGroup(state, i).forEach(l => {
      const { actor, visible: lVisible } = l;
      actor?.setVisibility(visible && lVisible);
    });
  });

  state.viewports.forEach(v => v.recreated = false);

  renderingEngine.renderViewports(selectVolumeViewportIds({ viewer: state }));
};

// -- Reducers ----------------------------------------------------------------

const sceneReducers = {
  initializeScene: (state, action) => {
    if(isInitialized(state)) return;
    if(!state.layers || !state.layers[0]?.volumeId) return;
    state.initStartTime = new Date().getTime();
    state.slicePlanes = new SlicePlanesActor(state.layers[0].volumeId);

    selectVolumeViewportIds({ viewer: state }).forEach(id =>
      initVolumeViewport(state, id)
    );

    // state.slicePlanes.addToViewport(volumeViewport);

    /*volumeViewport.setCamera({
      position:         [-120, 120, 80],
      focalPoint:       [0, 0, 0],
      viewUp:           [-0.1, 0.1, 1.0],
      viewPlaneNormal:  [-0.6, 0.6, 0.4]
    });
*/
    rebuildScene(state, true);
  },
  updateScene: (state, action) => {
    if(!isInitialized(state)) return;
    forEachLayer(state, ({ actor }) => actor?.setSlices(state.cameraPos));
    state.slicePlanes.setSlices(state.cameraPos);
    rebuildScene(state);
  },
  updateCameraPos: {
    reducer: (state, action) => {
      const { axis, pos } = action.payload;
      state.cameraPos[axis] = pos;
      if(!isInitialized(state) || !isCameraInitialized(state)) return;

      forEachLayer(state, ({ actor, visible }) => {
        if(visible) actor?.setSlices(state.cameraPos);
      });
      state.slicePlanes.setSlices(state.cameraPos);

      state.renderingEngine.renderViewports(
        selectVolumeViewportIds({ viewer: state })
      );
    },
    prepare: (axis, pos) => ({ payload: { axis, pos }})
  },
  setTractographySliceMode: {
    reducer: (state, action) => {
      if(!isInitialized(state)) return;

      const { index, mode } = action.payload;
      const { options, children } = getLayer(state, index);

      if(options.sliceMode === mode) return;

      getLayerActors(state, index).forEach(actor => {
        actor.setTractsMode(mode);
        actor.setSlices(state.cameraPos);
      });

      if(options.clusters) {
        if(mode !== TRACTS_SLICE_MODE.CLUSTERED) {
          children?.forEach(l => l.visible = false);
        } else {
          Object.entries(options.visibleClusters).forEach(([k, v]) => {
              children[k].visible = v;
          });
        }
      }

      options.sliceMode = mode;

      if(mode === TRACTS_SLICE_MODE.CLUSTERED && options.colorMode !== TRACTS_COLOR_MODE.COLORMAP) {
        options.colorMode = TRACTS_COLOR_MODE.COLORMAP;
        getLayerVolumes(state, index).forEach(volumeId => {
          cache.getVolume(volumeId)?.setColorMode(options.colorMode);
        });

        updateTractography(state, index, options.sliceMode);
      } else {
        rebuildScene(state);
      }
    },
    prepare: (index, mode) => ({ payload: { index, mode }})
  },
  setTractographyColorMode: {
    reducer: (state, action) => {
      const { index, mode } = action.payload;
      const { options } = getLayer(state, index);

      options.colorMode = mode;

      getLayerVolumes(state, index).forEach(volumeId => {
        cache.getVolume(volumeId)?.setColorMode(mode);
      });

      updateTractography(state, index, options.sliceMode);

    },
    prepare: (index, mode) => ({ payload: { index, mode }})
  },
  setTractographyQuality: {
    reducer: (state, action) => {
      if(!isInitialized(state)) return;

      const { index, quality } = action.payload;
      const { options } = getLayer(state, index);

      options.tractsQuality = quality;

      getLayerVolumes(state, index).forEach(volumeId => {
        cache.getVolume(volumeId)?.setQuality(quality);
      });

      updateTractography(state, index, options.sliceMode);
    },
    prepare: (index, quality) => ({ payload: { index, quality }})
  },
  setTractographyColored: {
    reducer: (state, action) => {
      const { index, colored } = action.payload;
      const { options } = getLayer(state, index);

      options.colored = colored;

      getLayerVolumes(state, index).forEach(volumeId => {
        cache.getVolume(volumeId)?.setColor(
          !colored && current(options.groupData?.colors[volumeId])
        );
      });

      updateTractography(state, index, options.sliceMode);
    },
    prepare: (index, colored) => ({ payload: { index, colored }})
  },
  setTractsVisible: {
    reducer: (state, action) => {
      const { index, values, visible } = action.payload;

      const { options, volumeId, children } = getLayer(state, index);

      values.forEach(v => {
        options.visibleClusters[v] = visible;
        if(children && options.sliceMode === TRACTS_SLICE_MODE.CLUSTERED) {
          children[v].visible = visible;
        }
      });

      cache.getVolume(volumeId)?.setVisibleClusters(
        Object.entries(options.visibleClusters)
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
      )

      updateTractography(state, index, options.sliceMode);
    },
    prepare: (index, values, visible) => ({ payload: { index, values, visible }})
  },
  setThicknessMode: {
    reducer: (state, action) => {
      if(!isInitialized(state)) return;

      const { index, mode } = action.payload;
      const { actor, options } = getLayer(state, index);

      if(!actor || options.thicknessMode === mode) return;

      actor.setDisplayMode(mode);
      options.thicknessMode = mode;
      state.renderingEngine.renderViewports(selectVolumeViewportIds({ viewer: state }));
    },
    prepare: (index, mode) => ({ payload: { index, mode }})
  },
  setThicknessRepresentationMode: {
    reducer: (state, action) => {
      if(!isInitialized(state)) return;

      const { index, mode } = action.payload;
      const { actor, options } = getLayer(state, index);

      if(!actor || options.representationMode === mode) return;

      actor.setRepresentation(mode);
      options.representationMode = mode;
      state.renderingEngine.renderViewports(selectVolumeViewportIds({ viewer: state }));
    },
    prepare: (index, mode) => ({ payload: { index, mode }})
  },
  setRGBTransferFunctionPreset: {
    reducer: (state, action) => {
      const { id, rgbTemplate } = action.payload;
      const preset = vtkColorMaps.getPresetByName(rgbTemplate)
      const layer = getLayer(state, id);
      layer.options.preset = rgbTemplate;
      layer.ctf.applyColorMap(preset);
      resetMappingRange(state, id);
    },
    prepare: (id, rgbTemplate) => ({ payload: { id, rgbTemplate }})
  },
  setRGBTransferFunctionRange: {
    reducer: (state, action) => {
      const { id, range } = action.payload;
      getLayer(state, id).options.mappingRange = range;
      resetMappingRange(state, id);
    },
    prepare: (id, range) => ({ payload: { id, range }})
  },
  setRGBTransferFunctionInterpolation: {
    reducer: (state, action) => {
      const { index, on } = action.payload;
      getLayer(state, index).options.interpolate = on;
      resetInterpolation(state, index);
    },
    prepare: (index, on) => ({ payload: { index, on }})
  },
  setOpacityRange: {
    reducer: (state, action) => {
      const { index, range } = action.payload;
      getLayer(state, index).options.opacityRange = range;
      resetScalarOpacityRange(state, index);
    },
    prepare: (index, range) => ({ payload: { index, range }})
  },
  setLayerOpacity: {
    reducer: (state, action) => {
      const { index, value } = action.payload;
      getLayerGroup(state, index).forEach(l => l.options.opacity = value);
      resetOpacity(state, index);
      state.renderingEngine.renderViewports(
        selectSliceViewportIds({ viewer: state })
      );
    },
    prepare: (index, value) => ({ payload: { index, value }})
  },
  setScalarValuesVisible: {
    reducer: (state, action) => {
      const { index, values, visible } = action.payload;
      const { sof, options } = getLayer(state, index);
      const { opacity, visibleScalars } = options;

      values.forEach(value => {
        sof.removePoint(value);
        sof.addPointLong(value, visible ? opacity : 0, 0.5, 1.0);
        visibleScalars[value] = visible;
      });

      state.renderingEngine.renderViewports(
        selectSliceViewportIds({ viewer: state })
      );
    },
    prepare: (index, values, visible) => ({ payload: { index, values, visible }})
  },
  setFunctionalPValue: {
    reducer: (state, action) => {
      const { index, p } = action.payload;
      const { options, actor } = getLayer(state, index);
      options.p = p;
      actor?.setPValue(p);
    },
    prepare: (index, p) => ({ payload: { index, p }})
  },
  setFunctionalGroupModeROI: {
    reducer: (state, action) => {
      const { index, roi } = action.payload;
      const layer = getLayer(state, index);
      layer.options.roi = roi;
      if(layer.options.mode === CONN_GROUP_MODE.POINT_CLOUD) {
        layer.actor?.setROI(roi);
      } else {
        setLayerVolumeId(state, [index, 0], layer.options.roiVolumeIds[roi], true);
        resetMappingRange(state, [index, 0]);
        resetScalarOpacityRange(state, [index, 0]);
        rebuildScene(state)
      }
    },
    prepare: (index, roi) => ({ payload: { index, roi }})
  },
  setFunctionalFilterArray: {
    reducer: (state, action) => {
      const { index, arrayIndex } = action.payload;
      const { options, actor } = getLayer(state, index);
      options.filterArray = arrayIndex;
      actor?.setFilterArray(arrayIndex);
    },
    prepare: (index, arrayIndex) => ({ payload: { index, arrayIndex }})
  },
  setFunctionalGroupDisplayMode: {
    reducer: (state, action) => {
      const { index, mode } = action.payload;
      const layer = getLayer(state, index);
      const { options } = layer;

      options.mode = mode;

      if(mode === CONN_GROUP_MODE.HEAT_MAP) {
        _deleteLayer(state, layer);

        const newVolumeId = options.roiVolumeIds[options.roi];
        const newIndex = [index, 0];
        setLayerVolumeId(state, newIndex, newVolumeId, true);
        resetMappingRange(state, newIndex);
        resetScalarOpacityRange(state, newIndex);
        if(getLayer(state, newIndex).actor) rebuildScene(state);

        if(state.probeTargetId === layer.volumeId) {
          setProbeToolTargetId(state, newVolumeId);
        }

      } else {
        setLayerVolumeId(state, [index, 0], undefined);
        layer.actor = createActor(state, layer);
        layer.actor.setROI(layer.options.roi);
        layer.actor.setFilterArray(layer.options.filterArray);
        layer.actor.setPValue(layer.options.p);
        layer.actor.setOpacity(layer.options.opacity);
        rebuildScene(state);
      }
    },
    prepare: (index, mode) => ({ payload: { index, mode }})
  }
};

export {
  initColorFunctions, initVolumeViewport, createActor,
  updateLayerVisibity, clearLayer, clearScene, rebuildScene, renderAllViewports,
  reorderActors, getLayerActors, getLayerVolumes
};
export default sceneReducers;
