import SliceActor from './SliceActor';
import { ActorsContainer } from './BaseActors';
import { AXIS } from '../../../../constants';

class VolumeSlicesActor extends ActorsContainer {
  constructor(renderingEngine, volumeId) {
    super([
      new SliceActor(renderingEngine, volumeId, AXIS.X),
      new SliceActor(renderingEngine, volumeId, AXIS.Y),
      new SliceActor(renderingEngine, volumeId, AXIS.Z)
    ]);
  }
}

export default VolumeSlicesActor;
