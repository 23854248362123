import styled from 'styled-components';

const UploadProgressStyled = styled.div`
  width: 55%;

  .ant-btn, .ant-alert {
    margin-top: 8px;
  }
`;

export default UploadProgressStyled;
