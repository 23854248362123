export const selectRunMode    = (state) => state.run.mode;
export const selectName       = (state) => state.run.name;
export const selectSubjects   = (state) => state.run.subjects;
export const selectIsParallel = (state) => state.run.parallel;
export const selectIsQA       = (state) => state.run.qa;
export const selectRunConfig  = (state) => state.run.config;

export const selectAnalyzersWithMissingData = (state) => state.run.missingTags;

export const selectIsValidConfig = (state) => {
  return state.run.tags !== undefined && !state.run.missingTags && Object.values(state.run.config).some(a => a.enabled)
}

export const selectCanParrallelize = (state) => {
  const enabled = Object.values(state.run.config).filter(v => v.enabled);
  return enabled.length > 1;
}
