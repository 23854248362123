import * as nifti from 'nifti-reader-js';
import {
  elapsed,
  fetchData,
  triggerVolumeLoadedEvent
} from '../cornerstone/fetcher';
import { createNiftiVolume } from './niftiVolume';

const niftiDataTypeConstructor = (typeCode) => {
  switch(typeCode) {
    case nifti.NIFTI1.TYPE_UINT8:   return Uint8Array;
    case nifti.NIFTI1.TYPE_UINT16:  return Uint16Array;
    case nifti.NIFTI1.TYPE_UINT32:  return Uint32Array;
    case nifti.NIFTI1.TYPE_INT8:    return Int8Array;
    case nifti.NIFTI1.TYPE_INT16:   return Int16Array;
    case nifti.NIFTI1.TYPE_INT32:   return Int32Array;
    case nifti.NIFTI1.TYPE_FLOAT32: return Float32Array;
    case nifti.NIFTI1.TYPE_FLOAT64: return Float64Array;
    case nifti.NIFTI1.TYPE_RGB:     return Uint8Array;
    case nifti.NIFTI1.TYPE_RGBA:    return Uint8Array;
    default:
      throw new Error(`Unsupported NIFTI data type code ${typeCode}`);
  }
}

const niftiHasFloatData = (typeCode) => {
  switch(typeCode) {
    case nifti.NIFTI1.TYPE_FLOAT32:
    case nifti.NIFTI1.TYPE_FLOAT64:
      return true;
    default:
      return false;
  }
}

const narrowDataRange = (data, minLimit, maxLimit) => {
  let min = 10e6, max = 10e-6;
  let secondMin = 10e6, secondMax = 10e-6;
  data.forEach((val) => {
    if(val < minLimit || val > maxLimit) return;

    if(val < min) {
      secondMin = min;
      min = val;
    } else if(val > max) {
      secondMax = max;
      max = val;
    }
  });

  console.log("NARROWED RANGE", [min, max], [secondMin, secondMax])
  return [secondMin, secondMax];

}

const extractBasicMetaData = async (volume) => {
  const TypedArray = niftiDataTypeConstructor(volume.header.datatypeCode);
  const view = new TypedArray(volume.data);

  let min = 10e6, max = -10e6;
  let secondMin = 10e6, secondMax = -10e6;
  view.forEach((val) => {
    // if(Math.abs(val) < min) min = val;
    // else if(Math.abs(val) > max) max = val;

    if(val < min) {
      secondMin = min;
      min = val;
    } else if(val > max) {
      secondMax = max;
      max = val;
    }
  });

  if(secondMin === 10e6) secondMin = min;
  if(secondMax === -10e6) secondMax = max;
/*
  console.log(Math.abs(secondMin / min), Math.abs(secondMax / max))

  while(Math.abs(secondMin / min) < 0.25 || Math.abs(secondMax / max) < 0.25) {
    min = secondMin;
    max = secondMax;
    [secondMin, secondMax] = narrowDataRange(view, min ,max);
    console.log(Math.abs(secondMin / min), Math.abs(secondMax / max))
  }
  */

  return {
    ...volume,
    data: view,
    metadata: {
      ...volume.metadata,
      xSize: volume.header.dims[1],
      ySize: volume.header.dims[2],
      zSize: volume.header.dims[3],
      minPixelValue: secondMin,
      maxPixelValue: secondMax,
      dataRange: [secondMin, secondMax],
      typeConstructor: TypedArray,
      isFloatData: niftiHasFloatData(volume.header.datatypeCode)
    }
  }
}

const calcWindowInfo = (volume) => {
  const { cal_min, cal_max } = volume.header;
  const { minPixelValue, maxPixelValue } = volume.metadata;

  let windowCenter, windowWidth;
  if(cal_min !== 0 || cal_max !== 0) {
    windowWidth = cal_max - cal_min;
    windowCenter = Math.trunc((cal_max - cal_min) / 2);
  } else {
    windowWidth = maxPixelValue - minPixelValue;
    windowCenter = Math.trunc((maxPixelValue - minPixelValue) / 2);
  }

  return {
    ...volume,
    metadata: { ...volume.metadata, windowWidth, windowCenter }
  };
}

const niftiLoader = (imageId, options) => {
  return fetchData(imageId, options).then(data => elapsed(() => {
    if(nifti.isCompressed(data)) {
      data = nifti.decompress(data);
    }

    if(!nifti.isNIFTI(data)) {
      throw new Error(`Invalid nifti file: ${imageId}`);
    }

    const header = nifti.readHeader(data);
    const image = nifti.readImage(header, data);

    return { imageId, header, data: image };
  }));
}

const fuzzyIsNull = (val) => Math.abs(val) < 10e-6;

const convertFloatToInteger = (volume, options) => {
  // NOTE: using 2^16 range for better discretization
  const intRange = Math.pow(2, 16) - 1;

  const floatMin = volume.metadata.minPixelValue;
  const floatMax = volume.metadata.maxPixelValue;

  const convertedData = new Uint16Array(volume.data.byteLength / volume.metadata.typeConstructor.BYTES_PER_ELEMENT);

  const linearTransform = (val) => {
    return Math.floor((val - floatMin) / ((floatMax - floatMin) || 1) * intRange);
  }

  volume.data.forEach((val, idx) => {
    // if(val < 0) convertedData[idx] = undefined;
    /*else*/ /*convertedData[idx] = fuzzyIsNull(val) ? linearTransform(0) : linearTransform(val);*/
    convertedData[idx] = fuzzyIsNull(val) ? linearTransform(Math.max(0, floatMin)) : linearTransform(val);
  });

  return {
    data: convertedData,
    metadata: {
      ...volume.metadata,
      minPixelValue: linearTransform(floatMin),
      maxPixelValue: linearTransform(floatMax),
      dataRangeScaling: linearTransform,//intRange / (floatMax - floatMin),
      dataRangeUnscale: val => val / intRange * (floatMax - floatMin) + floatMin,
      typeConstructor: Uint16Array
    }
  };
}

const convertData = (volume, options) => {
  return volume.metadata.isFloatData
    ? {
        ...volume,
        ...convertFloatToInteger(volume, options)
      }
    : volume;
}

/*
const registerVolumeMetaData = (volume, volumeId) => {
  const imageIds = Array(volume.metadata.zSize).fill().map(
    (_, i) => `nii:${volumeId}#z${i}`
  );

  registerVolume(volumeId, volume.metadata, imageIds);
  return volume;
}
*/

const getVolume = (imageId, options) => {
  // const volumeId = volumeIdFromImageId(imageId);
  return niftiLoader(imageId, options)
    .then(data => elapsed(() =>
      extractBasicMetaData(data)
      .then(volume => convertData(volume, options))
      .then(calcWindowInfo)
    ));
}

const niftiVolumeLoader = (volumeId, options) => {
  return {
    promise: getVolume(volumeId, options).then(volume => {
      console.log("NIFTI VOLUME", volumeId, volume)
      const niftiVolume = createNiftiVolume(volumeId, volume);
      triggerVolumeLoadedEvent(niftiVolume);
      return niftiVolume;
    }),
    cancel: undefined /* TODO */
  }
}

export default niftiVolumeLoader;
