import { fetchData } from '../cornerstone/fetcher';
import { ImageVolume } from '@cornerstonejs/core';
import * as mat4js from 'mat-for-js';

const loadMat = (volumeId, options) => {
  return fetchData(volumeId, options)
  .then(mat => {
    const { data } = mat4js.read(mat);
    console.log("MAT", data);

    if(!data.ROI) {
      throw Error("no ROI found in MAT file")
    }

    return data.ROI.reduce((acc, roi, idx) => {
      const vals = roi.F;
      const p = roi.p.map(p => 2 * Math.min(p, 1 - p));

      const ps = [p];
      if(data.pfdr) ps.push(data.pfdr[idx]);

      let center = roi.xyz2.filter(p => !isNaN(p[0]));
      center = center.reduce(
        (a, p) => a.map((v, i) => v + p[i]),
        [0, 0, 0]
      ).map(p => (p + 0.5) / center.length);

      const pos = roi.xyz2.map(p => p.map((v, i) => v - center[i]));

      const yMean = roi.y.reduce((a, y) => {
        return a.length === 0 ? [...y] : a.map((v, i) => v + y[i]);
      }, []).map(i => i / roi.y.length);

      const absVals = vals.filter(v => !isNaN(v)).map(Math.abs);

      return [...acc, {
        p: ps,
        y: roi.y,
        pos,
        yMean,
        vals,
        valMax: Math.max(...absVals),
        valMin: Math.min(...absVals),
        name: roi.names[idx],
        names: roi.names2
      }];
    }, []);
  });
}

const matLoader = (volumeId, options) => {
  return {
    promise: loadMat(volumeId, options).then(data => {
      const vol = new ImageVolume({
        volumeId,
        imageIds: [],
        dimensions: [1, 1, 1],
        spacing:    [1, 1, 1],
        origin:     [0, 0, 0],
        metadata:   {
          FrameOfReferenceUID: 'default',
          Modality: 'CT',
          PhotometricInterpretation: 'MONOCHROME2',
          PixelRepresentation: 0,
        },
        direction:   [1, 0, 0, 0, 1, 0, 0, 0, 1],
        scalarData:  new Uint8Array().fill(0),
        sizeInBytes: 1,
      });
      vol.data = data;
      vol.csRenderable = false;
      vol.isReady = true;

      return vol;
    }),
    cancel: undefined
  }
}

export default matLoader;
