import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Checkbox, Input, Button, Select, Typography, Tooltip } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import {
  faSquareCheck,
  faSquare,
  faSquarePlus
} from '@fortawesome/free-regular-svg-icons';
import {
  selectVisualOptions
} from '../../../../redux/viewer/viewer.selectors';
import {
  setTractsVisible,
  setTractographyColorMode
} from '../../../../redux/viewer/viewer.slice';
import { TRACTS_COLOR_MODE } from '../../../../constants';
import SegmentationSettingsStyled, {
  ColorPicker,
} from './SegmentationSettings.styled';

// TODO: move to utils
/*
const CollapsiblePanel = (props) => {
  return (<CollapsiblePanelStyled
    ghost
    defaultActiveKey={[0]}
    expandIconPosition='end'
  >
    <Collapse.Panel key={0} header={props.header}>
      {props.children}
    </Collapse.Panel>
  </CollapsiblePanelStyled>);
}
*/

const TractClustersSettings = ({ id, colorMode, clusters, visibleClusters, ...props }) => {
  const dispatch = useDispatch();

  const [ filterTerm, setFilterTerm ] = useState("");
  const [ filteredList, setFilteredList ] = useState([]);

  const clusterList = useMemo(() => {
    return clusters?.displayedNames.map((n, i) => ({ index: n.index, name: n.name, color: clusters.colors[n.index] }))
  }, [clusters]);

  console.log("CLUSTERS", clusterList)

  useEffect(() => {
    if(!clusterList) return;
    setFilteredList(filterTerm.length < 2
      ? clusterList
      : clusterList.filter(s => s.name.toLowerCase().includes(filterTerm))
    );
  }, [clusterList, filterTerm]);

  const selectItemsIcon = useMemo(() => {
    if(!visibleClusters) return faSquareCheck;
    return filteredList.every(i => visibleClusters[i.index])
      ? faSquareCheck
      : filteredList.some(i => visibleClusters[i.index])
        ? faSquarePlus
        : faSquare;
  }, [filteredList, visibleClusters]);

  const selectItemsCallback = useCallback(() => {
    const on = !filteredList.every(i => visibleClusters[i.index]);
    dispatch(setTractsVisible(id, filteredList.map(i => i.index), on));
  }, [id, dispatch, filteredList, visibleClusters]);

  if(!clusterList) return null;

  return (<>
    Color mode
    <Select
      variant='borderless'
      defaultValue={TRACTS_COLOR_MODE.NORMALS}
      options={[
        { label: 'Normals', value: TRACTS_COLOR_MODE.NORMALS  },
        { label: 'Color',   value: TRACTS_COLOR_MODE.COLORMAP }
      ]}
      value={colorMode}
      onChange={value => dispatch(setTractographyColorMode(id, value))}
    />
    <SegmentationSettingsStyled className="wide-box">
      <div className="filter-box">
        <Button type='text'
          icon={<FontAwesomeIcon icon={selectItemsIcon}/>}
          onClick={selectItemsCallback}
        />
        <Input allowClear
          variant="borderless"
          placeholder="Type to filter ..."
          onChange={e => setFilterTerm(e.target.value?.toLowerCase() || "")}
        />
      </div>
      <div className="scroll-box">
        { filteredList.map(s =>
          <Checkbox key={s.index}
            checked={visibleClusters ? visibleClusters[s.index] : true}
            onChange={e => {
              dispatch(setTractsVisible(id, [s.index], e.target.checked));
            }}
          >
            <Tooltip title={s.name} mouseEnterDelay={0.5}>
              <Typography.Text ellipsis={true}>
                {s.name}
              </Typography.Text>
            </Tooltip>
            <ColorPicker style={{marginLeft: 'auto'}} color={s.color}/>
          </Checkbox>
        )}
        { filteredList.length === 0 &&
          <div className="empty-box">
            <FontAwesomeIcon icon={faInbox}/>
            No items
          </div>
        }
      </div>
    </SegmentationSettingsStyled>
  </>);
}

const mapState = (state, { id }) => {
  const options = selectVisualOptions(state, id);
  return {
    visibleClusters: options?.visibleClusters,
    colorMode: options?.colorMode,
    clusters: options?.clusters
  }
}

export default connect(mapState, null)(TractClustersSettings);
