import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUserTie,
  faChevronDown,
  faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';
import UserBadgeStyled from './UserBadge.styled';
import { useLogout } from '../../redux/auth/auth.api';
import { useUser } from '../../redux/api';

const UserBadge = (props) => {
  const { data: user } = useUser();
  const [ doLogout ] = useLogout();

  const menu = useMemo(() => ({
    onClick: ({ key }) => {
      if(key === 'logout') doLogout();
    },
    items: [
      {
        key: 'profile',
        label:
          <Link to='/users/me'>
            <div>Logged in as</div>
            <Typography.Text strong>{user?.name}</Typography.Text>
          </Link>
      },
      {
        type: 'divider'
      },
      {
        key: 'logout',
        icon: <FontAwesomeIcon icon={faRightFromBracket}/>,
        label: "Logout"
      }
    ],
  }), [doLogout, user]);

  return (<Dropdown menu={menu} trigger={['click']} placement="bottomRight">
    <UserBadgeStyled>
      <FontAwesomeIcon icon={user?.isAdmin ? faUserTie : faUser}/>
      <FontAwesomeIcon icon={faChevronDown}/>
    </UserBadgeStyled>
  </Dropdown>);
}

export default UserBadge;
