import styled from 'styled-components';

const SignInStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 7px;
  background-color: white;
  border: 1px #D7D7D7 solid;
  text-align: center;
  padding: 12px 24px;
  padding-bottom: 8px;
  max-width: 450px;

  margin: auto;
  margin-top: 0px;
  margin-bottom: 40px;

  > * {
    margin-bottom: 16px;
  }

  .ant-checkbox-wrapper {
    align-self: flex-start;
  }

  .ant-alert {
    margin-bottom: 8px;
  }

  svg {
    margin-right: 5px;
  }

  img {
    max-width: 35%;
    align-self: center;
  }
`;

export default SignInStyled;
