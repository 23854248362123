import styled from 'styled-components';

const SceneSettingsStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 33px);
  scrollbar-width: thin;
  padding-right: 5px;
  padding-bottom: 4px;

  > div:first-child > span:first-child {
    border-top: none;
  }

  .ant-slider {
    max-width: calc(100% - 24px);
    .ant-slider-mark-text {
      color: white;
    }

    .ant-slider-rail {
      background-color: lightgray;
    }
  }

  .ant-select {
    color: white;
    padding-right: 10px;

    .ant-select-selector {
      padding-left: 0;
      border-bottom: 1px solid #9ccef9 !important;
      border-radius: 0px;
    }

    .ant-select-arrow {
      color: #9ccef9;
    }

    .ant-select-selection-item {
      color: white;
    }
  }

  .ant-radio-wrapper {
    color: white;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    width: calc(100% - 72px);
    flex: 1;
  }

  .ant-alert {
    margin-left: 24px;
    margin-bottom: 12px;

    b {
      margin-right: 0.3em;
    }
  }
`;

const SettingsHeader = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-right: -2px;
  margin-bottom: 2px;
  border-top: 1px dashed;

  .ant-checkbox-wrapper {
    > span {
      color: ${props => props.isError ? 'red' : 'white'};
    }

    > span:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ant-btn-active {
    color: white;
    background-color: #1890ff;
    border-radius: 8px;
  }
`;

const SettingsPanel = styled.div`
  margin-left: 8px;
  margin-bottom: 8px;
  padding-left: 16px;

  display: grid;
  grid-template-columns: minmax(100px, 1fr) 2fr;
  row-gap: 4px;
  align-items: center;

  color: lightgray;

  .ant-slider {
    margin-right: 12px;
  }

  .ant-select {
    max-width: 230px;
    @media (max-width: 1500px) {
      max-width: 190px;
    }
  }

  .wide-box {
    grid-column: 1 / span 2;
  }

  .ant-checkbox-wrapper {
    /* grid-column: 1 / span 2; */

    > span {
      color: lightgray;
    }
  }

  .ant-collapse-header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 24px;
      height: 24px;
      padding: 0;
    }

    button[disabled] {
      color: gray;
    }
  }

  .ant-list {
    max-height: 150px;
    overflow: auto;
    scrollbar-width: thin;
    li {
      margin-left: 24px;
      padding: 0;
      border-bottom: none;
      color: lightgray;
    }
  }

`;

export { SettingsPanel, SettingsHeader };
export default SceneSettingsStyled;
