import styled from 'styled-components';

const LayoutConfigStyled = styled.div`
  cursor: pointer;
  margin: 2px 0;

  span {
    display: flex;
  }

  div {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    width: 24px;
    height: 24px;
    transition: background-color 0.3s ease;
  }

  span:first-child > div {
    border-top: 1px solid black;
  }

  div:first-child {
    border-left: 1px solid black;
  }
`;

export default LayoutConfigStyled;
