import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedItems: [],
  showGroupDialog: false,
  editGroup: undefined
};

export const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    setSelectedDatasets: (state, action) => {
      state.selectedItems = action.payload;
    },
    setShowGroupDialog: (state, action) => {
      state.showGroupDialog = action.payload;
    },
    setEditGroup: (state, action) => {
      state.editGroup = action.payload;
    }
  }
});

export const {
  setSelectedDatasets, setShowGroupDialog, setEditGroup
} = datasetsSlice.actions;

export default datasetsSlice.reducer;
