import styled from 'styled-components';

export const AnalysisItemStyled = styled.div`
  display: flex;
  position: relative;
  color: black;
  margin-bottom: 16px;
  transition: border-color 0.3s;

  :hover {
    color: black;
    transition: border-color 0.3s;
    border-color: #1890ff;
  }

  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;

  > a {
    color: black;
  }

  .a-name {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .a-duration {
    margin-left: 0.3em;
  }

  .a-info {
    flex: 1;
    display: grid;
    grid-template-columns: min-content auto;
    grid-column-gap: 8px;
    grid-row-gap: 4px;

    svg {
      margin-right: 0.3em;
    }

    b > svg {
      vertical-align: -3px;
    }
  }

  .a-actions > .ant-btn {
    color: black;
    width: 16px;
    height: 16px;
    padding: 0;
    :hover {
      color: gray;
    }
  }
`

const AnalyzesStyled = styled.div`
  .al-header {
    display: flex;
    .ant-typography {
      flex: 1;
    }
  }
`;

export default AnalyzesStyled;
