import styled from 'styled-components';
import { Tree } from 'antd';

const FileTreeStyled = styled(Tree)`
  margin-top: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-bottom: 2px;
`;

export const FileTreeItemStyled = styled.span`
  display: flex;
  align-items: center;

  > svg:first-child {
    margin-right: 8px;
  }

  > svg:first-child + span {
    flex: 1;
  }

  > .ant-typography {
    text-align: right;
    margin-right: 8px;
  }

  > svg:last-child {
    transition: all 0.3s;
    cursor: pointer;
    color: gray;

    :hover {
      color: darkgray;
      transition: color 0.3s;
    }
  }
`;

export default FileTreeStyled;
