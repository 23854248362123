import { Utils } from 'nifti-reader-js';

const READER = {
  string: (...args) => ({ data: Utils.getStringAt(...args), size: 1 }),
  float:  (...args) => ({ data: Utils.getFloatAt(...args),  size: 4 }),
  int:    (...args) => ({ data: Utils.getIntAt(...args),    size: 4 }),
  byte:   (...args) => ({ data: Utils.getByteAt(...args),   size: 1 }),
  short:  (...args) => ({ data: Utils.getShortAt(...args),  size: 2 })
}

class BinaryReader {
  constructor(data) {
    this.data = new DataView(data);
    this.offset = 0;
    this.isLittleEndian = false;
    this.customReaders = {};
  }

  checkLittleEndian(type, pos, expected) {
    const read = this._findReader(type);
    const { data } = read(this.data, pos, true);
    this.isLittleEndian = data === expected;
  }

  setLittleEndian(val) {
    this.isLittleEndian = !!val;
  }

  addCustomReader(type, reader) {
    this.customReaders[type] = reader;
  }

  atEnd() {
    return this.offset >= this.data.byteLength;
  }

  scan(type, count = 1) {
    const { data: input, offset, isLittleEndian } = this;
    const read = this._findReader(type);

    if(type === 'string') {
      const { data } = read(input, offset, offset + count);
      this.offset += count;
      return data;
    } else if(count === 1) {
      const { data, size } = read(input, offset, isLittleEndian);
      this.offset += size;
      return data;
    }

    const res = [];
    for(let i = 0; i < count; ++i) {
      const { data, size } = read(input, this.offset, isLittleEndian);
      res.push(data);
      this.offset += size;
    }
    return res;
  }

  _findReader(type) {
    return type in this.customReaders ? this.customReaders[type] : READER[type];
  }
}

export default BinaryReader;
