import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { mriaApi } from './api';
import authReducer from './auth/auth.slice';
import viewerReducer from './viewer/viewer.slice';
import qaViewerReducer from './qa_viewer/qa_viewer.slice';
import fgmViewerReducer from './fgm_viewer/fgm_viewer.slice';
import uploadReducer from './upload/upload.slice';
import datasetsReducer from './datasets/datasets.slice';
import runReducer from './run/run.slice';

const middlewares = [ mriaApi.middleware ];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({
    predicate: (s, a) => /*!a.type?.includes('viewer')*/!a.type?.includes('Progress') && !a.type?.includes('Camera') && !a.type?.includes('Activ')
  }));
}

export const store = configureStore({
  reducer: {
    auth:     authReducer,
    viewer:   viewerReducer,
    qa:       qaViewerReducer,
    fgmr:     fgmViewerReducer,
    upload:   uploadReducer,
    datasets: datasetsReducer,
    run:      runReducer,
    [mriaApi.reducerPath]: mriaApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
  devTools: false
});
