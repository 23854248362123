import React from 'react';
import { cache } from '@cornerstonejs/core';
import { connect, useDispatch } from 'react-redux';
import { Checkbox, Collapse, List, Select, Button, Tooltip, Slider, Alert, Radio, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faFileCode, faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import SceneSettingsStyled, { SettingsPanel, SettingsHeader } from './SceneSettings.styled';
import SliceVisualizationSettings from './SliceVisualizationSettings';
import { CollapsiblePanelStyled } from './SegmentationSettings.styled';
import SegmentationSettings from './SegmentationSettings';
import TractClustersSettings from './TractClustersSettings'
import MetadataPanel from './MetadataPanel';
import SpinButton from './SpinButton';
import {
  selectLayers
} from '../../../../redux/viewer/viewer.selectors';
import {
  setLayerVisible,
  setLayerOpacity,
  setFunctionalROI,
  setTractographySliceMode,
  setTractographyQuality,
  setTractographyColored,
  setThicknessMode,
  setThicknessRepresentationMode,
  setFunctionalPValue,
  setFunctionalGroupModeROI,
  setFunctionalGroupDisplayMode,
  setFunctionalFilterArray,
  showLayerMetadata,
  deleteLayer,
  moveLayer,
  layerModified
} from '../../../../redux/viewer/viewer.slice';
import {
  DATA_TYPE,
  CONN_GROUP_MODE,
  CONN_GROUP_MODE_OPTIONS
} from '../../../../constants';
import {
  TRACTS_SLICE_MODE,
  THICKNESS_DISPLAY_MODE,
  THICKNESS_REPRESENTATION_MODE
} from '../Actors';

const TRACTOGRAPHY_MODES = [
  { label: 'Fibers',      value: TRACTS_SLICE_MODE.DISABLED    },
  { label: 'Clusters',    value: TRACTS_SLICE_MODE.CLUSTERED   },
  { label: 'Precise',     value: TRACTS_SLICE_MODE.PRECISE     },
  { label: 'Approximate', value: TRACTS_SLICE_MODE.APPROXIMATE },
  { label: 'Voxelized',   value: TRACTS_SLICE_MODE.VOXELIZED   }
];

const THICKNESS_MODES = [
  { label: 'Model',       value: THICKNESS_DISPLAY_MODE.MODEL },
  { label: 'Slices',      value: THICKNESS_DISPLAY_MODE.SLICES }
];

const THICKNESS_REPRESENTATIONS = [
  { label: 'Points',    value: THICKNESS_REPRESENTATION_MODE.POINTS    },
  { label: 'Wireframe', value: THICKNESS_REPRESENTATION_MODE.WIREFRAME },
  { label: 'Surface',   value: THICKNESS_REPRESENTATION_MODE.SURFACE   }
];

const selectConnGroupModeROIs = (layer) => {
  if(layer.type !== DATA_TYPE.CONN_GROUP) return undefined;
  const vol = cache.getVolume(layer.volumeId);
  return vol?.data?.map((roi, i) => ({ label: roi.names[i], value: i }));
}

const SceneSettings = (props) => {
  const dispatch = useDispatch();
  const { layers } = props;

  return (<SceneSettingsStyled>
    { layers.map((layer, idx) => {
      if(layer.hidden) return undefined;
      return (<div key={idx}>
        <SettingsHeader isError={!!layer.error}>
          <Checkbox
            disabled={!!layer.error}
            checked={layer.error ? false : layer.visible}
            onChange={e => {
              dispatch(setLayerVisible(idx, e.target.checked));
            }}
          >
            {layer.name}
          </Checkbox>
          { (!layer.default || layer.type === DATA_TYPE.SEGMENTATION) &&
            <SpinButton
              upDisabled={idx === 1}
              downDisabled={idx === layers.length - 1}
              onUpClick={() => dispatch(moveLayer(idx, idx - 1))}
              onDownClick={() => dispatch(moveLayer(idx, idx + 1))}
            />
          }
          { layer.type === DATA_TYPE.CONN_GROUP &&
            <Tooltip
              title={"Show results on multiple slices"}
              mouseEnterDelay={0.5}
            >
              <Button type='link' size='small'
                icon={<FontAwesomeIcon icon={faLayerGroup}/>}
                onClick={() => {
                  if(props.onShowFGMResults) props.onShowFGMResults(layer.volumeId)
                }}
              />
            </Tooltip>
          }
          {
            <Tooltip
              title={layer.showMetadata ? 'Hide metadata' : 'Show metadata'}
              mouseEnterDelay={0.5}
            >
              <Button type='link' size='small' className={layer.showMetadata ? 'ant-btn-active' : ''}
                icon={<FontAwesomeIcon icon={faFileCode}/>}
                onClick={() => {
                  dispatch(showLayerMetadata(idx, !layer.showMetadata));
                }}
              />
            </Tooltip>
          }
          { (!layer.default || layer.error) &&
            <Tooltip title='Delete layer' mouseEnterDelay={0.5}>
              <Button type='link' size='small'
                icon={<FontAwesomeIcon icon={faTrashCan}/>}
                onClick={() => dispatch(deleteLayer(idx))}
              />
            </Tooltip>
          }
        </SettingsHeader>
        { layer.error &&
          <Alert type='error' message={<span>
            <b>Error:</b>
            { layer.error instanceof Error ? layer.error.message : layer.error }
          </span>}/>
        }
        { layer.showMetadata && !layer.error &&
          <MetadataPanel volumeId={layer.volumeId}/>
        }
        { layer.visible && !layer.error &&
          <SettingsPanel>
            { layer.type === DATA_TYPE.FUNCTIONAL && layer.options?.roiList && <>
              ROI
              <Select
                variant='borderless'
                options={layer.options?.roiList}
                value={layer.volumeId}
                onChange={value => dispatch(setFunctionalROI(idx, value))}
              />
            </>}
            { layer.type === DATA_TYPE.TRACTOGRAPHY && <>
              { !!layer.options.groupData && <>
                <CollapsiblePanelStyled ghost expandIconPosition='end' className='wide-box'>
                  <Collapse.Panel header='Correlated tracts list'>
                    { Object.keys(layer.options.groupData.names).map(k =>
                      <CollapsiblePanelStyled key={k} ghost style={{marginLeft: '8px'}}>
                        <Collapse.Panel header={
                          <>
                            {(k === 'pos' || k === 'inc') ? 'Positive' : 'Negative'}
                            <Button type="link"
                              icon={<FontAwesomeIcon icon={faCopy}/>}
                              disabled={!navigator.clipboard}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(
                                  layer.options.groupData.names[k].join('\n')
                                ).then(() => {
                                  message.success("Tracts list copied to clipboard");
                                });
                              }}
                            />
                          </>}
                        >
                          <List
                            dataSource={layer.options.groupData.names[k]}
                            renderItem={item =>
                              <List.Item>{item}</List.Item>
                            }
                          />
                          {/*{ layer.options.groupData.names[k].map(t => <div key={t}>{t}</div>) }*/}
                        </Collapse.Panel>
                      </CollapsiblePanelStyled>
                    )}
                  </Collapse.Panel>
                </CollapsiblePanelStyled>
                Correlation
                <div style={{ display: 'flex' }}>
                  { Object.entries(layer.options.groupData.files).map((([k, v], i) =>
                    <Checkbox
                      key={v}
                      checked={layers[idx].children[i].visible}
                      onChange={e => dispatch(setLayerVisible([idx, i], e.target.checked))}
                    >
                      { (k === 'pos' || k === 'inc') ? 'Positive' : 'Negative' }
                    </Checkbox>
                  ))}
                </div>
                Colored
                <div>
                  <Checkbox
                    checked={layer.options.colored}
                    onChange={e => dispatch(setTractographyColored(idx, e.target.checked))}
                  />
                </div>
              </>}
              Slice mode
              <Select
                variant='borderless'
                options={TRACTOGRAPHY_MODES}
                value={layers[idx].options?.sliceMode}
                onChange={value => dispatch(setTractographySliceMode(idx, value))}
              />
              Quality
              <Slider min={0} max={3} step={null}
                marks={{
                  0: 'very low',
                  1: 'low',
                  2: 'medium',
                  3: 'high'
                }}
                value={layers[idx].options?.tractsQuality}
                onChange={value => dispatch(setTractographyQuality(idx, value))}
                defaultValue={1}
              />
              { layers[idx].options?.clusters &&
                <TractClustersSettings id={idx}/>
              }
            </>}
            { layer.type === DATA_TYPE.THICKNESS && <>
              Display mode
              <Select
                variant='borderless'
                options={THICKNESS_MODES}
                value={layers[idx].options?.thicknessMode}
                onChange={value => dispatch(setThicknessMode(idx, value))}
              />
              { layers[idx].options?.thicknessMode === THICKNESS_DISPLAY_MODE.MODEL && <>
                Model mode
                <Select
                  variant='borderless'
                  options={THICKNESS_REPRESENTATIONS}
                  value={layers[idx].options?.representationMode}
                  onChange={value => dispatch(setThicknessRepresentationMode(idx, value))}
                />
              </>}
            </>}
            { layer.type === DATA_TYPE.CONN_GROUP && <>
              ROI
              <Select
                variant='borderless'
                options={selectConnGroupModeROIs(layer)}
                value={layer.options?.roi}
                onChange={value => {
                  dispatch(setFunctionalGroupModeROI(idx, value));
                  dispatch(layerModified(idx))
                }}
              />
              Mode
              <Select
                variant='borderless'
                options={CONN_GROUP_MODE_OPTIONS}
                value={layer.options?.mode}
                onChange={value => {
                  dispatch(setFunctionalGroupDisplayMode(idx, value));
                }}
              />
              { layer.options?.mode === CONN_GROUP_MODE.POINT_CLOUD && <>
                Filter
                <div>
                  <Radio.Group
                    value={layer.options.filterArray}
                    onChange={e => {
                      dispatch(setFunctionalFilterArray(idx, e.target.value));
                      dispatch(layerModified(idx));
                    }}
                  >
                    <Radio value={0}> p </Radio>
                    <Radio value={1} disabled={!layer.options.hasPFDR}> pFDR </Radio>
                  </Radio.Group>
                </div>
                { layer.options.filterArray === 0 ? 'p' : 'pFDR' }
                <Slider min={0.0} max={1.0} step={0.05}
                  value={layer.options?.p}
                  deafultValue={1}
                  onChange={value => dispatch(setFunctionalPValue(idx, value))}
                  onAfterChange={() => dispatch(layerModified(idx))}
                />
              </>}
            </>}
            Opacity
            <Slider min={0.0} max={1.0} step={0.01}
              value={layers[idx].options?.opacity}
              onChange={value => dispatch(setLayerOpacity(idx, value))}
              onAfterChange={() => dispatch(layerModified(idx))}
            />
            { layer.type === DATA_TYPE.SEGMENTATION &&
              <SegmentationSettings id={idx}/>
            }
            { layer.hasColorSettings &&
              <SliceVisualizationSettings id={idx}/>
            }
            {
              layer.type === DATA_TYPE.CONN_GROUP && layer.options.mode === CONN_GROUP_MODE.HEAT_MAP &&
              <SliceVisualizationSettings id={[idx, 0]}/>
            }
          </SettingsPanel>
        }
      </div>);
    })}
  </SceneSettingsStyled>);
}

const mapState = (state, props) => ({
  layers:   selectLayers(state)
});

export default connect(mapState, null)(SceneSettings);
