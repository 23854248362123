import { fetchData, elapsed } from '../cornerstone/fetcher';
import { ungzip } from 'pako';
import BinaryReader from '../binReader';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import TrkImageVolume from './trkVolume';

// TRK file format: http://trackvis.org/docs/?subsect=fileformat
const TRK_HEADER_SIZE = 1000;

const decompress = async (volumeId, data) => {
  if(volumeId.endsWith('.gz')) return ungzip(data).buffer;
  return data;
}

const distance = (vs, p1, p2) => {
  const dx = vs[p2 + 0] - vs[p1 + 0];
  const dy = vs[p2 + 1] - vs[p1 + 1];
  const dz = vs[p2 + 2] - vs[p1 + 2];
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

const getSegmentColor = (cellArray, vertexArray, i) => {
  const p1 = 3 * cellArray[i + 1];
  const p2 = 3 * cellArray[i + 2];
  const dx = Math.abs(vertexArray[p2] - vertexArray[p1]);
  const dy = Math.abs(vertexArray[p2 + 1] - vertexArray[p1 + 1]);
  const dz = Math.abs(vertexArray[p2 + 2] - vertexArray[p1 + 2]);
  const d = Math.sqrt(dx * dx + dy * dy + dz * dz);
  return [dx / d * 255, dy / d * 255, dz / d * 255];
}

const loadTRK = (volumeId, options) => fetchData(volumeId, options)
  .then(data => decompress(volumeId, data))
  .then(data => elapsed(() => {
    const r = new BinaryReader(data);
    r.checkLittleEndian('int', TRK_HEADER_SIZE - 4, TRK_HEADER_SIZE);

    const header = {
      id_string:      r.scan('string', 6),
      dim:            r.scan('short', 3),
      voxel_size:     r.scan('float', 3),
      origin:         r.scan('float', 3),
      n_scalars:      r.scan('short'),
      scalar_name:    r.scan('string', 200),
      n_properties:   r.scan('short'),
      property_name:  r.scan('string', 200),
      vox_to_ras:     r.scan('float', 16),
      reserved:       r.scan('string', 444),
      voxel_order:    r.scan('string', 4),
      pad2:           r.scan('string', 4),
      image_orientation_patient: r.scan('float', 6),
      pad1:           r.scan('string', 2),
      invert_x:       r.scan('byte'),
      invert_y:       r.scan('byte'),
      invert_z:       r.scan('byte'),
      swap_xy:        r.scan('byte'),
      swap_yz:        r.scan('byte'),
      swap_zx:        r.scan('byte'),
      n_count:        r.scan('int'),
      version:        r.scan('int'),
      hdr_size:       r.scan('int')
    };

    if(header.id_string !== "TRACK" || header.hdr_size !== TRK_HEADER_SIZE) {
      throw new Error(`Invalid trk file: ${volumeId}`);
    }

    // read tracks
    const vertexArray = [];
    const cellArray = [];
    const tracks = [];
    let pointIdx = 0;

    let minX, minY, minZ, maxX, maxY, maxZ, minL, maxL;
    minX = minY = minZ = minL = Infinity;
    maxX = maxY = maxZ = maxL = -Infinity;

    for(let i = 0; i < header.n_count; ++i) {
      const numPoints = r.scan('int');
      const points = r.scan('float', numPoints * (3 + header.n_scalars) + header.n_properties);
      const trackIndex = cellArray.length;

      let length = 0;
      let prevPoint = undefined;
      for(let j = 0; j < numPoints; ++j) {
        const p = (3 + header.n_scalars) * j;

        // https://github.com/freesurfer/freesurfer/blob/c8fde4d6c8b6fa18de4cd09213a171e0e51e04e9/freeview/FSTrack.cpp#L66
        let x = points[p]     / header.voxel_size[0] - 0.5;
        let y = points[p + 1] / header.voxel_size[1] - 0.5;
        let z = points[p + 2] / header.voxel_size[2] - 0.5;

        x = x * header.vox_to_ras[0]  + header.vox_to_ras[3];
        y = y * header.vox_to_ras[5]  + header.vox_to_ras[7];
        z = z * header.vox_to_ras[10] + header.vox_to_ras[11];

        if(x < minX) minX = x;
        else if(x > maxX) maxX = x;

        if(y < minY) minY = y;
        else if(y > maxY) maxY = y;

        if(z < minZ) minZ = z;
        else if(z > maxZ) maxZ = z;

        vertexArray.push(x, y, z);

        if(j > 0) {
          cellArray.push(2, pointIdx++, pointIdx);
          length += distance(vertexArray, prevPoint, p);
        }

        prevPoint = p;
      }

      pointIdx++;

      if(length < minL) minL = length;
      else if(length > maxL) maxL = length;

      tracks.push({ index: trackIndex, numPoints, length });
    }

    const colorArray = new Uint8Array(cellArray.length);
    for(let i = 0; i < cellArray.length; i += 3) {
      const [x, y, z] = getSegmentColor(cellArray, vertexArray, i);
      colorArray[i] = x;
      colorArray[i + 1] = y;
      colorArray[i + 2] = z;
    }

    let centerX = (minX + maxX) / 2.0;
    let centerY = (minY + maxY) / 2.0;
    let centerZ = (minZ + maxZ) / 2.0;
    const xs = Math.round(Math.abs(maxX - minX));
    const ys = Math.round(Math.abs(maxY - minY));
    const zs = Math.round(Math.abs(maxZ - minZ));
    if(options.offset) {
      centerX = options.offset[0];
      centerY = options.offset[1];
      centerZ = options.offset[2];
    }

    console.log("TRK", header, xs, ys, zs, centerX, centerY, centerZ)

    const vertexData = new Float32Array(vertexArray.length);
    for(let i = 0; i < vertexArray.length; i += 3) {
      vertexData[i] = vertexArray[i] - centerX;
      vertexData[i + 1] = vertexArray[i + 1] - centerY;
      vertexData[i + 2] = vertexArray[i + 2] - centerZ;
    }

    const da = vtkDataArray.newInstance({
      numberOfComponents: 3,
      values: colorArray,
    });
    da.setName('Scalars');

    const linesPolyData = vtkPolyData.newInstance();
    linesPolyData.getPoints().setData(vertexData, 3);
    linesPolyData.getLines().setData(Uint32Array.from(cellArray));
    linesPolyData.getCellData().setScalars(da);

/*
    const planeSource = vtkPlaneSource.newInstance({
      xResolution: 1,
      yResolution: 1,
      point1: [xs / maxD, 0, 0],
      point2: [0, ys / maxD, 0],
    });
    planeSource.setNormal([0, 0, 1]);

    const plane = vtkPlane.newInstance();
    plane.setNormal([0, 0, 1]);
    plane.setOrigin([0, 0, 0.2]);

    const getPoint = (index) => {
      const i = 3 * cellArray[index];
      return vertexArray.slice(i, i + 3);
    }

    const getVector = (p1, p2) => {
      const vec = [p2[0] - p1[0], p2[1] - p1[1], p2[2] - p1[2]];
      vtkMath.normalize(vec);
      return vec;
    }

    const asVector = (p1, p2) => {
      return p2.map((v, i) => Math.abs(v - p1[i]));
    }

    const addVectors = (v1, v2) => {
      if(!v1) return v2;
      if(!v2) return v1;
      return v1.map((v, i) => v + v2[i]);
    }

    const slicePolyData = vtkPolyData.newInstance();

    const vxs = 91;
    const vys = 109;
    const vzs = 91;

    const targetCoord = 0.3;
    let counter = 0, counter1 = 0, counter2 = 0;
    const sliceVertexArray = [];
    let sliceColorArray = []
    let sliceOrientationArray = new Array(vxs * vys).fill(undefined);

    const sliceCellArray2 = [];
    const sliceBox = vtkBoundingBox.newInstance();
    sliceBox.setBounds([-1, 1, -1, 1, 0, 2 / 91.0]);

    for(let i = 0; i < cellArray.length; i += 3) {
      const p1 = getPoint(i + 1);
      const p2 = getPoint(i + 2);
      if(sliceBox.containsPoint(...p1) || sliceBox.containsPoint(...p2)) {
        if(vtkMath.angleBetweenVectors(asVector(p1, p2), [0, 0, 1]) <  25 / 180 * Math.PI) {
          continue;
        }
        sliceCellArray2.push(2, cellArray[i + 1], cellArray[i + 2]);
        sliceColorArray.push(...colorArray.slice(i, i + 3));
      }
*/


/*
      if((p1[2] <= targetCoord && p2[2] >= targetCoord) || (p1[2] >= targetCoord && p2[2] <= targetCoord)) {
        // tract segment intersects with the slice plane
        const { x: pi } = plane.intersectWithLine(p1, p2);
        // need to add vector to voxel
        const x = (pi[0] * maxD + centerX) - minX;
        const y = (pi[1] * maxD + centerY) - minY;
        const z = (pi[2] * maxD + centerZ) - minZ;
        if(x < 0) continue;

        const vx = Math.round(x / xs * vxs);
        const vy = Math.round(y / ys * vys);
        const vz = Math.round(z / zs * vzs);

        sliceOrientationArray[vx + vxs * vy] = addVectors(
          sliceOrientationArray[vx + vxs * vy], getVector(p1, p2)
        );

        // const v = getVector(p1, p2);
        // sliceVertexArray.push(...pi);
        // sliceOrientationArray.push(...v);
        // sliceColorArray.push(...v.map(i => Math.abs(i) * 255));
*/
    // }
    //
    // sliceOrientationArray = sliceOrientationArray.map(v => {
    //   if(!v) return [0, 0, 0];
    //   vtkMath.normalize(v);
    //   return v;
    // });
    //
    // const simpleFilter = vtkCalculator.newInstance();
    // simpleFilter.setFormula({
    //   getArrays: (inputDataSets) => ({
    //     input:  [{ location: FieldDataTypes.COORDINATE }],
    //     output: [
    //       {
    //         location: FieldDataTypes.POINT,
    //         name: 'orientation',
    //         dataType: 'Float32Array',
    //         numberOfComponents: 3,
    //       },
    //       {
    //         location: FieldDataTypes.POINT,
    //         name: 'color',
    //         dataType: 'Uint8Array',
    //         attribute: AttributeTypes.SCALARS,
    //         numberOfComponents: 3,
    //       }
    //     ]
    //   }),
    //   evaluate: (arraysIn, arraysOut) => {
    //     // Convert in the input arrays of vtkDataArrays into variables
    //     // referencing the underlying JavaScript typed-data arrays:
    //     const [coords] = arraysIn.map(d => d.getData());
    //     const [orient, color] = arraysOut.map(d => d.getData());
    //
    //     console.log(coords.length / 3, sliceOrientationArray.length);
    //     console.log(coords)
    //     for(let i = 0; i < coords.length / 3; i++) {
    //       const p = 3 * i;
    //       const v = sliceOrientationArray[i];
    //       const c = v.map(i => Math.abs(i) * 255);
    //
    //       orient[p] = v[0];
    //       orient[p + 1] = v[1];
    //       orient[p + 2] = v[2];
    //       color[p] = c[0];
    //       color[p + 1] = c[1];
    //       color[p + 2] = c[2]
    //     }
    //     arraysOut.forEach((x) => x.modified());
    //   }
    // });
    //
    // // sliceColorArray = sliceOrientationArray.map(v => Math.abs(v) * 255);
    //
    // // const sliceCellArray = [ sliceVertexArray.length / 3 ];
    // // for(let i = 0; i < sliceVertexArray.length / 3; ++i) sliceCellArray.push(i);
    //
    // const da1 = vtkDataArray.newInstance({
    //   numberOfComponents: 3,
    //   values: Uint8Array.from(sliceColorArray),
    // });
    // da1.setName('color');
    //
    // const da2 = vtkDataArray.newInstance({
    //   numberOfComponents: 3,
    //   values: Float32Array.from(sliceOrientationArray),
    // });
    // da2.setName('orientation');
    //
    // slicePolyData.getPoints().setData(linesPolyData.getPoints().getData());
    // slicePolyData.getLines().setData(Uint32Array.from(sliceCellArray2));
    // slicePolyData.getCellData().setScalars(da1);
    // slicePolyData.getPoints().setData(Float32Array.from(sliceVertexArray), 3);
    // slicePolyData.getPointData().setScalars(da1);
    // slicePolyData.getPointData().addArray(da2);
    // slicePolyData.getVerts().setData(Uint32Array.from(sliceCellArray));

    return {
      header,
      data: linesPolyData,
      dims: [ xs, ys, zs ],
      origin: [ -xs / 2.0, -ys / 2.0, -zs / 2.0 ],
      size: (vertexArray.length + cellArray.length) * 4 + colorArray.length,
      metadata: {
        offset: [centerX, centerY, centerZ],
        minLength: minL,
        maxLength: maxL,
        tracks
      }
    };
  }
));


const trkVolumeLoader = (volumeId, options) => {
  return {
    promise: loadTRK(volumeId, options).then(volume => {
      const trkVolume = new TrkImageVolume({
        volumeId,
        imageIds: [],
        dimensions:  volume.dims,
        spacing:     [1, 1, 1],//volume.header.voxel_size,
        origin:      volume.origin,
        metadata:    {
          ...volume.metadata,
          FrameOfReferenceUID: 'default',
          Modality: 'CT',
          PhotometricInterpretation: "RGB",
          PixelRepresentation: 0,
        },
        direction:   [1, 0, 0, 0, 1, 0, 0, 0, 1],
        scalarData:  [0, 0, 0],
        sizeInBytes: volume.size,
        polyData:    volume.data,
        quality:     options.quality
      });

      return trkVolume;
    }),
    cancel: undefined /* TODO */
  }
}

export { getSegmentColor };
export default trkVolumeLoader;
